<template>
  <Combobox v-model="selectedEvaluator">
    <div class="relative">
      <div class="relative w-full cursor-default">
        <ComboboxInput
          placeholder="John Smith"
          :class="props.inputClasses"
          class="focus:border-blue-500 ring-blue-500 w-full focus:outline-hidden disabled:bg-transparent font-normal text-sm px-3.5 py-2.5 flex items-center justify-between rounded-md bg-white border border-slate-300"
          :disabled="disabled"
          @change="query = $event.target.value"
        />
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          class="p-2 z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm"
        >
          <ComboboxOption
            v-if="query && !evaluatorExists"
            v-slot="{ selected, active }"
            as="template"
            :value="query"
          >
            <li
              class="cursor-default select-none py-2 px-4"
              :class="{
                'bg-slate-200 text-slate-700': active,
                'text-gray-900': !active,
              }"
            >
              <span
                class="block truncate"
                :class="{ 'font-medium': selected, 'font-normal': !selected }"
              >
                {{ query }}
              </span>
            </li>
          </ComboboxOption>

          <ComboboxOption
            v-for="(evaluator, index) in filteredItems"
            :key="'evaluator-' + index"
            v-slot="{ selected, active }"
            as="template"
            :value="evaluator"
          >
            <li
              class="cursor-default select-none py-2 px-4"
              :class="{
                'bg-slate-200 text-slate-700': active,
                'text-slate-700 hover:invert': !active,
              }"
            >
              <span
                class="block truncate"
                :class="{ 'font-medium': selected, 'font-normal': !selected }"
              >
                <!-- Highlight the matching query within the evaluator -->
                <span
                  v-for="(part, partIndex) in highlightQuery(evaluator)"
                  :key="partIndex"
                  :class="part.isMatch ? 'text-blue-500' : 'text-slate-700'"
                  class="inline"
                >
                  {{ part.text }}
                </span>
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../use-review'
import { computed, ref } from 'vue'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'

const props = defineProps<{
  inputClasses?: string
  disabled?: boolean
}>()
const review = injectStrict(ReviewKey)
const project = review.entity.value.project!
const evaluators = project.users.map((u) => `${u.firstName} ${u.lastName}`)
const selectedEvaluator = defineModel<any>()

const query = ref('')

const filteredItems = computed(() =>
  query.value === ''
    ? evaluators
    : evaluators.filter((evaluator) => {
        return evaluator.toLowerCase().includes(query.value.toLowerCase())
      }),
)

const evaluatorExists = computed(() =>
  evaluators.some(
    (evaluator) => evaluator.toLowerCase() === query.value.toLowerCase(),
  ),
)

const highlightQuery = (text: string) => {
  if (!query.value.trim()) return [{ text, isMatch: false }]

  const regex = new RegExp(`(${query.value.trim()})`, 'gi')
  return text.split(regex).map((part) => ({
    text: part,
    isMatch: regex.test(part),
  }))
}
</script>
