<template>
  <ImportSourceCard
    :import-source="handSearch"
    @import-source-removed="emit('importSourceRemoved')"
  >
  </ImportSourceCard>
</template>
<script setup lang="ts">
import ImportSourceCard from '../../Components/ImportSourceCard.vue'

import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'

const builtInImportSources = useBuiltInImportSources()
const emit = defineEmits<(e: 'importSourceRemoved') => void>()

const handSearch = builtInImportSources.findById(
  BuiltInImportSourceId.HAND_SEARCH,
)!
</script>
