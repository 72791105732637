<template>
  <GenericAutoComplete
    v-model="selected"
    :items="remainingUsers"
    :item-options="{
      displayProperty: 'email',
      filterProperties: ['email', 'firstName', 'lastName'],
      valueProperty: 'id',
    }"
    placeholder="Select a user to add as member"
    @update:model-value="addMember"
  >
    <template #item="{ firstName, lastName, email }">
      {{ email }}
      {{ firstName && lastName ? `(${firstName} ${lastName})` : '' }}
    </template>
  </GenericAutoComplete>
</template>

<script setup lang="ts">
import useUsers from '@app/composables/use-users'
import { computed, ref } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import { Project } from '@core/domain/models/project.model'
import useProjects from '@app/views/Projects/use-projects'
import useLoading from '@app/composables/use-loading'

const props = defineProps<{
  project: Project
}>()
const { addProjectMember } = useProjects()
const { users } = useUsers()
const floatingMenu = ref()

const selected = ref('')
const loading = useLoading()
async function addMember(userId?: unknown) {
  loading.start()
  try {
    if (!userId || typeof userId !== 'string') return
    selected.value = ''
    if (!props.project.users?.some((u) => u.id === userId)) {
      const user = users.value.find((u) => u.id === userId)
      if (!user) return
      await addProjectMember(props.project.id!, user)
    }
    floatingMenu.value?.hide()
  } finally {
    loading.stop()
  }
}

const remainingUsers = computed(() =>
  users.value.filter(
    (u) => !u.isDisabled && !props.project.users?.some((m) => u.id === m.id),
  ),
)
</script>
