<template>
  <Modal v-if="isAdmin" ref="modal" position="center">
    <template #activator="{ show }">
      <button
        class="px-4 py-3 flex items-center gap-2 hover:bg-light-neutral-500 w-full"
        @click="show"
      >
        <ArchiveBoxArrowDownIcon class="size-5 text-dark-neutral-500" />
        <a class="font-medium text-dark-neutral-500 relative text-sm">
          {{ review.isArchived ? 'Restore Review' : 'Archive Review' }}
          <span class="absolute inset-0" />
        </a>
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        {{ review.isArchived ? 'Restore' : 'Archive' }}
        Review
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content>
      <div class="flex-1 md:flex md:justify-between">
        <p v-if="!review.isArchived" class="text-base text-dark-neutral-500">
          Are you sure you want to archive this review? <br />
        </p>
        <p v-else class="text-base text-dark-neutral-500">
          Are you sure you want to restore this review?
        </p>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="flex justify-end gap-2">
        <ActionButton color="neutral" @click="hide"> Cancel </ActionButton>
        <ActionButton
          :class="{
            'bg-tertiary-500 text-white': review.isArchived,
            'bg-primary-500 text-white': !review.isArchived,
          }"
          :color="review.isArchived ? 'tertiary' : 'primary'"
          @click="updateReviewArchiveState(review, hide)"
        >
          {{ review.isArchived ? 'Restore' : 'Archive' }}
        </ActionButton>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { SnackbarState } from '@app/types'
import useSnackbar from '@app/composables/use-snackbar'
import { computed, onMounted, ref, nextTick } from 'vue'
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import useLoading from '@app/composables/use-loading'
import useProjects from '@app/views/Projects/use-projects'

import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { Role } from '@core/domain/types/role.type'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Review } from '@core/domain/models/review.model'
import { ArchiveBoxArrowDownIcon } from '@heroicons/vue/24/outline'
import { Project } from '@core/domain/models/project.model'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import ActionButton from '@app/components/Global/ActionButton.vue'

const props = defineProps<{ review: Review; project: Project }>()

const snackbar = useSnackbar()
const loading = useLoading()
const projects = useProjects()
const { user } = injectStrict(AuthKey)

async function archiveReview(reviewId: Id) {
  try {
    loading.start()
    await projects.archiveReview(reviewId)
    snackbar.show(SnackbarState.SUCCESS, 'Review archived successfully')
    hideAll()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function unarchiveReview(reviewId: Id) {
  try {
    loading.start()
    await projects.unarchiveReview(reviewId)
    snackbar.show(SnackbarState.SUCCESS, 'Review restored successfully')
    hideAll()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function updateReviewArchiveState(review: Review, cb: () => void) {
  loading.start()
  try {
    if (review.isArchived) {
      await unarchiveReview(review.id)
    } else {
      await archiveReview(review.id)
    }
    cb()
  } finally {
    loading.stop()
  }
}

const shouldRender = ref(false)
onMounted(() => {
  nextTick(() => {
    const element = document.querySelector(`#project-${props.project.id}`)!
    const callback: IntersectionObserverCallback = (entries, observer) => {
      if (!entries[0].isIntersecting) return
      observer.unobserve(element)
      shouldRender.value = true
    }
    const observer = new IntersectionObserver(callback)
    observer.observe(element)
  })
})

const isAdmin = computed(() => user.value?.role === Role.ADMIN)
</script>
