<template>
  <div :id="`project-${project.id}`" class="rounded-md">
    <div
      v-if="!shouldRender"
      class="flex justify-between gap-2 px-6 py-1 items-center w-full h-14 rounded-b-md bg-azureish-white rounded-md"
    >
      <div class="w-full gap-2 min-w-0 flex">
        <div class="w-full">
          <h1 class="text-md truncate">Loading</h1>
          <p class="text-gray-500">please wait</p>
        </div>
      </div>
    </div>
    <div v-else class="bg-white">
      <ExpansionPanel v-model="isExpanded" class="">
        <template #activator="{ toggle, isShown }">
          <ul class="border-b border-mid-neutral-50">
            <li
              class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-2 px-[24px] sm:flex-nowrap hover:bg-light-neutral-300"
            >
              <div
                class="flex justify-between items-center cursor-pointer transition-all duration-200 w-96"
                :class="[isShown ? 'text-black/90  ' : 'text-black/90  ']"
                @click="toggle"
              >
                <div class="flex gap-2 items-center">
                  <button @click.stop="toggle">
                    <div
                      v-if="isShown"
                      class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
                    >
                      <ChevronUpIcon
                        class="stroke-2 hover:brightness-125 text-mid-neutral-500"
                      />
                    </div>
                    <div
                      v-else
                      class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
                    >
                      <ChevronDownIcon
                        class="stroke-2 hover:brightness-125 text-mid-neutral-500"
                      />
                    </div>
                  </button>
                  <p
                    class="font-semibold text-dark-neutral-500"
                    :class="[isShown ? '' : '']"
                  >
                    {{ project.name }}
                  </p>
                </div>
              </div>
              <div class="flex gap-x-2 items-center">
                <div
                  class="bg-tertiary-50 size-7 text-tertiary-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
                >
                  <DocumentTextIcon class="size-5" aria-hidden="true" />
                </div>
                <p class="font-medium text-dark-neutral-500">
                  {{ project.reviews?.length }} Reviews
                </p>
              </div>
              <div class="flex gap-x-2 items-center w-72">
                <div class="flex -space-x-1">
                  <div v-if="project.users.length === 0">
                    <!-- Default "N/A" Avatar -->
                    <div
                      class="size-7 flex items-center justify-center rounded-full bg-primary-50 text-primary-500 font-medium ring-2 ring-white"
                    >
                      <p class="text-xs font-medium">N/A</p>
                    </div>
                  </div>
                  <div
                    v-for="projectUser in project.users"
                    v-else
                    :key="projectUser.id"
                  >
                    <div
                      class="size-7 flex items-center justify-center bg-primary-50 text-primary-500 rounded-full font-medium ring-2 ring-white"
                    >
                      <p class="text-xs font-medium">
                        {{
                          (
                            projectUser.firstName.charAt(0) +
                            projectUser.lastName.charAt(0)
                          ).toUpperCase()
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <p class="font-medium text-dark-neutral-500">
                  {{ project?.users.length }} Members
                </p>
              </div>

              <div class="flex items-center gap-2">
                <button
                  class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
                  @click.stop="toggleFavorite()"
                >
                  <StarIcon
                    class="w-5 h-5 hover:stroke-mid-neutral-500"
                    :class="[
                      project.isFavorite
                        ? 'fill-mid-neutral-500 stroke-mid-neutral-500'
                        : isShown
                          ? 'stroke-mid-neutral-500 fill-transparent'
                          : 'stroke-mid-neutral-500 fill-transparent ',
                    ]"
                  />
                </button>
                <ProjectItemActions :project="project" />
              </div>
            </li>
          </ul>
        </template>
        <template #default>
          <div v-if="(project.reviews?.length ?? 0) > 0" class="">
            <div
              v-for="projectReview in project.reviews"
              :key="'review-' + projectReview.id"
              class="px-2 flex justify-between items-center h-12 hover:bg-gray-50 pl-[52px] pr-[24px]"
              :class="{
                'bg-light-neutral-200': projectReview.isArchived,
              }"
            >
              <RouterLink
                :to="`/reviews/${projectReview.id}`"
                class="py-1 px-2 flex gap-2 h-full w-full items-center"
              >
                <DocumentTextIcon
                  class="size-5 text-dark-neutral-500"
                  aria-hidden="true"
                />
                <label
                  class="hover:cursor-pointer text-base leading-5 text-dark-neutral-500"
                  :for="projectReview.name"
                >
                  {{ projectReview.name }}
                </label>
              </RouterLink>
              <div class="flex items-center gap-2">
                <span
                  v-if="projectReview.isArchived"
                  class="inline-flex items-center rounded-full bg-tertiary-50 px-2 py-1 text-xs font-medium text-tertiary-500 border border-tertiary-500"
                >
                  Archived
                </span>
                <ReviewItemActions :review="projectReview" :project="project" />
              </div>
            </div>
          </div>

          <div
            v-if="project.reviews?.length === 0"
            class="p-6 border-b border-mid-neutral-50 text-center space-y-2 rounded-b-lg flex items-center justify-center flex-col"
          >
            <img
              :src="NoReviewIllustration"
              alt="No Reviews Illustration"
              class="h-28 w-28"
            />
            <div class="flex flex-col gap-2">
              <p class="text-sm text-dark-neutral-500">
                There are no reviews in this project yet, <br />
                start by adding one
              </p>
              <div v-if="isAdmin">
                <CreateProjectReview :project-id="project.id!" />
              </div>
            </div>
          </div>
          <div v-else>
            <CreateProjectReview
              v-if="isAdmin"
              :project-id="project.id!"
              class="h-10 pl-[52px] w-full"
            />
          </div>
        </template>
      </ExpansionPanel>
    </div>
  </div>
</template>

<script lang="ts" setup>
import StarIcon from '@app/components/Icons/StarIcon.vue'
import CreateProjectReview from '@app/views/Projects/Project/CreateReview/CreateProjectReview.vue'
import { SnackbarState } from '@app/types'
import { Project } from '@core/domain/models/project.model'
import useSnackbar from '@app/composables/use-snackbar'
import { computed, onMounted, ref, nextTick } from 'vue'
import useProjects from '@app/views/Projects/use-projects'
import { User } from '@core/domain/models/user.model'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import ChevronUpIcon from '@app/components/Icons/ChevronUpIcon.vue'
import { Role } from '@core/domain/types/role.type'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import { useStorage } from '@vueuse/core'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Review } from '@core/domain/models/review.model'
import ProjectItemActions from './ProjectPlan/ProjectSettings/ProjectItemActions.vue'
import ReviewItemActions from './ProjectPlan/ReviewSettings/Index.vue'
import NoReviewIllustration from '../../../assets/images/NoReviewIllustration.png'
import { DocumentTextIcon } from '@heroicons/vue/24/solid'

const props = defineProps<{
  project: Project
  users: User[]
  review?: Review
}>()

const isExpanded = useStorage(`isShown-${props.project.id}`, true)

const snackbar = useSnackbar()
const projects = useProjects()
const { user } = injectStrict(AuthKey)

function toggleFavorite() {
  projects.toggleFavorite(props.project.id!)
  if (props.project.isFavorite) {
    snackbar.show(SnackbarState.SUCCESS, 'Set project as favorite')
  } else {
    snackbar.show(SnackbarState.SUCCESS, 'Set project as unfavorite')
  }
}

const shouldRender = ref(false)
onMounted(() => {
  nextTick(() => {
    const element = document.querySelector(`#project-${props.project.id}`)!
    const callback: IntersectionObserverCallback = (entries, observer) => {
      if (!entries[0].isIntersecting) return
      observer.unobserve(element)
      shouldRender.value = true
    }
    const observer = new IntersectionObserver(callback)
    observer.observe(element)
  })
})

const isAdmin = computed(() => user.value?.role === Role.ADMIN)
</script>
