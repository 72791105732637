<template>
  <div
    class="sticky bottom-0 z-50 inline-grid"
    :style="[
      gridStyle,
      {
        height: isExpanded ? '200px' : '50px',
      },
    ]"
  >
    <div class="sticky left-0 z-[51] bottom-0 border-l-[24px] border-white">
      <button
        class="px-4 py-2 gap-2 w-full h-full flex bg-blue-100 items-start"
        @click="isExpanded = !isExpanded"
      >
        <div class="flex items-center">
          <ChevronDoubleUpIcon v-if="!isExpanded" class="w-5" />
          <ChevronDoubleDownIcon v-else class="w-5" />
          Synthesis
        </div>
      </button>
    </div>
    <div
      v-for="attributeStructure in review.sortedAttributes.value"
      :key="attributeStructure.id"
      class="border bg-blue-100 relative"
      :class="isExpanded ? 'h-[200px]' : 'h-[50px]'"
    >
      <AttributeSynthesis
        :attribute-structure="attributeStructure"
        :is-expanded="isExpanded"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../use-review'
import { ref } from 'vue'
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/vue/24/outline'
import AttributeSynthesis from './AttributeSynthesis.vue'

defineProps<{
  gridStyle: string
}>()
const review = injectStrict(ReviewKey)
const isExpanded = ref(false)
</script>
