<template>
  <div class="p-5">
    <div class="space-y-6">
      <fieldset>
        <legend class="text-sm text-black font-medium pointer-events-none">
          Select a medical indication
        </legend>
        <div
          class="mt-4 space-y-6 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
        >
          <div
            v-for="MedicalIndication in medicalIndications"
            :key="MedicalIndication"
            class="flex items-center"
          >
            <input
              :id="MedicalIndication"
              v-model="preset.problem"
              :value="MedicalIndication"
              name="medical-condition"
              type="radio"
              class="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-primary-600 checked:bg-primary-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
            />
            <label
              :for="MedicalIndication"
              class="ml-3 block text-sm/6 font-medium text-gray-900"
            >
              {{ MedicalIndication }}
            </label>
          </div>
        </div>
      </fieldset>
      <div class="space-y-2">
        <p class="text-sm text-black font-medium pointer-events-none">
          Specify the condition name
        </p>
        <div>
          <TextInput
            v-model="preset.problemName"
            placeholder="Condition name"
            type="text"
            class="w-96"
          >
            <template #suffix>
              <button
                class="inline-flex items-center gap-2 rounded-md border border-gray-300 bg-primary-500 px-3 py-2 text-xs text-white shadow-md transition hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-400"
              >
                Press Enter
                <ArrowTurnDownLeftIcon class="w-4 h-4 text-white" />
              </button>
            </template>
          </TextInput>
        </div>
      </div>

      <div>
        <span class="text-sm text-black font-medium pointer-events-none">
          Are there common alternative names?
        </span>
        <div class="flex flex-wrap gap-2 mt-2">
          <span
            class="bg-tertiary-50 text-tertiary-500 px-3 py-1 rounded-lg flex items-center text-sm font-medium"
          >
            Croniq Pulmonary Disease (CPD)
            <button class="ml-2 text-tertiary-500">✕</button>
          </span>
        </div>
        <button class="text-secondary-500 mt-2 text-sm">
          + Add alternative name
        </button>
      </div>
      <fieldset>
        <legend class="text-sm text-black font-medium pointer-events-none">
          Specify your objective
        </legend>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mt-2">
          <template v-for="objective in Objectives" :key="objective">
            <label
              :for="`objective-${objective}`"
              class="font-medium text-gray-900 select-none p-3 flex items-center justify-between border rounded-lg"
            >
              {{ objective }}
              <div class="group grid size-4 grid-cols-1">
                <input
                  :id="`objective-${objective}`"
                  v-model="selectedObjectives"
                  :name="`objective-${objective}`"
                  type="checkbox"
                  :value="objective"
                  class="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-primary-600 checked:bg-primary-600 indeterminate:border-primary-600 indeterminate:bg-primary-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                />
                <svg
                  class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-disabled:stroke-gray-950/25"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    class="opacity-0 group-has-checked:opacity-100"
                    d="M3 8L6 11L11 3.5"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    class="opacity-0 group-has-indeterminate:opacity-100"
                    d="M3 7H11"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </label>
          </template>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ArrowTurnDownLeftIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue'
import { CreateReviewKey } from '@app/views/Projects/Project/CreateReview/useCreateProjectReview'

const { preset } = injectStrict(CreateReviewKey)

const medicalIndications = ['Disease', 'Injury', 'Disability']
const Objectives = [
  'Prevention',
  'Prediction',
  'Prognosis',
  'Diagnosis',
  'Treatment',
  'Alleviation',
  'Monitoring',
  'Compensation',
]
const selectedObjectives = ref(['Prevention', 'Prediction'])
</script>
