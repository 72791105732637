<template>
  <Modal ref="modal">
    <template #activator="{ show }">
      <button
        class="bg-stone-100 text-stone-800 px-5 py-2 font-medium rounded-full whitespace-nowrap flex items-center gap-1"
        @click="areAllStudiesProcessed ? exportRis() : show()"
      >
        <TableCellsIcon class="w-5" />RIS (filtered results)
        <ArrowDownTrayIcon class="w-4" />
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        RIS (filtered results)
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content>
      <p v-if="!areAllStudiesProcessed" class="text-sm text-gray-500">
        Some studies are missing their appraisal or screening, are you sure you
        would like to proceed with the export?
      </p>
    </template>

    <template #footer="{ hide }">
      <div class="sm:flex sm:flex-row-reverse gap-2">
        <button
          type="button"
          class="inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-primary-700 sm:w-auto"
          @click=";[hide(), exportRis()]"
        >
          Proceed
        </button>
        <button
          type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          @click="hide"
        >
          Cancel
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { generateRisContent } from '@app/utils/generate-ris-content'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
import { TableCellsIcon } from '@heroicons/vue/24/solid'
import useExport from './useExport'
import XIcon from '@app/components/Icons/XIcon.vue'

const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()

function exportRis() {
  try {
    if (!review.entity.value) throw new Error('search not set')
    loading.start()
    let rawRisContent = generateRisContent(review.filteredReviewItems.value)

    const blob = new Blob([rawRisContent], { type: 'text/plain' })
    let link = document.createElement('a')
    link.setAttribute('href', window.URL.createObjectURL(blob))
    link.setAttribute(
      'download',
      review.entity.value.project?.name +
        ' - ' +
        review.entity.value?.name +
        '.ris',
    )
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    snackbar.error('Failed to export RIS')
    throw e
  } finally {
    loading.stop()
  }
}

const { areAllStudiesProcessed } = useExport(review.entity.value)
</script>
