<template>
  <Modal v-if="isAdmin" ref="modal" position="center">
    <template #activator="{ show }">
      <button
        class="px-4 py-3 flex items-center gap-2 hover:bg-light-neutral-500 w-full"
        @click.stop="show()"
      >
        <PencilIcon class="size-5 text-dark-neutral-500" />
        <a class="font-medium text-dark-neutral-500 relative text-sm">
          Rename Review
          <span class="absolute inset-0" />
        </a>
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Rename Review
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content="{ hide }">
      <div>
        <input
          id="rename-review"
          v-model="newReviewName"
          type="text"
          name="rename-review"
          placeholder="Rename review"
          class="block min-w-0 grow px-3 py-1.5 text-base text-dark-neutral-500 placeholder:text-mid-neutral-400 focus:outline-none sm:text-sm/6 border rounded-lg w-full"
          @save="updateReviewName(project.id!, review.id, newReviewName, hide)"
        />
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="flex justify-end gap-2">
        <ActionButton color="neutral" @click="hide"> Cancel </ActionButton>
        <ActionButton
          color="primary"
          @click="updateReviewName(project.id!, review.id, newReviewName, hide)"
        >
          Update
        </ActionButton>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { SnackbarState } from '@app/types'
import useSnackbar from '@app/composables/use-snackbar'
import { computed, ref } from 'vue'
import { Id } from '@core/domain/types/id.type'
import useLoading from '@app/composables/use-loading'
import useProjects from '@app/views/Projects/use-projects'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { Role } from '@core/domain/types/role.type'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Review } from '@core/domain/models/review.model'
import { PencilIcon } from '@heroicons/vue/24/outline'
import { Project } from '@core/domain/models/project.model'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import ActionButton from '@app/components/Global/ActionButton.vue'

const props = defineProps<{ review: Review; project: Project }>()
const newReviewName = ref(props.review.name)

const snackbar = useSnackbar()
const loading = useLoading()
const projects = useProjects()
const { user } = injectStrict(AuthKey)

async function updateReviewName(
  projectId: Id,
  reviewId: Id,
  name: string,
  cb: () => void,
): Promise<void> {
  try {
    loading.start()
    await projects.updateReviewName(projectId, reviewId, name)
    snackbar.show(SnackbarState.SUCCESS, 'Review name updated')
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

const isAdmin = computed(() => user.value?.role === Role.ADMIN)
</script>
