<template>
  <button
    class="bg-blue-100 text-blue-800 px-4 py-2 rounded-full whitespace-nowrap flex items-center gap-1"
    @click="exportPlan"
  >
    <DocumentTextIcon class="w-5" /> Plan
    <ArrowDownTrayIcon class="w-4" />
  </button>
</template>

<script lang="ts" setup>
import { ReviewKey } from '@app/views/Review/use-review'
import { hideAll } from 'tippy.js'
import { injectStrict } from '@app/utils/injectStrict'
import useUsers from '@app/composables/use-users'
import { generateProtocol } from './utils/generate-protocol'
import { generatePrismaDiagram } from './utils/generate-prisma-diagram'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
import { DocumentTextIcon } from '@heroicons/vue/24/solid'

const review = injectStrict(ReviewKey)

const users = useUsers()
users.refresh()
const loading = useLoading()
const snackbar = useSnackbar()

async function exportPlan(): Promise<void> {
  loading.start()
  try {
    const prismaDiagramImage = await generatePrismaDiagram(review.entity.value)

    const protocol = await generateProtocol({
      review: review.entity.value,
      appraisalCriteria: review.entity.value.plan?.appraisalCriteria ?? [],
      attributesStructure: review.sortedAttributes.value,
      prismaDiagramImage,
      inclusionCriteria: review.inclusionCriteria.value!,
      isPlanIncluded: true,
      isReportIncluded: false,
    })

    const downloadElement = document.createElement('a')
    downloadElement.style.display = 'none'
    downloadElement.href = window.URL.createObjectURL(new Blob([protocol]))
    downloadElement.download =
      review.entity.value?.project?.name +
      ' - ' +
      review.entity.value?.name +
      '.docx'
    document.body.appendChild(downloadElement)
    downloadElement.click()
    window.URL.revokeObjectURL(downloadElement.href)
    downloadElement.remove()
    hideAll()
    snackbar.success('Plan exported successfully')
  } catch (error) {
    snackbar.error('Failed to export plan')
    throw error
  } finally {
    loading.stop()
  }
}
</script>
