<template>
  <div class="flex items-center gap-2">
    <FloatingMenu
      ref="floatingMenu"
      placement="bottom-start"
      :arrow="true"
      :offset="[0, 10]"
    >
      <template #activator>
        <button
          class="font-semibold border p-5 text-white rounded-full bg-primary-50 w-5 h-5 text-sm uppercase flex items-center justify-center"
          @click="openMenu"
        >
          <div class="flex items-center">
            <p class="font-medium text-base text-primary-500">
              {{ user?.firstName?.charAt(0) }}{{ user?.lastName?.charAt(0) }}
            </p>
          </div>
        </button>
      </template>
      <template #content>
        <div
          class="flex items-center gap-2 border-b py-2 pb-3 px-4 hover:bg-light-neutral-300 w-[16rem]"
        >
          <button
            class="font-bold border p-5 rounded-full text-primary-500 bg-primary-50 w-5 h-5 text-sm uppercase flex items-center justify-center"
          >
            <div class="flex items-center">
              <p class="font-medium text-base text-primary-500">
                {{ user?.firstName?.charAt(0) }}{{ user?.lastName?.charAt(0) }}
              </p>
            </div>
          </button>
          <div class="flex items-center gap-1">
            <p class="font-medium text-dark-neutral-500 text-base">
              {{
                user?.firstName
                  ? user.firstName.charAt(0).toUpperCase() +
                    user.firstName.slice(1)
                  : ''
              }}
            </p>
            <p class="font-medium text-dark-neutral-500 text-base">
              {{
                user?.lastName
                  ? user.lastName.charAt(0).toUpperCase() +
                    user.lastName.slice(1)
                  : ''
              }}
            </p>
          </div>
        </div>
        <div>
          <Modal ref="modal" :show-footer="false" position="center">
            <template
              v-if="user?.role !== Role.READ_ONLY"
              #activator="{ show }"
            >
              <div
                class="font-medium text-dark-neutral-500 text-base hover:bg-light-neutral-300 px-4 py-1 pt-2"
              >
                <button
                  class="py-2 tracking-wide w-full flex gap-2"
                  @click="show"
                >
                  <Cog6ToothIcon class="size-6" />
                  Settings
                </button>
              </div>
            </template>

            <template #header="{ hide }">
              <p
                class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
              >
                Settings
              </p>
              <button
                class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
                @click="hide"
              >
                <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
              </button>
            </template>

            <template #content>
              <div class="w-full space-y-4"><UserSettings /></div>
            </template>
          </Modal>

          <div
            class="font-medium text-error-500 text-base hover:bg-light-neutral-300 px-4 py-1"
          >
            <button
              class="py-2 tracking-wide hover:bg-light-neutral-300 w-full flex gap-2"
              @click="logout"
            >
              <ArrowLeftEndOnRectangleIcon class="size-6" />
              logout
            </button>
          </div>
        </div>
      </template>
    </FloatingMenu>
  </div>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { Role } from '@core/domain/types/role.type'
import { AuthKey } from '@app/injectionKeys'
import useWebExtension from '@app/composables/useWebExtension'
import {
  ArrowLeftEndOnRectangleIcon,
  Cog6ToothIcon,
} from '@heroicons/vue/24/outline'
import XIcon from '@app/components/Icons/XIcon.vue'
import UserSettings from '../Settings/UserSettings/UserSettings.vue'

const { user, logout: authLogout } = injectStrict(AuthKey)

const { logoutExtension } = useWebExtension()

const floatingMenu = ref()

async function openMenu() {
  floatingMenu?.value?.show()
}
async function logout() {
  authLogout()
  logoutExtension()
}
</script>
