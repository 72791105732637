<template>
  <header class="sticky top-0 left-0 bg-white" style="z-index: 99999999">
    <div
      class="flex px-[24px] w-full relative justify-between h-16 border-b border-mid-neutral-50 items-center"
    >
      <div class="space-y-1 text-xl font-semibold">
        {{ activeRouteName }}
      </div>
      <div id="header-middle" />
      <UserSettingsFloatingMenu />

      <div
        v-if="loading.isLoading.value"
        class="overflow-hidden absolute bottom-0 left-0 w-full h-1"
      >
        <div class="line absolute bg-primary-500/20 h-1"></div>
        <div class="absolute bg-primary-500 h-1 inc"></div>
        <div class="absolute bg-primary-500 h-1 dec"></div>
      </div>
    </div>

    <div
      v-if="changelog.isNewVersionAvailable.value"
      class="w-full bg-yellow-700 px-10 py-4 flex justify-between text-white"
    >
      <button
        class="underline"
        @click="
          () => {
            changelogModal?.show()
            changelog.acknowledgeUpdate()
          }
        "
      >
        New version available, click here for more details
      </button>
      <div class="flex gap-8">
        <button @click="changelog.acknowledgeUpdate">close</button>
        <button @click="changelog.disableUpdatesNotification">
          disable notification
        </button>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import useLoading from '../composables/use-loading'
import UserSettingsFloatingMenu from '@app/views/Users/UserSettingsFloatingMenu.vue'
import { computed, ref } from 'vue'
import useChangelog from '@app/composables/use-changelog'
import type Modal from '@app/components/Global/Modal/Modal.vue'
import { useRoute } from 'vue-router'

const loading = useLoading()
const changelogModal = ref<InstanceType<typeof Modal>>()
const changelog = useChangelog()
const vueRoute = useRoute()

const activeRouteName = computed(() => vueRoute?.name)
</script>
