<template>
  <Modal>
    <template #activator="{ show: showEditModal }">
      <button
        class="hover:brightness-150 flex items-center gap-1 leading-none p-2 hover:bg-black/20 w-full"
        @click.stop="showEditModal"
      >
        <PenIcon class="h-4 w-auto" />
        <p>Edit</p>
      </button>
    </template>
    <template #header="{ hide }">
      <div class="flex w-full justify-end">
        <button class="right-4 top-2" @click="hide">
          <XIcon class="w-6 h-6" />
        </button>
      </div>
    </template>
    <template #content>
      <div class="w-[1000px] edit-article-modal flex flex-col">
        <div class="overflow-auto flex-1 p-6 space-y-4">
          <div
            class="bg-white border border-slate-300 pt-4 pb-4 px-5 rounded-lg cursor-text space-y-4"
          >
            <label class="text-sm text-primary font-medium">Details </label>
            <div class="flex flex-col gap-6">
              <TextInput
                v-model="internalMetaData.title"
                placeholder="Study title"
                label="Title"
              />
              <TextInput
                v-model="internalMetaData.doi"
                placeholder="Study DOI"
                label="DOI"
              />

              <TextInput
                v-model="internalMetaData.journal"
                placeholder="Study journal"
                label="Journal"
              />
              <div class="flex flex-row gap-16">
                <TextInput
                  v-model="internalMetaData.publishYear"
                  placeholder="Year of publication"
                  label="Year of publication"
                />
                <TextInput
                  v-model="internalMetaData.pagesNumber"
                  placeholder="Study pages number"
                  label="Pages number"
                />
                <TextInput
                  v-model="internalMetaData.volumeNumber"
                  placeholder="Study volume number"
                  label="Volume number"
                />
                <TextInput
                  v-model="internalMetaData.issueNumber"
                  placeholder="Study issue number"
                  label="Issue number"
                />
              </div>
            </div>
          </div>
          <div
            class="bg-white border border-slate-300 pt-4 pb-4 px-5 rounded-lg cursor-text space-y-4"
          >
            <label class="text-sm text-primary font-medium">Authors </label>
            <div
              v-for="(author, i) in internalMetaData.authors"
              :key="'author-' + author"
              class="flex justify-between"
            >
              <TextInput
                v-model="internalMetaData.authors[i]"
                class="w-full"
                placeholder="Author Name"
              >
                <template #suffix>
                  <button
                    v-tooltip="'click to delete author'"
                    class="text-red-600"
                    @click="removeAuthor(internalMetaData.authors[i])"
                  >
                    <XCircleIcon class="w-5 h-5" />
                  </button>
                </template>
              </TextInput>
            </div>
            <button
              class="w-full mt-8 border-2 border-dashed border-blue-300 bg-blue-50 rounded-md text-blue-700 text-sm font-medium px-5 py-3 flex justify-center items-center"
              @click="addAuthor()"
            >
              <PlusIcon class="text-blue-800 w-4" />Add Author
            </button>
          </div>
          <div
            class="bg-white border border-slate-300 pt-4 pb-4 px-5 rounded-lg cursor-text space-y-4"
          >
            <label class="text-sm text-primary font-medium"> Abstract </label>
            <InlineTextInput
              v-model="internalMetaData.abstract"
              placeholder="Please Write Your Message"
              :multiline="true"
              class="w-full"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer="{ hide }">
      <div class="flex justify-between gap-6">
        <button
          class="w-full bg-white text-primary border border-primary rounded-md text-base tracking-tighter px-4 py-2 font-medium hover:brightness-110"
          @click="hide"
        >
          Cancel
        </button>
        <FloatingMenu
          v-if="!isValid"
          class="w-full"
          trigger="hover"
          :offset="[0, 10]"
        >
          <template #activator>
            <button
              class="cursor-not-allowed w-full bg-primary-500 text-white rounded-md text-base tracking-tighter px-4 py-2 font-medium opacity-50"
            >
              Save
            </button>
          </template>
          <template #content>
            <div
              class="flex flex-col justify-end gap-2 bg-white w-full py-2 px-2 sticky bottom-0 right-0"
            >
              <p v-if="internalMetaData.title === ''">Title is missing</p>

              <p v-if="internalMetaData.abstract === ''">Abstract is missing</p>
              <p
                v-if="internalMetaData.authors.every((author) => author === '')"
              >
                author name is missing
              </p>
            </div>
          </template>
        </FloatingMenu>
        <button
          v-else
          class="w-full bg-primary-500 text-white rounded-md text-base tracking-tighter px-4 py-2 font-medium hover:brightness-110"
          @click="updateStudy(props.id, internalMetaData, hide)"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useLoading from '@app/composables/use-loading'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import { Id } from '@core/domain/types/id.type'
import { MetaData } from '@core/domain/types/meta-data.type'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'
import PenIcon from '@app/components/Icons/PenIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { PlusIcon } from '@heroicons/vue/24/outline'
import XIcon from '@app/components/Icons/XIcon.vue'

const props = withDefaults(
  defineProps<{
    id: Id
    metaData: MetaData
  }>(),
  {},
)
function prepareMetaData(): MetaData {
  return props.metaData
}

const internalMetaData = ref<MetaData>(prepareMetaData())
const snackbar = useSnackbar()

const loading = useLoading()

const review = injectStrict(ReviewKey)

const isValid = computed(() => {
  const isTitleValid = internalMetaData.value.title !== ''
  const isAbstractValid = internalMetaData.value.abstract !== ''
  const isAuthorsValid = internalMetaData.value.authors.every(
    (author) => author !== '',
  )
  return isTitleValid && isAbstractValid && isAuthorsValid
})

function removeAuthor(authorName: string) {
  const authorNameIndex = internalMetaData.value.authors.findIndex(
    (author) => author === authorName,
  )
  internalMetaData.value?.authors.splice(authorNameIndex, 1)
}

function addAuthor() {
  internalMetaData.value.authors.push('')
}

async function updateStudy(stydyid: Id, data: MetaData, callback: () => void) {
  try {
    loading.start()
    await review.updateStudy(stydyid, {
      abstract: data.abstract,
      doi: data.doi,
      issueNumber: data.issueNumber,
      journal: data.journal,
      pagesNumber: data.pagesNumber,
      publishYear: data.publishYear,
      title: data.title,
      volumeNumber: data.volumeNumber,
      authors: [...data.authors],
    })
    snackbar.show(SnackbarState.SUCCESS, 'Study updated successfully')
    callback()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    loading.stop()
  }
}
</script>
<style>
.edit-article-modal {
  height: min(1000px, calc(100vh - 200px));
}
</style>
