<template>
  <table
    class="w-full divide-y divide-slate-200 overflow-hidden"
    aria-describedby="users table"
  >
    <thead class="bg-light-neutral-300 w-full">
      <tr>
        <th
          scope="col"
          class="py-3.5 pl-[24px] text-left text-sm font-medium text-mid-neutral-500"
        >
          Name
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-slate-200 bg-white border">
      <tr v-for="team in teams" :key="'user-' + team.id">
        <td class="py-4 px-6 text-dark-neutral-500">
          {{ team.name }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { TeamsServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { Team } from '@core/domain/models/team.model'
import { onMounted, ref } from 'vue'

const teamsService = injectStrict(TeamsServiceKey)
const teams = ref<Team[]>()

async function fetchTeams() {
  teams.value = await teamsService.findTeams()
}
onMounted(async () => await fetchTeams())
</script>
