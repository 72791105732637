import { ImportSource } from '@core/domain/models/import-source.model'
import { ref } from 'vue'
type BuiltInSource = ImportSource & {
  isEvidenceMetadataExtractionTechnology: boolean
}

const builtInImportSources = ref<BuiltInSource[]>([])
export default () => {
  async function set(sources: BuiltInSource[]) {
    builtInImportSources.value = sources
  }
  function findById(sourceId: string) {
    return builtInImportSources.value.find(
      (importSource) => importSource.id === sourceId,
    )
  }
  return { set, findById }
}
