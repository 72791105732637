import { DataExtractionCompletedEvent } from '@core/domain/events/dataExtractionCompleted.event'
import { DataExtractionFailedEvent } from '@core/domain/events/dataExtractionFailed.event'
import { DataExtractionStartedEvent } from '@core/domain/events/dataExtractionStarted.event'
import { ReviewItemFileUploaded } from '@core/domain/events/reviewItemFileUploaded'
import { ReviewSearchCreatedEvent } from '@core/domain/events/reviewSearchCreated.event'
import { EventDispatcher } from '@infrastructure/eventDispatcher/eventDispatcher'
import { io } from 'socket.io-client'

export class SocketGateway {
  private socket = io('/reviews', {
    path: '/events',
  })
  constructor(private readonly eventDispatcher: EventDispatcher) {}

  init() {
    this.socket.on('reviewUpdated', ({ reviewId }: { reviewId: number }) => {
      this.eventDispatcher.dispatch(new ReviewSearchCreatedEvent(reviewId))
    })
    this.socket.on(
      'dataExtractionCompleted',
      ({
        reviewId,
        reviewItemId,
      }: {
        reviewId: number
        reviewItemId: number
      }) => {
        this.eventDispatcher.dispatch(
          new DataExtractionCompletedEvent(reviewId, reviewItemId),
        )
      },
    )
    this.socket.on(
      'dataExtractionStarted',
      ({
        reviewId,
        reviewItemId,
      }: {
        reviewId: number
        reviewItemId: number
      }) => {
        this.eventDispatcher.dispatch(
          new DataExtractionStartedEvent(reviewId, reviewItemId),
        )
      },
    )
    this.socket.on(
      'dataExtractionFailed',
      ({
        reviewId,
        reviewItemId,
      }: {
        reviewId: number
        reviewItemId: number
      }) => {
        this.eventDispatcher.dispatch(
          new DataExtractionFailedEvent(reviewId, reviewItemId),
        )
      },
    )

    this.socket.on(
      'reviewItemFileUploaded',
      ({
        reviewId,
        reviewItemId,
      }: {
        reviewId: number
        reviewItemId: number
      }) => {
        this.eventDispatcher.dispatch(
          new ReviewItemFileUploaded(reviewId, reviewItemId),
        )
      },
    )
  }
}
