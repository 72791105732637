<template>
  <FloatingMenu v-if="isAdmin" :offset="[-10, 14]">
    <template #activator="{ show }">
      <button
        class="inline-flex items-center gap-x-1 text-sm font-semibold text-gray-900"
        @click.stop="show()"
      >
        <div
          class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center text-mid-neutral-500"
        >
          <EllipsisHorizontalIcon class="size-6" aria-hidden="true" />
        </div>
      </button>
    </template>
    <template #content>
      <div class="flex flex-col text-dark-neutral-500 w-[11rem]" @click.stop="">
        <RenameReviewModal :review="review" :project="project" />
        <ArchiveReviewModal :review="review" :project="project" />
        <DeleteReviewModal :review="review" :project="project" />
        <CreateReviewUpdate v-if="isCuttingEdge" :review="review" />
      </div>
    </template>
  </FloatingMenu>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { computed, onMounted, ref, nextTick } from 'vue'
import EllipsisHorizontalIcon from '@app/components/Icons/EllipsisHorizontalIcon.vue'
import { Role } from '@core/domain/types/role.type'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey, DevToolboxKey } from '@app/injectionKeys'
import { Review } from '@core/domain/models/review.model'
import { Project } from '@core/domain/models/project.model'
import DeleteReviewModal from '../ReviewSettings/DeleteReviewModal.vue'
import ArchiveReviewModal from '../ReviewSettings/ArchiveReviewModal.vue'
import RenameReviewModal from '../ReviewSettings/RenameReviewModal.vue'
import CreateReviewUpdate from './CreateReviewUpdate.vue'

const { isCuttingEdge } = injectStrict(DevToolboxKey)
const props = defineProps<{ review: Review; project: Project }>()
const { user } = injectStrict(AuthKey)

const shouldRender = ref(false)
onMounted(() => {
  nextTick(() => {
    const element = document.querySelector(`#project-${props.project.id}`)!
    const callback: IntersectionObserverCallback = (entries, observer) => {
      if (!entries[0].isIntersecting) return
      observer.unobserve(element)
      shouldRender.value = true
    }
    const observer = new IntersectionObserver(callback)
    observer.observe(element)
  })
})

const isAdmin = computed(() => user.value?.role === Role.ADMIN)
</script>
