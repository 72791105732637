import { IncidentMetadata } from '@core/domain/models/reviewItemMetadata/incidentMetadata'
import axios from 'axios'

export class OpenFdaApiClient {
  private readonly baseUrl = 'https://api.fda.gov/device/event.json'

  async searchArticles(query: string): Promise<IncidentMetadata[]> {
    return this.searchRecords(query)
  }

  private async searchRecords(query: string): Promise<IncidentMetadata[]> {
    let incidents: IncidentMetadata[] = []
    const url = `${this.baseUrl}?search=${query}&limit=500`
    try {
      const response = await axios.get(url)

      incidents = response.data.results.map((record: any) =>
        this.mapToIncident(record),
      )
    } catch (error) {
      console.error('Error fetching records from OpenFDA API:', error)
      return [] // Return an empty array on failure
    }

    return incidents
  }

  private mapToIncident(record: any): IncidentMetadata {
    return new IncidentMetadata({
      title: `${record.device[0].manufacturer_d_name} ${record.device[0].brand_name} ${record.device[0].generic_name}`,
      reportNumber: record.report_number,
      eventDate: record.date_of_event,
      eventType: record.event_type,
      reportDate: record.date_report,
      url: `https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfMAUDE/detail.cfm?mdrfoi__id=${record.mdr_report_key}`,
      summary: record.mdr_text
        .map(
          (mt: any) =>
            `${mt.text_type_code}: ${this.capitalizeSentences(mt.text)}\n`,
        )
        .join(''),
    })
  }

  private capitalizeSentences(text: string) {
    const lowercaseText = text.toLowerCase()

    const sentences = lowercaseText.split('. ')

    for (let i = 0; i < sentences.length; i++) {
      sentences[i] =
        sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1)
    }

    const result = sentences.join('. ')

    return result
  }
}
