<template>
  <div class="space-y-6 border border-slate-300 rounded-md p-6">
    <div class="flex space-x-2">
      <h3
        class="tracking-[-0.14px] text-base text-blue-700 font-semibold flex items-center gap-2"
      >
        Inclusion criteria and limits
      </h3>
    </div>
    <div class="flex gap-2 justify-between">
      <div class="flex gap-2 flex-col">
        <h3 class="text-base font-normal text-gray-700">
          Apply <strong>PICO</strong>
        </h3>
        <div>
          <p class="text-slate-500 text-sm font-[400]">
            Use the PICO framework (Population, Intervention, Comparator,
            Outcome) to structure your search strategy.
          </p>
        </div>
      </div>
      <Switch
        :disabled="review.isPlanReadonly.value"
        :model-value="
          review.entity.value?.plan?.inclusionCriteria
            .isPicoInclusionCriteriaApplicable
        "
        :class="[
          review.entity.value?.plan?.inclusionCriteria
            .isPicoInclusionCriteriaApplicable
            ? 'bg-green-600'
            : 'bg-gray-200',
          'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden ',
        ]"
        @update:model-value="togglePicoInclusionCriteria"
      >
        <span
          aria-hidden="true"
          :class="[
            review.entity.value?.plan?.inclusionCriteria
              .isPicoInclusionCriteriaApplicable
              ? 'translate-x-5'
              : 'translate-x-0',
            'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
    </div>
    <div
      v-if="
        !review.entity.value?.plan?.inclusionCriteria
          .isPicoInclusionCriteriaApplicable
      "
    >
      <div class="flex flex-wrap gap-4 items-center">
        <div
          v-for="criterion in inclusionCriteria"
          :key="'patient-criterion-' + criterion"
          class="flex items-center text-sm gap-1 px-4 py-2.5 rounded-full text-green-700 bg-green-100 font-semibold leading-none"
        >
          <span
            v-tooltip="criterion.length > 35 ? criterion : ''"
            class="truncate max-w-[252px]"
            >{{ criterion }}</span
          >
          <button
            v-if="!review.isPlanReadonly.value"
            @click="deleteInclusionCriterion(criterion)"
          >
            <XMarkIcon class="w-4 h-4" />
          </button>
        </div>
        <FloatingTextInput
          v-if="!review.isPlanReadonly.value"
          @save="addInclusionCriterion($event)"
        >
          <template #activator="{ show }">
            <button
              key=""
              class="flex items-center gap-1 text-sm border-2 py-1.5 px-3 rounded-full text-slate-500 border-slate-500 border-dashed font-semibold"
              @click.stop="show"
            >
              <PlusIcon class="w-4 h-4" /> Add criterion
            </button>
          </template>
        </FloatingTextInput>
      </div>
    </div>

    <div
      v-if="
        review.entity.value?.plan?.inclusionCriteria
          .isPicoInclusionCriteriaApplicable
      "
      class="flex flex-col gap-6"
    >
      <div class="space-y-4">
        <h3 class="text-gray-700 text-sm font-semibold">
          Patient, problem or population
        </h3>

        <div class="flex flex-wrap gap-4 items-center">
          <div
            v-for="criterion in patientCriteria"
            :key="'patient-criterion-' + criterion"
            class="flex items-center text-sm gap-1 px-4 py-2.5 rounded-full text-green-700 bg-green-100 font-semibold leading-none"
          >
            <span
              v-tooltip="criterion.length > 35 ? criterion : ''"
              class="truncate max-w-[252px]"
              >{{ criterion }}</span
            >
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deletePicoInclusionCriterion(
                  InclusionCriterion.Patient,
                  criterion,
                )
              "
            >
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            placeholder="e.g Patients under 25"
            @save="
              addPicoInclusionCriterion(InclusionCriterion.Patient, $event)
            "
          >
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" /> Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="text-gray-700 text-sm font-semibold">Intervention</h3>

        <div class="flex flex-wrap gap-4 items-center">
          <div
            v-for="criterion in interventionCriteria"
            :key="'intervention-criterion-' + criterion"
            class="flex items-center text-sm gap-1 px-4 py-2.5 rounded-full text-green-700 bg-green-100 font-semibold"
          >
            <span
              v-tooltip="criterion.length > 35 ? criterion : ''"
              class="truncate max-w-[252px]"
              >{{ criterion }}</span
            >
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deletePicoInclusionCriterion(
                  InclusionCriterion.Intervention,
                  criterion,
                )
              "
            >
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="
              addPicoInclusionCriterion(InclusionCriterion.Intervention, $event)
            "
          >
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" /> Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="text-gray-700 text-sm font-semibold">
          Comparison, control or comparator
        </h3>

        <div class="flex flex-wrap gap-4 items-center">
          <div
            v-for="criterion in comparisonControlComparator"
            :key="'comparison-control-comparator-' + criterion"
            class="flex items-center text-sm gap-1 px-4 py-2.5 rounded-full text-green-700 bg-green-100 font-semibold"
          >
            <span
              v-tooltip="criterion.length > 35 ? criterion : ''"
              class="truncate max-w-[252px]"
              >{{ criterion }}</span
            >
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deletePicoInclusionCriterion(
                  InclusionCriterion.ComparisonControlComparator,
                  criterion,
                )
              "
            >
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="
              addPicoInclusionCriterion(
                InclusionCriterion.ComparisonControlComparator,
                $event,
              )
            "
          >
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" /> Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="text-gray-700 text-sm font-semibold">Outcomes</h3>

        <div class="flex flex-wrap gap-4 items-center">
          <div
            v-for="criterion in outcomesCriteria"
            :key="'outcomes-criterion-' + criterion"
            class="flex items-center text-sm gap-1 px-4 py-2.5 rounded-full text-green-700 bg-green-100 font-semibold"
          >
            <span
              v-tooltip="criterion.length > 35 ? criterion : ''"
              class="truncate max-w-[252px]"
              >{{ criterion }}</span
            >
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deletePicoInclusionCriterion(
                  InclusionCriterion.Outcomes,
                  criterion,
                )
              "
            >
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="
              addPicoInclusionCriterion(InclusionCriterion.Outcomes, $event)
            "
          >
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" /> Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
      <div class="border w-full block"></div>
      <div class="grid grid-cols-2 gap-12">
        <div class="space-y-6">
          <div class="space-y-2">
            <h3 class="text-gray-700 text-sm font-semibold">Timing</h3>
          </div>
          <div class="flex flex-wrap gap-4 items-center">
            <div
              v-for="criterion in timingCriteria"
              :key="'timing-criterion-' + criterion"
              class="flex items-center text-sm gap-1 px-4 py-2.5 rounded-full text-green-700 bg-green-100 font-semibold leading-none"
            >
              <span
                v-tooltip="criterion.length > 35 ? criterion : ''"
                class="truncate max-w-[252px]"
                >{{ criterion }}</span
              >
              <button
                v-if="!review.isPlanReadonly.value"
                @click="
                  deletePicoInclusionCriterion(
                    InclusionCriterion.Timing,
                    criterion,
                  )
                "
              >
                <XMarkIcon class="w-4 h-4" />
              </button>
            </div>
            <FloatingTextInput
              v-if="!review.isPlanReadonly.value"
              @save="
                addPicoInclusionCriterion(InclusionCriterion.Timing, $event)
              "
            >
              <template #activator="{ show }">
                <button
                  key=""
                  class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                  @click.stop="show"
                >
                  <PlusIcon class="w-4 h-4" />Add criterion
                </button>
              </template>
            </FloatingTextInput>
          </div>
        </div>
        <div class="space-y-6">
          <div class="space-y-2">
            <h3 class="text-gray-700 text-sm font-semibold">Study design</h3>
          </div>
          <div class="flex flex-wrap gap-4 items-center">
            <div
              v-for="criterion in studyDesignCriteria"
              :key="'study-design-criterion-' + criterion"
              class="flex items-center text-sm gap-1 px-4 py-2.5 rounded-full text-green-700 bg-green-100 font-semibold leading-none"
            >
              <span
                v-tooltip="criterion.length > 35 ? criterion : ''"
                class="truncate max-w-[252px]"
                >{{ criterion }}</span
              >
              <button
                v-if="!review.isPlanReadonly.value"
                @click="
                  deletePicoInclusionCriterion(
                    InclusionCriterion.StudyDesign,
                    criterion,
                  )
                "
              >
                <XMarkIcon class="w-4 h-4" />
              </button>
            </div>
            <FloatingTextInput
              v-if="!review.isPlanReadonly.value"
              @save="
                addPicoInclusionCriterion(
                  InclusionCriterion.StudyDesign,
                  $event,
                )
              "
            >
              <template #activator="{ show }">
                <button
                  key=""
                  class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                  @click.stop="show"
                >
                  <PlusIcon class="w-4 h-4" /> Add criterion
                </button>
              </template>
            </FloatingTextInput>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import { InclusionCriterion } from '@core/domain/models/InclusionCriterion.model'

import { Switch } from '@headlessui/vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import PlusIcon from '@app/components/Icons/PlusIcon.vue'

const review = injectStrict(ReviewKey)

const loading = useLoading()
const snackbar = useSnackbar()

const inclusionCriteria = ref<string[]>([])

const patientCriteria = ref<string[]>([])
const interventionCriteria = ref<string[]>([])
const outcomesCriteria = ref<string[]>([])
const timingCriteria = ref<string[]>([])
const studyDesignCriteria = ref<string[]>([])
const comparisonControlComparator = ref<string[]>([])

watchEffect(() => {
  patientCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.patient ?? []

  interventionCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.intervention ?? []

  outcomesCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.outcomes ?? []

  timingCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.timing ?? []

  studyDesignCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.studyDesign ?? []

  comparisonControlComparator.value =
    review.entity.value?.plan?.inclusionCriteria?.comparisonControlComparator ??
    []

  inclusionCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.criteria ?? []
})

async function togglePicoInclusionCriteria() {
  loading.start()
  try {
    if (
      review.entity.value.plan?.inclusionCriteria
        .isPicoInclusionCriteriaApplicable
    ) {
      await review.disablePicoInclusionCriteria()
      snackbar.show(
        SnackbarState.SUCCESS,
        'PICO inclusion criteria disabled successfully',
      )
    } else {
      await review.enablePicoInclusionCriteria()
      snackbar.show(
        SnackbarState.SUCCESS,
        'PICO inclusion criteria enabled successfully',
      )
    }
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while enabling/disabling PICO inclusion criteria, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function addPicoInclusionCriterion(
  criterionType: InclusionCriterion,
  criterion: string,
) {
  loading.start()
  try {
    await review.addPicoInclusionCriterion({
      criterion: criterion,
      criterionType: criterionType,
    })
    snackbar.show(SnackbarState.SUCCESS, 'Criterion added successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        `An error occured while adding criterion to ${{ criterionType }}, please try again.`,
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deletePicoInclusionCriterion(
  criterionType: InclusionCriterion,
  criterion: string,
) {
  loading.start()
  try {
    await review.deletePicoInclusionCriterion({
      criterion: criterion,
      criterionType: criterionType,
    })
    snackbar.show(SnackbarState.SUCCESS, 'Criterion deleted successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        `An error occured while deleting ${{ criterionType }}, please try again.`,
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function addInclusionCriterion(criterion: string) {
  loading.start()
  try {
    await review.addInclusionCriterion(criterion)
    snackbar.show(SnackbarState.SUCCESS, 'Criterion added successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        `An error occured while adding criterion to inclusion criteria, please try again.`,
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteInclusionCriterion(criterion: string) {
  loading.start()
  try {
    await review.deleteInclusionCriterion(criterion)
    snackbar.show(SnackbarState.SUCCESS, 'Criterion deleted successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        `An error occured while deleting inclusion criterion, please try again.`,
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}
</script>
