import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { generateSearchQuery } from '@app/utils/generateSearchQuery'
import { Review } from '@core/domain/models/review.model'
import { ArticleMetadata } from '@core/domain/models/reviewItemMetadata/articleMetadata'
import { FieldSafetyNoticeMetadata } from '@core/domain/models/reviewItemMetadata/fieldSafetyNoticeMetadata'
import { IncidentMetadata } from '@core/domain/models/reviewItemMetadata/incidentMetadata'
import { RegistrationMetadata } from '@core/domain/models/reviewItemMetadata/registrationMetadata'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import { CochraneApiClient } from '@infrastructure/cochraneApi/cochraneApi.client'
import { ReviewsRepository } from '@infrastructure/datasource/reviews.repository'
import { EuropePmcApiClient } from '@infrastructure/europePmcApi/europePmcApi.client'
import { FsnApiClient } from '@infrastructure/fsnApi/fsnApi.client'
import { OpenAlexApiClient } from '@infrastructure/openAlexApi/openAlexApi.client'
import { OpenFdaApiClient } from '@infrastructure/openFdaApi/openFdaApi.client'
import { PmcApiClient } from '@infrastructure/pmcApi/pmcApi.client'
import { ProsperoApiClient } from '@infrastructure/prosperoApi/prosperoApi.client.'
import { PubmedApiClient } from '@infrastructure/pubmedApi/pubmedApi.client'

export class AutoImportService {
  private readonly builtInImportSources = useBuiltInImportSources()
  constructor(
    private readonly _reviewsRepository: ReviewsRepository,
    private readonly pmcApiClient: PmcApiClient,
    private readonly europePmcApiClient: EuropePmcApiClient,
    private readonly openAlexApiClient: OpenAlexApiClient,
    private readonly pubmedApiClient: PubmedApiClient,
    private readonly fsnApiClient: FsnApiClient,
    private readonly prosperoApiClient: ProsperoApiClient,
    private readonly cochraneApiClient: CochraneApiClient,
    private readonly openFdaApiClient: OpenFdaApiClient,
  ) {}

  async *handleWithProgress({ reviewId }: { reviewId: number }) {
    let sourceApis: any[] = []
    const deviceSpecificSearchSources = [
      {
        client: this.pubmedApiClient,
        source: this.builtInImportSources.findById(
          BuiltInImportSourceId.PUBMED,
        ),
      },
      {
        client: this.openAlexApiClient,
        source: this.builtInImportSources.findById(
          BuiltInImportSourceId.OPEN_ALEX,
        ),
      },
      {
        client: this.europePmcApiClient,
        source: this.builtInImportSources.findById(
          BuiltInImportSourceId.EUROPE_PMC,
        ),
      },
      {
        client: this.pmcApiClient,
        source: this.builtInImportSources.findById(BuiltInImportSourceId.PMC),
      },
      {
        client: this.fsnApiClient,
        source: this.builtInImportSources.findById(
          BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
        ),
      },
      {
        client: this.openFdaApiClient,
        source: this.builtInImportSources.findById(
          BuiltInImportSourceId.OPEN_FDA,
        ),
      },
    ]
    const medicalBackgroundSources = [
      {
        client: this.prosperoApiClient,
        id: BuiltInImportSourceId.PROSPERO,
      },
      {
        client: this.cochraneApiClient,
        id: BuiltInImportSourceId.COCHRANE,
      },
    ]
    try {
      const review = await this._reviewsRepository.findById(reviewId)
      if (!review) return
      if (
        !review.plan?.preset ||
        ![
          ReviewPreset.DEVICE_SPECIFIC_SEARCH,
          ReviewPreset.MEDICAL_BACKGROUND,
        ].includes(review.plan.preset)
      )
        return

      if (review.plan.preset === ReviewPreset.DEVICE_SPECIFIC_SEARCH) {
        sourceApis = deviceSpecificSearchSources
      }
      if (review.plan.preset === ReviewPreset.MEDICAL_BACKGROUND) {
        sourceApis = medicalBackgroundSources
      }

      for (const sourceApi of sourceApis) {
        yield `Searching in ${sourceApi.source.name} for ${generateSearchQuery(
          sourceApi.source,
          review,
        )}`
        await this.processSource(sourceApi, review, sourceApi.source.id)
      }
    } catch (error: any) {
      console.error(
        `Error handling ReviewCreatedEvent: ${error.message}`,
        error.stack,
      )
    }
  }

  private async processSource(
    source: any,
    review: Review,
    importSourceId: string,
  ) {
    try {
      const query = generateSearchQuery(source.source, review)

      let articles:
        | ArticleMetadata[]
        | FieldSafetyNoticeMetadata[]
        | RegistrationMetadata[]
        | IncidentMetadata[]

      let queryTranslation: string | undefined

      if (importSourceId === BuiltInImportSourceId.FIELD_SAFETY_NOTICES) {
        articles = await source.client.searchArticles(
          query,
          review.plan?.fsnCountries,
        )
      } else if (
        importSourceId === BuiltInImportSourceId.PUBMED ||
        importSourceId === BuiltInImportSourceId.PMC
      ) {
        const result = await source.client.searchArticles(query)
        articles = result.articles
        queryTranslation = result.queryTranslation
      } else {
        articles = await source.client.searchArticles(query)
      }

      let items
      if (importSourceId === BuiltInImportSourceId.FIELD_SAFETY_NOTICES) {
        items = articles?.map((article) => ({
          type: ReviewItemType.FieldSafetyNotice,
          ...article,
        }))
      } else if (importSourceId === BuiltInImportSourceId.PROSPERO) {
        items = articles?.map((article) => ({
          type: ReviewItemType.Registration,
          ...article,
        }))
      } else if (importSourceId === BuiltInImportSourceId.OPEN_FDA) {
        items = articles?.map((article) => ({
          type: ReviewItemType.Incident,
          ...article,
        }))
      } else {
        items = articles?.map((article) => ({
          type: ReviewItemType.Article,
          ...article,
        }))
      }
      if (importSourceId === BuiltInImportSourceId.OPEN_FDA) {
        await this._reviewsRepository.addCustomImportSourceToPlan(review.id, {
          name: source.source.name,
          type: source.source.type,
          url: source.source.url,
          description: source.source.description,
        })
      }
      await this._reviewsRepository.importSearch({
        reviewId: review.id,
        date: new Date().toISOString(),
        query,
        importSourceId,
        queryTranslation,
        items,
      })
    } catch (error: any) {
      console.error(
        `Error processing source ${source.source?.name || 'unknown'}: ${error.message}`,
        error.stack,
      )
    }
  }
}
