<template>
  <div ref="editorContainer" class="h-full">
    <Editor
      v-if="isVisible"
      v-model:html="answer"
      :is-expanded="isExpanded"
      :provider="dataExtractionProvider"
      :user-name="userName"
      :user-color="color"
      :disabled="isReviewReadonly"
      :field="'study::' + study.id + '|attribute::' + id"
      placeholder="Enter the information extracted from the record. Use a dash '-' if the record does not contain information."
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, useTemplateRef } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import Editor from '../../components/Editor.vue'

defineProps<{
  isExpanded: boolean
  isReviewReadonly: boolean
  placeholder?: string
  id: string
  study: ReviewItem
}>()
const { user, color } = injectStrict(AuthKey)
const userName = computed(
  () => user.value?.firstName + ' ' + user.value?.lastName,
)
const { dataExtractionProvider } = injectStrict(ReviewKey)

const isVisible = ref(false)
const editorContainer = useTemplateRef('editorContainer')

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        isVisible.value = true
        observer.disconnect()
      }
    },
    {
      rootMargin: '50px',
      threshold: 0.1,
    },
  )

  if (editorContainer.value) {
    observer.observe(editorContainer.value)
  }
})
const answer = defineModel<string>()
</script>
