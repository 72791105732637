<template>
  <HeadlessUiCombobox
    v-model="selectedItem"
    :items="props.items"
    :label="props.label"
    as="div"
    class="space-y-1"
    @update:model-value="query = ''"
  >
    <slot name="label">
      <ComboboxLabel class="block text-sm font-medium leading-5 text-slate-700">
        {{ label }}
      </ComboboxLabel>
    </slot>
    <div class="relative">
      <ComboboxInput
        class="w-full rounded-md hover:outline-blue-500 bg-white px-3.5 py-2.5 text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-1 focus:-outline-offset-1 focus:outline-blue-500 sm:text-sm"
        :display-value="displayValue ?? selectedItem"
        :placeholder="placeholder"
        @change="query = $event.target.value"
        @blur="query = ''"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-hidden"
      >
        <ChevronUpDownIcon class="size-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        class="absolute z-[9999999999999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm"
      >
        <div
          v-if="filteredItems.length === 0 && query !== ''"
          class="relative cursor-default select-none px-4 py-2 text-gray-700"
        >
          Nothing found.
        </div>
        <ul>
          <ComboboxOption
            v-for="item in filteredItems"
            :key="item.id"
            v-slot="{ active, selected }"
            :value="item"
            as="template"
          >
            <li
              :class="[
                'relative cursor-default select-none py-2 pl-8 pr-4',
                active
                  ? 'bg-primary-500 text-white outline-hidden'
                  : 'text-gray-900',
              ]"
            >
              <span :class="['block truncate', selected && 'font-semibold']">
                {{ displayValue(item) }}
              </span>

              <span
                v-if="selected"
                :class="[
                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  active ? 'text-white' : 'text-primary',
                ]"
              >
                <CheckIcon class="size-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ul>
      </ComboboxOptions>
    </div>
  </HeadlessUiCombobox>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import {
  Combobox as HeadlessUiCombobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'

const props = withDefaults(
  defineProps<{
    items: any[]
    label?: string
    filter?: (item: any, query: string) => boolean
    displayValue?: (item: any) => string
    placeholder?: string
  }>(),
  {
    displayValue: (item: any) => item,
    filter: (item: any, query: string) => item.includes(query),
    placeholder: '',
    label: '',
  },
)

if (typeof props.items[0] !== 'string') {
  if (!props.displayValue)
    throw new Error('displayValue is required when items are not strings')
  if (!props.filter)
    throw new Error('filter is required when items are not strings')
}

const query = ref('')
const selectedItem = defineModel<any>()
const filteredItems = computed(() =>
  query.value === ''
    ? props.items
    : props.items.filter((item) => {
        return props.filter(item, query.value)
      }),
)
</script>
