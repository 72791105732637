<template>
  <div class="flex items-center space-x-2">
    <template v-for="tab in tabs" :key="'tab-' + tab.number">
      <button :disabled="tab.isDisabled" @click="onClick(tab)">
        <slot :tab="tab">
          <div
            :class="{
              'bg-blue-100   text-blue-600': tab.number === activeTabNumber,
              'text-gray-500': tab.number !== activeTabNumber,
            }"
            class="py-2 px-3 rounded-md text-sm font-medium"
          >
            {{ tab.title }}
          </div>
        </slot>
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
type Tab = {
  number: number
  title?: string
  subtitle?: string
  isDisabled?: boolean
}
defineProps<{
  tabs: Tab[]
  activeTabNumber: number
}>()
function onClick(tab: Tab) {
  emit('tab:clicked', tab)
}
const emit = defineEmits<(e: 'tab:clicked', tab: Tab) => void>()
</script>
