<template>
  <Modal
    v-if="isAdmin"
    ref="modal"
    :show-footer="false"
    dismissible
    position="center"
  >
    <template #activator="{ show }">
      <button
        :class="[
          'px-4 py-3 flex items-center gap-2',
          isShown ? 'hover:bg-light-neutral-500' : '',
        ]"
        @click.stop="show"
      >
        <UserPlusIcon class="size-5 text-dark-neutral-500" />
        <a class="font-medium text-dark-neutral-500 relative text-sm">
          Assign Members
          <span class="absolute inset-0" />
        </a>
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Assign Members
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content>
      <div class="w-full">
        <ProjectMembers :project="project" class="shrink-0 w-full mb-5" />

        <div class="w-full">
          <div class="w-full flex flex-wrap gap-4">
            <template v-for="user in project.users" :key="'user-' + user.id">
              <div
                class="rounded-full text-black text-center max-w-fit px-3 py-2 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                :style="{
                  'background-color': `${usersColors[user?.id ?? '']}40`,
                }"
              >
                <div class="leading-none">
                  {{ buildUserFullname(user) }}
                </div>
                <div
                  class="w-5 h-5 flex items-center justify-center text-dark-neutral-900"
                >
                  <button
                    v-tooltip="
                      !canDeleteMember()
                        ? 'Cannot remove member. Assigned in some reviews.'
                        : ''
                    "
                    :class="{
                      'opacity-50 cursor-not-allowed': !canDeleteMember(),
                    }"
                    class="hover:bg-transparent"
                    @click.stop="removeProjectMember(project.id!, user?.id)"
                  >
                    <XCircleIcon class="w-5 h-5" />
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div> </template
  ></Modal>
</template>
<script setup lang="ts">
import { Project } from '@core/domain/models/project.model'
import { User } from '@core/domain/models/user.model'
import { computed } from 'vue'
import ProjectMembers from './ProjectMembers/ProjectMembers.vue'
import useProjects from '@app/views/Projects/use-projects'
import { injectStrict } from '@app/utils/injectStrict'
import { generateColorFromString } from '@app/utils/generateColorFromString'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { UserPlusIcon } from '@heroicons/vue/24/outline'

import XIcon from '@app/components/Icons/XIcon.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'

const { user: authenticatedUser } = injectStrict(AuthKey)
const isAdmin = computed(() => authenticatedUser.value?.role === Role.ADMIN)

const props = defineProps<{
  project: Project
  isShown?: boolean
}>()

const { removeProjectMember } = useProjects()

const usersColors = computed<{ [userId: string]: string }>(
  () =>
    props.project.users?.reduce(
      (a, u) => ({
        ...a,
        [u.id]: generateColorFromString(`${u.firstName} ${u.lastName}`),
      }),
      {},
    ) ?? {},
)

const canDeleteMember = () => {
  return true
}

function buildUserFullname(user: User) {
  if (!user) return ''
  let fullName = ''
  if (user.firstName) {
    fullName += user.firstName
  }
  if (user.lastName) {
    fullName += ' ' + user.lastName
  }
  if (!fullName) {
    fullName = user.email
  }
  return fullName
}
</script>
