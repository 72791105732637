<template>
  <div class="w-full border-r border-mid-neutral-50">
    <div
      class="flex w-full justify-between items-center pr-[24px] border-b border-mid-neutral-50"
    >
      <Tabs
        v-model:tab="activeStepNumber"
        :tabs="steps"
        :style="5"
        class="h-full"
        @update:step="updateStep"
      />
      <template v-if="activeStepNumber === 1">
        <CreateUsersFloatingMenu />
      </template>
      <template v-if="activeStepNumber === 2">
        <CreateTeamsFloatingMenu />
      </template>
    </div>
  </div>
  <div class="flex-1">
    <template v-if="activeStepNumber === 1">
      <UsersList />
    </template>

    <template v-else-if="activeStepNumber === 2">
      <TeamsList />
    </template>
  </div>
</template>

<script lang="ts" setup>
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import UsersList from './Users/UsersList.vue'
import TeamsList from './Users/TeamsList.vue'
import CreateUsersFloatingMenu from './Users/CreateUsersFloatingMenu.vue'
import CreateTeamsFloatingMenu from './Users/CreateTeamsFloatingMenu.vue'

const { user } = injectStrict(AuthKey)

const steps = [
  ...(user.value?.role === Role.ADMIN
    ? [
        {
          number: 1,
          title: 'All users',
        },
      ]
    : []),

  ...(user.value?.role !== Role.READ_ONLY
    ? [
        {
          number: 2,
          title: 'Teams',
        },
      ]
    : []),
]

const route = useRoute()
const router = useRouter()
const activeStepNumber = ref(steps[0].number)
const activeSubStepNumber = ref(1)

if (route.query.tab) {
  activeStepNumber.value = Number(route.query.tab)
}
if (route.query.subtab) {
  activeSubStepNumber.value = Number(route.query.subtab)
}

function updateStep(step: number) {
  router.push({ query: { tab: step, subtab: 1 } })
}
</script>
