export async function* generateMessageWithDelay(
  message: string,
): AsyncGenerator<string> {
  const words = message.split(' ')
  for (const word of words) {
    yield word
    const delay = Math.floor(Math.random() * 100) + 100
    await new Promise<void>((resolve) => setTimeout(resolve, delay))
  }
}
