<template>
  <div class="px-6 my-6 space-y-6 w-[30rem]">
    <TextInput
      v-model="apiKey"
      label="OpenAI key"
      placeholder=""
      :disabled="!isAdmin"
      @keydown.enter="configureStudiesQA(apiKey)"
    >
      <template #label="{ id }">
        <div class="flex items-center gap-2">
          <label
            class="text-sm text-primary font-medium pointer-events-none"
            :for="id"
          >
            Enter you OpenAI key
          </label>
        </div>
      </template>
      <template #suffix>
        <button
          v-if="isAdmin"
          class="text-white px-2 py-1 bg-primary-500 rounded-md text-sm hover:brightness-110"
          @click="configureStudiesQA(apiKey)"
        >
          Save
        </button>
      </template>
    </TextInput>
    <GenericAutoComplete
      ref="assistantModelSelection"
      label="Model"
      :items="[
        {
          value: 'gpt-4o',
        },
        {
          value: 'gpt-4-turbo',
        },
        {
          value: 'gpt-3.5-turbo',
        },
      ]"
      :item-options="{
        displayProperty: 'value',
        filterProperties: ['value'],
        valueProperty: 'value',
      }"
      :model-value="assistant.model"
      :readonly="!isAdmin || isLoading"
      @update:model-value="setAssistantModel"
    />
  </div>
</template>

<script setup lang="ts">
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import useAiAssistant from '@app/composables/useAiAssistant'
import { AuthKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { Role } from '@core/domain/types/role.type'
import { computed, onBeforeMount, ref } from 'vue'

const { apiKey, configure, getAssistant, updateAssistantModel } =
  useAiAssistant()
const loading = useLoading()
const snackbar = useSnackbar()
const isValid = computed(() => apiKey.value && !apiKey.value.includes('...'))
const assistant = ref<{ model: string; id: '' }>({ model: '', id: '' })
const { user } = injectStrict(AuthKey)
const isAdmin = computed(() => {
  return user.value?.role === Role.ADMIN
})

async function configureStudiesQA(apiKey: string) {
  if (!isValid.value) {
    return
  }
  loading.start()
  try {
    await configure(apiKey)
    snackbar.success('StudiesQA key configured')
  } catch (_) {
    snackbar.error(
      'Error while configuring StudiesQA key. Please check your key and try again.',
    )
  } finally {
    loading.stop()
  }
}
const isLoading = ref(false)
async function startLoading() {
  loading.start()
  isLoading.value = true
}

async function stopLoading() {
  loading.stop()
  isLoading.value = false
}

async function setAssistantModel(model?: unknown) {
  if (!model || typeof model !== 'string') return
  startLoading()
  try {
    await updateAssistantModel(model)
    assistant.value.model = model
    snackbar.success('Model updated successfully')
  } catch (_) {
    snackbar.error('Error while updating model. Please try again.')
  } finally {
    stopLoading()
  }
}

onBeforeMount(async () => {
  assistant.value = await getAssistant().catch(() => assistant.value)
})
</script>
