<template>
  <div
    :id="'review-item-' + reviewItem.id"
    class="cursor-pointer sticky left-0 z-40 border-l-[24px] border-l-white bg-white transition-colors h-full"
    :class="{
      '!bg-purple-50': reviewItem.state === StudyState.DUPLICATE,
    }"
  >
    <div v-if="isCuttingEdge" class="absolute top-1 z-10 -right-1">
      <div
        class="w-2 h-2 rounded-full"
        :class="{
          'bg-yellow-500':
            dataExtractionStatus === ReviewItemDataExtractionStatus.STARTED,
          'bg-green-500':
            dataExtractionStatus === ReviewItemDataExtractionStatus.COMPLETED,
          'bg-red-500':
            dataExtractionStatus === ReviewItemDataExtractionStatus.FAILED,
        }"
      />
    </div>
    <ArticleDetails
      v-if="article"
      :article="article"
      :is-metadata-expanded="props.isMetadataExpanded"
      :expansion-panel="expansionPanel"
    />
    <IncidentDetails
      v-else-if="incident"
      :incident="incident"
      :expansion-panel="expansionPanel"
      :is-metadata-expanded="props.isMetadataExpanded"
    />
    <GuidanceDetails
      v-else-if="guidance"
      :guidance="guidance"
      :expansion-panel="expansionPanel"
      :is-metadata-expanded="props.isMetadataExpanded"
    />
    <RegistrationDetails
      v-else-if="registration"
      :registration="registration"
      :expansion-panel="expansionPanel"
      :is-metadata-expanded="props.isMetadataExpanded"
    />
    <FieldSafetyNoticeDetails
      v-else-if="fsn"
      :fsn="fsn"
      :expansion-panel="expansionPanel"
      :is-metadata-expanded="props.isMetadataExpanded"
    />
  </div>
</template>
<script setup lang="ts">
import ArticleDetails from './Article.vue'
import IncidentDetails from './Incident.vue'
import GuidanceDetails from './Guidance.vue'

import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import RegistrationDetails from './Registration.vue'
import FieldSafetyNoticeDetails from './FieldSafetyNotice.vue'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { computed } from 'vue'
import { Article } from '@core/domain/models/article.model'
import { Incident } from '@core/domain/models/incident.model'
import { Guidance } from '@core/domain/models/guidance.model'
import { Registration } from '@core/domain/models/registration.model'
import { FieldSafetyNotice } from '@core/domain/models/fieldSafetyNotice.model'
import { StudyState } from '@app/types'
import { injectStrict } from '@app/utils/injectStrict'
import {
  ReviewItemDataExtractionStatus,
  ReviewKey,
} from '@app/views/Review/use-review'
import { DevToolboxKey } from '@app/injectionKeys'

const { isCuttingEdge } = injectStrict(DevToolboxKey)

const props = defineProps<{
  reviewItem: ReviewItem
  expansionPanel: any
  isMetadataExpanded: boolean
}>()
const { reviewItemDataExtractionStatuses } = injectStrict(ReviewKey)
const dataExtractionStatus = computed(() => {
  return (
    reviewItemDataExtractionStatuses.value[props.reviewItem.id] ??
    ReviewItemDataExtractionStatus.UNKNOWN
  )
})
const article = computed(() => {
  if (props.reviewItem.type === ReviewItemType.Article) {
    return props.reviewItem as Article
  }
  return null
})

const incident = computed(() => {
  if (props.reviewItem.type === ReviewItemType.Incident) {
    return props.reviewItem as Incident
  }
  return null
})

const guidance = computed(() => {
  if (props.reviewItem.type === ReviewItemType.Guidance) {
    return props.reviewItem as Guidance
  }
  return null
})

const registration = computed(() => {
  if (props.reviewItem.type === ReviewItemType.Registration) {
    return props.reviewItem as Registration
  }
  return null
})

const fsn = computed(() => {
  if (props.reviewItem.type === ReviewItemType.FieldSafetyNotice) {
    return props.reviewItem as FieldSafetyNotice
  }
  return null
})
</script>
