import { StudyState } from '@app/types'
import { Review } from '@core/domain/models/review.model'
import { computed, ref } from 'vue'

export default () => {
  const filters = ref<{ [key in StudyState]: boolean }>({
    [StudyState.DUPLICATE]: true,
    [StudyState.EXCLUDED]: true,
    [StudyState.FAVOURITE]: true,
    [StudyState.INCLUDED]: true,
    [StudyState.UNSCREENED]: true,
    [StudyState.QUARANTINED]: true,
  })

  const query = ref<string>('')

  const highlightOnly = ref(false)

  const terms = computed<string[]>(() => {
    return query.value
      .split(';')
      .map((st) =>
        st
          .trim()
          .toLowerCase()
          .replaceAll(/[\\.+*?^$[\](){}/'#:=!|]/gi, '\\$&'),
      )
      .filter((st) => !!st)
  })

  const hasFiltersApplied = computed(() => {
    return Object.values(filters.value).some((isApplied) => !isApplied)
  })

  function toggleStateFilter(
    filterName: StudyState,
    value: boolean | null = null,
  ) {
    filters.value[filterName] = value ?? !filters.value[filterName]
  }

  function toggleAllStateFilters() {
    const isEveryFilterActive = Object.keys(filters.value).every(
      (k) => filters.value[k as StudyState],
    )
    Object.keys(filters.value).forEach((filter) =>
      toggleStateFilter(filter as StudyState, !isEveryFilterActive),
    )
  }

  function filterStudiesByState(studySate: StudyState) {
    filters.value[studySate] = true
    Object.keys(filters.value).forEach((filter) => {
      if (filter !== studySate) filters.value[filter as StudyState] = false
    })
  }

  function resetFilters() {
    Object.keys(filters.value).forEach((filter) => {
      filters.value[filter as StudyState] = true
    })
  }

  function findSearchSource(review: Review, searchName: string): string {
    const searchIndex = parseInt(searchName.replace('search ', '')) - 1
    return review.searches?.[searchIndex]?.source.name ?? ''
  }

  function findSearchQuery(review: Review, searchName: string): string {
    const searchIndex = parseInt(searchName.replace('search ', '')) - 1
    const search = review.searches?.[searchIndex]
    return search?.searchQuery ?? ''
  }

  return {
    toggleAllStateFilters,
    toggleStateFilter,
    filterStudiesByState,
    resetFilters,
    filters,
    terms,
    query,
    highlightOnly,
    findSearchSource,
    findSearchQuery,
    hasFiltersApplied,
  }
}
