import { StudyState } from '@app/types'
import { ReviewsService } from '@core/application/reviews.service'
import { Search } from '@core/domain/models/search.model'
import { Review } from '@core/domain/models/review.model'
import { Id } from '@core/domain/types/id.type'
import {
  computed,
  Ref,
  ref,
  InjectionKey,
  onMounted,
  watch,
  onUnmounted,
} from 'vue'
import useStudiesFiltering from './Execute/SearchAndFilters/use-studies-filtering'
import useStudiesSorting from './Execute/Sort/use-studies-sorting'
import useDisplayOptions from './Execute/DisplayOptions/display-options'
import { OxfordLevelOfEvidenceType } from '@core/domain/types/oxford-level-of-evidence-type'
import { MetaData } from '@core/domain/types/meta-data.type'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { Attribute } from '@core/domain/models/attributes.model'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { injectStrict } from '@app/utils/injectStrict'
import { ProjectsServiceKey, ReviewsServiceKey } from '@app/injectionKeys'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'

import { ImportStudyPdfJob } from '@core/domain/models/importStudyPdfJob.model'
import { InclusionCriterion } from '@core/domain/models/InclusionCriterion.model'
import { PeerReviewStatus } from '@core/domain/types/peerReview.type'
import { StudyDesignStatus } from '@core/domain/types/studyDesignStatus.type'
import { StudyDesign } from '@core/domain/models/studyDesign.model'
import { EventDispatcherKey } from '@infrastructure/eventDispatcher/eventDispatcher'
import { ReviewSearchCreatedEvent } from '@core/domain/events/reviewSearchCreated.event'
import { HocuspocusProvider } from '@hocuspocus/provider'
import { TiptapTransformer } from '@hocuspocus/transformer'
import { DataExtractionCompletedEvent } from '@core/domain/events/dataExtractionCompleted.event'
import { DataExtractionStartedEvent } from '@core/domain/events/dataExtractionStarted.event'
import { DataExtractionFailedEvent } from '@core/domain/events/dataExtractionFailed.event'
import { createDebounce } from '@app/utils/debounce'
import { ReviewItemFileUploaded } from '@core/domain/events/reviewItemFileUploaded'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { BackgroundTaskRunner } from '@infrastructure/taskRunner'
import { createReviewItem } from '@core/domain/factories/reviewItem.factory'
import useLoading from '@app/composables/use-loading'

export const ReviewKey: InjectionKey<Awaited<ReturnType<typeof useReview>>> =
  Symbol('Review')

export enum ReviewItemDataExtractionStatus {
  UNKNOWN = 'UNKNOWN',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
export default function useReview(reviewId: Id) {
  const review = ref() as Ref<Review>
  const reviewsService: ReviewsService = injectStrict(ReviewsServiceKey)
  const projectsService = injectStrict(ProjectsServiceKey)
  const eventDispatcher = injectStrict(EventDispatcherKey)

  const dataExtractionProvider = new HocuspocusProvider({
    url: '/attributes-draft',
    name: 'review-' + reviewId + '-data-extraction',
  })

  onUnmounted(() => dataExtractionProvider.destroy())

  function getDataExtraction() {
    const doc = TiptapTransformer.fromYdoc(dataExtractionProvider.document)
    return doc
  }

  const reviewItemDataExtractionStatuses = ref<{
    [id: number]: ReviewItemDataExtractionStatus
  }>({})

  const debounce = createDebounce(1000)
  onMounted(() => {
    eventDispatcher.registerHandler(
      ReviewSearchCreatedEvent,
      (event: ReviewSearchCreatedEvent) => {
        if (event.reviewId === reviewId) debounce(refresh)
      },
    )

    eventDispatcher.registerHandler(
      DataExtractionStartedEvent,
      (event: DataExtractionStartedEvent) => {
        if (event.reviewId === reviewId) {
          reviewItemDataExtractionStatuses.value[event.reviewItemId] =
            ReviewItemDataExtractionStatus.STARTED
        }
      },
    )
    eventDispatcher.registerHandler(
      DataExtractionCompletedEvent,
      (event: DataExtractionCompletedEvent) => {
        if (event.reviewId === reviewId) {
          reviewItemDataExtractionStatuses.value[event.reviewItemId] =
            ReviewItemDataExtractionStatus.COMPLETED
          setTimeout(() => {
            dataExtractionProvider.disconnect()
            dataExtractionProvider.connect()
          }, 1000)
          setTimeout(() => {
            reviewItemDataExtractionStatuses.value[event.reviewItemId] =
              ReviewItemDataExtractionStatus.UNKNOWN
          }, 10000)
        }
      },
    )
    eventDispatcher.registerHandler(
      DataExtractionFailedEvent,
      (event: DataExtractionFailedEvent) => {
        if (event.reviewId === reviewId) {
          reviewItemDataExtractionStatuses.value[event.reviewItemId] =
            ReviewItemDataExtractionStatus.FAILED
        }
      },
    )

    let lastRefreshTime: number | null = null
    let isRefreshing = false

    eventDispatcher.registerHandler(
      ReviewItemFileUploaded,
      async (event: ReviewItemFileUploaded) => {
        if (event.reviewId === reviewId && !isRefreshing) {
          const currentTime = Date.now()

          if (
            lastRefreshTime === null ||
            currentTime - lastRefreshTime >= 10000
          ) {
            isRefreshing = true
            await refresh()
            lastRefreshTime = Date.now()
            isRefreshing = false
          }
        }
      },
    )
  })

  async function refresh() {
    const foundReview = await reviewsService.findById(reviewId)
    if (!foundReview) throw new Error('review not found')

    review.value = new Review(foundReview)
    const project = await reviewsService.findProject(reviewId)
    review.value.project = project
  }

  const filtering = useStudiesFiltering()
  const sorting = useStudiesSorting()

  const inclusionCriteria = computed({
    get: () => review.value.plan?.inclusionCriteria,
    set: (v) => {
      if (v !== inclusionCriteria.value) inclusionCriteria.value = v
    },
  })

  const searchesBySource = computed<{ [key: string]: Search[] }>(() => {
    const builtInSources = Object.values(BuiltInImportSourceId)

    const plannedSources =
      review.value.plan?.importPlan.importSources
        ?.map((source) => source.id)
        .filter(
          (id) => !builtInSources.includes(id as BuiltInImportSourceId),
        ) ?? []

    const allSources = [...builtInSources, ...plannedSources]

    return allSources.reduce(
      (acc, sourceId) => {
        acc[sourceId] =
          review.value?.searches?.filter((s) => s.source.id === sourceId) ?? []
        return acc
      },
      {} as { [key: string]: Search[] },
    )
  })

  const areInvalidStudiesDisplayed = ref(false)

  const runningStudiesPdfImports = ref<ImportStudyPdfJob[]>([])

  const runningStudiesPdfImportsMap = computed<Map<number, ImportStudyPdfJob>>(
    () => {
      const result: Map<number, ImportStudyPdfJob> = new Map()
      runningStudiesPdfImports.value.forEach((job) => {
        result.set(job.studyId, job)
      })
      return result
    },
  )

  const isPlanReadonly = computed(
    () =>
      (review.value.plan?.lockState === ReviewLockState.LOCKED &&
        review.value.project?.useReviewsPlanLocking) ||
      review.value.isLocked ||
      review.value.isArchived,
  )

  const isLocked = computed(() => review.value.isLocked)

  const isArchived = computed(() => review.value.isArchived)

  const isReviewReadonly = computed(
    () =>
      review.value.isLocked ||
      review.value.isArchived ||
      review.value.plan?.lockState === ReviewLockState.UNLOCKED,
  )

  const displayOptions = useDisplayOptions()

  const worker = new Worker(
    new URL('./workers/processReviewItem.worker.ts', import.meta.url),
    {
      type: 'module',
    },
  )

  onUnmounted(() => worker.terminate())
  const taskRunner = new BackgroundTaskRunner(worker)
  const loading = useLoading()

  const parentStudyIds = computed(() => {
    return new Set(
      review.value?.studies
        .map((study) => study.parentStudyId)
        .filter((id): id is number => !!id),
    )
  })

  const maybeParentStudyIds = computed(() => {
    return new Set(
      review.value?.studies
        .map((study) => study.maybeParentStudyId)
        .filter((id): id is number => !!id),
    )
  })

  const filteredReviewItems = ref<ReviewItem[]>([])

  const filteredIncludedReviewItems = ref<ReviewItem[]>([])
  async function processReviewItems() {
    if (!review.value?.studies?.length) return

    const jsonItems: ReviewItem[] = await taskRunner.runTask(
      'processReviewItems',
      JSON.parse(
        JSON.stringify({
          reviewItems: review.value.studies,
          terms: filtering.highlightOnly.value ? [] : filtering.terms.value,
          states: Object.keys(filtering.filters.value).filter(
            (f) => !!filtering.filters.value[f as StudyState],
          ) as StudyState[],
          sortBy: sorting.sort.value.by,
          sortOrder: sorting.sort.value.order,
          parentStudyIds: parentStudyIds.value,
          maybeParentStudyIds: maybeParentStudyIds.value,
          filters: filtering.filters.value,
        }),
      ),
    )
    filteredReviewItems.value = jsonItems.map((i: any) => createReviewItem(i))
  }
  const includedReviewItems = ref<ReviewItem[]>([])

  async function processIncludedReviewItems() {
    if (!review.value?.studies?.length) return
    await Promise.allSettled([
      taskRunner
        .runTask(
          'processIncludedReviewItems',
          JSON.parse(
            JSON.stringify({
              reviewItems: review.value.studies,
              sortBy: sorting.sort.value.by,
              sortOrder: sorting.sort.value.order,
            }),
          ),
        )
        .then((items) => {
          includedReviewItems.value = (items as ReviewItem[]).map((i: any) =>
            createReviewItem(i),
          )
        }),
      taskRunner
        .runTask(
          'processFilteredIncludedReviewItems',
          JSON.parse(
            JSON.stringify({
              reviewItems: review.value.studies,
              terms: filtering.highlightOnly.value ? [] : filtering.terms.value,
              sortBy: sorting.sort.value.by,
              sortOrder: sorting.sort.value.order,
            }),
          ),
        )
        .then((items) => {
          filteredIncludedReviewItems.value = (items as ReviewItem[]).map(
            (i: any) => createReviewItem(i),
          )
        }),
    ])
  }
  watch(
    [
      () => filtering.highlightOnly.value,
      () => filtering.terms.value,
      () => sorting.sort.value,
      () => filtering.filters.value,
    ],
    async () => {
      loading.start()
      try {
        await processReviewItems()
        await processIncludedReviewItems()
      } finally {
        loading.stop()
      }
    },
    { immediate: true, deep: true },
  )

  watch(
    [() => review.value?.studies],
    async () => {
      await processReviewItems()
      await processIncludedReviewItems()
    },
    { immediate: true, deep: true },
  )

  const markStudyAsDuplicate = async (
    studyId: Id,
    parentStudyId: Id,
  ): Promise<void> => {
    if (!review.value) throw Error('search not found')
    await reviewsService.markStudyAsDuplicate(
      review.value.id,
      studyId,
      parentStudyId,
    )
    const study = review.value.studies.find((s) => s.id === studyId)!
    study.markAsDuplicate(parentStudyId)
  }

  const markStudyAsNotDuplicate = async (studyId: Id): Promise<void> => {
    if (!review.value) throw Error('search not found')
    await reviewsService.markStudyAsNotDuplicate(review.value?.id, studyId)
    const study = review.value.studies.find((s) => s.id === studyId)!
    study.markAsNotDuplicate()
  }

  const setStudyTitleAndAbstractScreening = async (
    studyId: Id,
    key: string,
  ) => {
    if (!review.value) throw Error('review not found')
    await reviewsService.setStudyTitleAndAbstractScreening(
      review.value.id,
      studyId,
      key,
    )

    const study = review.value.studies.find((s) => s.id === studyId)!
    study.titleAndAbstractScreening = key
    review.value.updateStudy(studyId, { titleAndAbstractScreening: key })
  }

  const clearStudyTitleAndAbstractScreening = async (studyId: Id) => {
    if (!review.value) throw Error('search not found')
    await reviewsService.clearStudyTitleAndAbstractScreening(
      review.value.id,
      studyId,
    )
    const study = review.value.studies.find((s) => s.id === studyId)!
    study.titleAndAbstractScreening = ''
    review.value.updateStudy(studyId, { titleAndAbstractScreening: '' })
  }

  const setStudyFullTextScreening = async (
    studyId: Id,
    fullTextScreening: string,
  ) => {
    if (!review.value) throw Error('search not found')
    await reviewsService.setStudyFullTextScreening(
      review.value.id,
      studyId,
      fullTextScreening,
    )
    const study = review.value.studies.find((s) => s.id === studyId)!
    study.fullTextScreening = fullTextScreening
    review.value.updateStudy(studyId, { fullTextScreening })
  }

  const clearStudyFullTextScreening = async (studyId: Id) => {
    if (!review.value) throw Error('search not found')
    await reviewsService.clearStudyFullTextScreening(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      fullTextScreening: '',
    })
  }

  const favoriteStudy = async (studyId: Id) => {
    if (!review.value) throw new Error('no search set')
    await reviewsService.favoriteStudy(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      isFavorite: true,
    })
  }

  const unfavoriteStudy = async (studyId: Id) => {
    if (!review.value) throw new Error('no search set')
    await reviewsService.unfavoriteStudy(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      isFavorite: false,
    })
  }

  const removeSearch = async (searchId: number) => {
    if (!review.value) throw new Error('no review set')
    await reviewsService.removeSearch(review.value.id, searchId)
    const foundReview = await reviewsService.findById(review.value.id)
    if (!foundReview) throw new Error('review not found')
  }

  async function uploadStudyPdfFile(studyId: Id, file: File) {
    if (!review.value) throw new Error('no review set')
    const pdfFile = await reviewsService.uploadStudyPdfFile(
      review.value.id,
      studyId,
      file,
    )
    const study = review.value.studies.find((s) => s.id === studyId)!
    review.value.updateStudy(studyId, {
      ...study,
      pdfFile,
    })
  }

  async function getStudyPdfFile(studyId: Id) {
    if (!review.value) throw new Error('no review set')
    return reviewsService.getStudyPdfFile(review.value.id, studyId)
  }

  async function downloadSearchCitationFile(searchId: Id) {
    if (!review.value) throw new Error('no review set')
    return reviewsService.downloadSearchCitationFile(review.value.id, searchId)
  }

  async function deleteStudyPdfFile(studyId: Id) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteStudyPdfFile(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      pdfFile: undefined,
    })
  }

  async function downloadPdfZip() {
    if (!review.value) throw new Error('no review set')
    return reviewsService.downloadPdfZip(review.value.id)
  }

  async function editStudyAbstract(studyId: Id, abstract: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.editStudyAbstract(review.value.id, studyId, abstract)
    const study = review.value.studies.find((a) => a.id === studyId)
    review.value.updateStudy(studyId, {
      metadata: { ...study?.metadata, abstract },
    })
  }

  async function submitComplaint(
    studyId: Id,
    data: {
      body: string
      fromAddress: string
      fromName: string
      subject: string
      to: string
    },
  ) {
    if (!review.value) throw new Error('no review set')
    const complaintDate = await reviewsService.submitComplaint(
      review.value.id,
      studyId,
      data,
    )

    review.value.updateStudy(studyId, {
      complaintDate,
    })
  }

  async function addFullTextCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addFullTextCriterion(review.value.id, criterion)
    review.value.plan?.screeningPlan.fullTextCriteria.push(criterion)
  }

  async function enableTitleAndAbstractScreening() {
    if (!review.value) throw new Error('no review set')
    await reviewsService.enableTitleAndAbstractScreening(review.value.id)
  }

  async function disableTitleAndAbstractScreening() {
    if (!review.value) throw new Error('no review set')
    await reviewsService.disableTitleAndAbstractScreening(review.value.id)
    if (review.value.plan)
      review.value.plan.screeningPlan.titleAndAbstractCriteria = []
  }

  async function deletFullTextCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteFullTextCriterion(review.value.id, criterion)
    if (review.value.plan)
      review.value.plan.screeningPlan.fullTextCriteria =
        review.value.plan?.screeningPlan.fullTextCriteria.filter(
          (c) => c !== criterion,
        )
  }

  async function addTitleAndAbstractCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addTitleAndAbstractCriterion(
      review.value.id,
      criterion,
    )
    review.value.plan?.screeningPlan.titleAndAbstractCriteria.push(criterion)
  }

  async function deleteTitleAndAbstractCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteTitleAndAbstractCriterion(
      review.value.id,
      criterion,
    )
    if (review.value.plan)
      review.value.plan.screeningPlan.titleAndAbstractCriteria =
        review.value.plan?.screeningPlan.titleAndAbstractCriteria.filter(
          (c) => c !== criterion,
        )
  }

  const planScreening = async (data: {
    titleAndAbstractCriteria: string[]
    fullTextCriteria: string[]
  }) => {
    if (!review.value) throw new Error('no review set')
    await reviewsService.planScreening(review.value.id, data)
  }

  async function removeImportSourceFromPlan(importSourceId: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.removeImportSourceFromPlan(
      review.value.id,
      importSourceId,
    )
    review.value.removeImportSource(importSourceId)
  }

  async function enableStudiesAppraisalImdrfMdce2019() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.enableStudiesAppraisalImdrfMdce2019(review.value.id)
    review.value.plan.appraisalPlan.isImdrfMdce2019Applicable = true
  }

  async function enableStudiesAppraisalOxfordLevelOfEvidence() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.enableStudiesAppraisalOxfordLevelOfEvidence(
      review.value.id,
    )
    review.value.plan.appraisalPlan.isOxfordLevelOfEvidenceApplicable = true
  }
  async function enablePicoInclusionCriteria() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.enablePicoInclusionCriteria(review.value.id)
    review.value.plan.inclusionCriteria.isPicoInclusionCriteriaApplicable = true
  }

  async function disablePicoInclusionCriteria() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.disablePicoInclusionCriteria(review.value.id)
    review.value.plan.inclusionCriteria.isPicoInclusionCriteriaApplicable =
      false
  }

  async function enableStudiesAppraisalPeerReviewStatus() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.enableStudiesAppraisalPeerReviewStatus(review.value.id)
    review.value.plan.appraisalPlan.isPeerReviewStatusApplicable = true
  }

  async function disableStudiesAppraisal() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.disableStudiesAppraisal(review.value.id)
    review.value.plan.appraisalPlan.isImdrfMdce2019Applicable = false
  }

  async function disableStudiesAppraisalOxfordLevelOfEvidence() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.disableStudiesAppraisalOxfordLevelOfEvidence(
      review.value.id,
    )
    review.value.plan.appraisalPlan.isOxfordLevelOfEvidenceApplicable = false
  }

  async function disableStudiesAppraisalPeerReviewStatus() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.disableStudiesAppraisalPeerReviewStatus(
      review.value.id,
    )
    review.value.plan.appraisalPlan.isPeerReviewStatusApplicable = false
  }

  async function planSearch(plan: {
    screening: {
      titleAndAbstractCriteria: string[]
      fullTextCriteria: string[]
    }
  }) {
    await planScreening({
      titleAndAbstractCriteria: plan.screening.titleAndAbstractCriteria?.filter(
        (c) => c !== '',
      ),
      fullTextCriteria: plan.screening?.fullTextCriteria.filter(
        (c) => c !== '',
      ),
    })
  }

  async function appraiseStudy(
    studyId: Id,
    appraisal: { criterionId: string; gradeId: string },
  ) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.appraiseStudy(review.value?.id, studyId, appraisal)
    const study = review.value.studies.find((a) => a.id === studyId)
    review.value.updateStudy(studyId, {
      appraisal: {
        ...study?.appraisal,
        [appraisal.criterionId]: appraisal.gradeId,
      },
    })
  }
  async function setOxfordLevelOfEvidenceStudy(
    studyId: Id,
    oxfordLevelOfEvidence: OxfordLevelOfEvidenceType,
  ) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.setOxfordLevelOfEvidenceStudy(
      review.value?.id,
      studyId,
      oxfordLevelOfEvidence,
    )
    review.value.updateStudy(studyId, {
      oxfordLevelOfEvidence: oxfordLevelOfEvidence,
    })
  }

  async function setStudyPeerReviewStatus(
    studyId: Id,
    peerReviewStatus: PeerReviewStatus,
  ) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.setStudyPeerReviewStatus(
      review.value?.id,
      studyId,
      peerReviewStatus,
    )
    review.value.updateStudy(studyId, {
      peerReviewStatus: peerReviewStatus,
    })
  }

  async function setStudyDesignStatus(
    studyId: Id,
    studyDesignStatus: StudyDesignStatus,
  ) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.setStudyDesignStatus(
      review.value?.id,
      studyId,
      studyDesignStatus,
    )
    review.value.updateStudy(studyId, {
      studyDesignStatus: studyDesignStatus,
    })
  }

  async function clearStudyAppraisal(studyId: Id, criterionId: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.clearStudiesAppraisal(
      review.value.id,
      studyId,
      criterionId,
    )
    const study = review.value.studies.find((a) => a.id === studyId)
    review.value.updateStudy(studyId, {
      appraisal: {
        ...study?.appraisal,
        [criterionId]: '',
      },
    })
  }

  async function clearStudyOxfordLevelOfEvidence(studyId: Id) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.cleaStudyOxfordLevelOfEvidence(
      review.value.id,
      studyId,
    )
    review.value.updateStudy(studyId, {
      oxfordLevelOfEvidence: '',
    })
  }

  async function clearStudyPeerReviewStatus(studyId: Id) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.clearStudyPeerReviewStatus(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      peerReviewStatus: PeerReviewStatus.default,
    })
  }

  async function updateStudy(studyId: Id, metadata: MetaData) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.updateStudy(review.value.id, studyId, metadata)
    review.value.updateStudy(studyId, { metadata })
  }

  async function addAttributeStructure(
    reviewId: Id,
    attribute: Partial<AttributeStructure>,
  ) {
    const attr = await reviewsService.addAttributeStructure(reviewId, {
      label: attribute.label ?? '',
      question: attribute.question ?? '',
      order: attribute.order ?? '',
    })
    if (review.value.plan) {
      review.value.plan.synthesisPlan.attributesStructure.push(attr)
    }
  }

  async function deleteAttributeStructure(
    reviewId: Id,
    attributeStructureId: string,
  ) {
    await reviewsService.deleteAttributeStructure(
      reviewId,
      attributeStructureId,
    )
  }

  async function editAttributeStructure(
    reviewId: Id,
    updatedAttribute: AttributeStructure,
  ) {
    await reviewsService.editAttributeStructure(reviewId, updatedAttribute)
    const foundAttributeIndex =
      review.value.plan?.synthesisPlan.attributesStructure?.findIndex(
        (a) => a.id === updatedAttribute.id,
      ) ?? -1
    if (foundAttributeIndex !== -1) {
      review.value.plan!.synthesisPlan.attributesStructure[
        foundAttributeIndex
      ] = updatedAttribute
    }
  }

  async function updateStudySynthesisAttribute(
    reviewId: Id,
    studyId: Id,
    attribute: Attribute,
  ) {
    await reviewsService.updateStudySynthesisAttribute(reviewId, studyId, {
      id: attribute.attributeStructureId,
      value: attribute.value,
    })
    const study = review.value.studies.find((s) => s.id === studyId)!
    const foundAttributeIndex =
      study.synthesis?.attributes?.findIndex(
        (a) => a.attributeStructureId === attribute.attributeStructureId,
      ) ?? -1
    if (foundAttributeIndex !== -1) {
      study.synthesis.attributes[foundAttributeIndex] = { ...attribute }
    } else {
      study.synthesis.attributes.push(attribute)
    }
    review.value.updateStudy(studyId, study)
  }

  async function lockPlan(reviewId: Id) {
    await reviewsService.lockPlan(reviewId)
    if (review.value.plan) review.value.plan.lockState = ReviewLockState.LOCKED
  }

  async function unlockPlan(reviewId: Id) {
    await reviewsService.unlockPlan(reviewId)
    if (review.value.plan)
      review.value.plan.lockState = ReviewLockState.UNLOCKED
  }

  async function addCustomImportSourceToPlan(data: {
    id?: string
    name: string
    url: string
    type: ImportSourceType
    fullName?: string
    description?: string
  }): Promise<string> {
    const sourceId = await reviewsService.addCustomImportSourceToPlan(
      reviewId,
      data,
    )
    return sourceId
  }

  async function importRisSearch({
    citationFiles,
    date,
    query,
    filters,
    importSourceId,
  }: {
    query?: string
    filters?: string
    citationFiles: File[]
    date: string
    importSourceId: string
  }) {
    await reviewsService.importRisSearch({
      reviewId,
      citationFiles,
      date,
      query,
      filters,
      importSourceId,
    })
  }

  async function importSearch({
    query,
    filters,
    date,
    importSourceId,
    items,
  }: {
    query?: string
    filters?: string
    date: string
    importSourceId: string
    items: any[]
  }) {
    await reviewsService.importSearch({
      reviewId,
      query,
      filters,
      date,
      importSourceId,
      items,
    })
  }

  async function importCitationSearch({
    citationFiles,
    parentStudyId,
  }: {
    citationFiles: File[]
    parentStudyId: number
  }) {
    await reviewsService.importCitationSearch({
      reviewId,
      citationFiles,
      parentStudyId,
    })
  }

  const sortedAttributes = computed(
    () =>
      review.value.plan!.synthesisPlan?.attributesStructure.toSorted((a, b) => {
        if (a.order === null && b.order === null) return 0
        if (a.order === null) return -1
        if (b.order === null) return 1
        return a.order > b.order ? 1 : -1
      }) ?? [],
  )

  async function answerStudyQuestion(
    studyId: number,
    question: string,
  ): Promise<string> {
    if (!review.value) throw new Error('no review set')
    return reviewsService.answerStudyQuestion(studyId, question)
  }

  async function addPicoInclusionCriterion(newCriterion: {
    criterionType: InclusionCriterion
    criterion: string
  }) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addPicoInclusionCriterion(
      review.value.id,
      newCriterion,
    )
    review.value.plan?.inclusionCriteria[newCriterion.criterionType].push(
      newCriterion.criterion,
    )
  }

  async function deletePicoInclusionCriterion(criterionDetails: {
    criterionType: InclusionCriterion
    criterion: string
  }) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deletePicoInclusionCriterion(
      review.value.id,
      criterionDetails,
    )
    if (review.value.plan?.inclusionCriteria[criterionDetails.criterionType])
      review.value.plan.inclusionCriteria[criterionDetails.criterionType] =
        review.value.plan?.inclusionCriteria[
          criterionDetails.criterionType
        ].filter((c) => c !== criterionDetails.criterion)
  }

  async function addInclusionCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addInclusionCriterion(review.value.id, criterion)
    review.value.plan?.inclusionCriteria.criteria.push(criterion)
  }

  async function deleteInclusionCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteInclusionCriterion(review.value.id, criterion)
    if (review.value.plan?.inclusionCriteria.criteria)
      review.value.plan.inclusionCriteria.criteria =
        review.value.plan?.inclusionCriteria.criteria.filter(
          (c) => c !== criterion,
        )
  }

  async function addAppraisalCriterionAnswer(data: {
    appraisalCriterionId: string
    answer: string
  }) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addAppraisalCriteria(review.value.id, data)
  }
  async function deleteAppraisalCriterionAnswer(data: {
    answerId: string
    appraisalCriterionId: string
  }) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteAppraisalCriterionAnswer(review.value.id, data)
  }
  async function lockReview(reviewId: Id) {
    await reviewsService.lock(reviewId)
    review.value.isLocked = true
  }

  async function unlockReview(reviewId: Id) {
    await reviewsService.unlock(reviewId)
    review.value.isLocked = false
  }

  async function updateCslStyle(cslStyle: string) {
    if (!review.value.project?.id)
      throw new Error('something went wrong, cannot find project id')
    await projectsService.updateCslStyle(review.value.project?.id, cslStyle)
  }

  async function updateStudyDesign(studyId: Id, studyDesign: StudyDesign) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.updateStudyDesign(
      review.value?.id,
      studyId,
      studyDesign,
    )
    review.value.updateStudy(studyId, { studyDesign: studyDesign })
  }

  async function updateSynthesisPlanSummary(summary: string) {
    await reviewsService.updateSynthesisPlanSummary(review.value.id, summary)
  }

  async function updateSearchStrategySummary(strategy: string) {
    await reviewsService.updateSearchStrategySummary(review.value.id, strategy)
  }

  async function createEvaluator({
    name,
    role,
  }: {
    name: string
    role: string
  }) {
    const evaluatorId = await reviewsService.createEvaluator({
      name,
      role,
      reviewId: review.value.id,
    })
    review.value.evaluators.push({
      id: evaluatorId,
      name,
      role,
    })
  }

  async function updateEvaluator({
    id,
    name,
    role,
  }: {
    id: number
    role: string
    name: string
  }) {
    await reviewsService.updateEvaluator({
      reviewId: review.value.id,
      evaluatorId: id,
      name,
      role,
    })
    review.value.evaluators = review.value.evaluators.map((evaluator) => {
      if (evaluator.id === id) {
        return {
          id,
          name,
          role,
        }
      }
      return evaluator
    })
  }

  async function deleteEvaluator(id: number) {
    await reviewsService.deleteEvaluator({
      evaluatorId: id,
      reviewId: review.value.id,
    })
    review.value.evaluators = review.value.evaluators.filter(
      (evaluator) => evaluator.id !== id,
    )
  }

  return {
    refresh,
    markStudyAsDuplicate,
    markStudyAsNotDuplicate,
    setStudyTitleAndAbstractScreening,
    clearStudyTitleAndAbstractScreening,
    setStudyFullTextScreening,
    clearStudyFullTextScreening,
    removeSearch,
    planSearch,
    enableStudiesAppraisalOxfordLevelOfEvidence,
    removeImportSourceFromPlan,
    favoriteStudy,
    unfavoriteStudy,
    uploadStudyPdfFile,
    getStudyPdfFile,
    deleteStudyPdfFile,
    downloadPdfZip,
    downloadSearchCitationFile,
    editStudyAbstract,
    enableStudiesAppraisalImdrfMdce2019,
    disableStudiesAppraisalOxfordLevelOfEvidence,
    enableStudiesAppraisalPeerReviewStatus,
    disableStudiesAppraisalPeerReviewStatus,
    disableStudiesAppraisal,
    submitComplaint,
    appraiseStudy,
    setOxfordLevelOfEvidenceStudy,
    clearStudyAppraisal,
    clearStudyOxfordLevelOfEvidence,
    updateStudy,
    addAttributeStructure,
    deleteAttributeStructure,
    editAttributeStructure,
    updateStudySynthesisAttribute,
    lockPlan,
    addCustomImportSourceToPlan,
    importRisSearch,
    addFullTextCriterion,
    addTitleAndAbstractCriterion,
    deletFullTextCriterion,
    deleteTitleAndAbstractCriterion,
    enableTitleAndAbstractScreening,
    disableTitleAndAbstractScreening,
    unlockPlan,
    importCitationSearch,
    answerStudyQuestion,
    addPicoInclusionCriterion,
    deletePicoInclusionCriterion,
    addAppraisalCriterionAnswer,
    deleteAppraisalCriterionAnswer,
    setStudyPeerReviewStatus,
    clearStudyPeerReviewStatus,
    updateCslStyle,
    setStudyDesignStatus,
    updateStudyDesign,
    enablePicoInclusionCriteria,
    disablePicoInclusionCriteria,
    addInclusionCriterion,
    deleteInclusionCriterion,
    importSearch,
    isLocked,
    isPlanReadonly,
    isArchived,
    isReviewReadonly,
    entity: review,
    filteredReviewItems,
    includedReviewItems,
    filteredIncludedReviewItems,
    filtering,
    sorting,
    displayOptions,
    areInvalidStudiesDisplayed,
    searchesBySource,
    inclusionCriteria,
    sortedAttributes,
    parentStudyIds,
    maybeParentStudyIds,
    runningStudiesPdfImportsMap,
    lockReview,
    unlockReview,
    updateSynthesisPlanSummary,
    updateSearchStrategySummary,
    dataExtractionProvider,
    getDataExtraction,
    createEvaluator,
    updateEvaluator,
    deleteEvaluator,
    reviewItemDataExtractionStatuses,
  }
}
