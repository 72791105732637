<template>
  <ExpansionPanel
    class="border-gray-500 border-6"
    v-model="isSynthesisPanelExpanded"
  >
    <template #activator="{ toggle }">
      <div class="flex items-center gap-2 bg-gray-500">
        <button
          @click="toggle"
          class="w-full text-white text-left px-2 py-2 flex items-center gap-2"
        >
          <ChevronDownIcon class="w-5" />
          Synthesis
        </button>
        <button
          class="text-white p-2 hover:text-slate-200"
          @click="toggleAllPanels"
        >
          <QueueListIcon class="w-5" />
        </button>
      </div>
    </template>
    <template #default>
      <ExpansionPanel
        v-if="review.id"
        class="border-b-1"
        v-model="isSynthesisSettingsPanelExpanded"
      >
        <template #activator="{ toggle }">
          <div
            class="bg-slate-600 text-white flex justify-between items-center"
          >
            <button
              class="px-4 py-2 truncate w-full text-left flex gap-2 items-center"
              @click="toggle"
            >
              <ChevronDownIcon class="w-5" />
              Settings
            </button>
          </div>
        </template>
        <template #default>
          <div class="p-2 space-y-2">
            <div>
              <label class="text-sm text-primary">Summary Prompt</label>
              <textarea
                v-model="summaryPrompt"
                class="w-full border border-gray-300 h-30 px-2 py-1"
              />
            </div>
            <div>
              <div class="flex items-center gap-2">
                <div
                  v-tooltip="
                    `What sampling temperature to use, between 0 and 2.
                     Higher values like 0.8 will make the output more random, while
                     lower values like 0.2 will make it more focused and deterministic.`
                  "
                  class="flex items-center gap-1 w-40 text-sm text-primary"
                >
                  temperature <InformationCircleIcon class="w-4" />
                </div>
                <div class="w-8">
                  {{ modelTemperature }}
                </div>
                <input
                  v-model.number="modelTemperature"
                  type="range"
                  min="0.2"
                  max="1.0"
                  step="0.1"
                />
              </div>
              <div class="flex items-center gap-2">
                <div
                  v-tooltip="
                    `Number between -2.0 and 2.0. Positive values penalize new
                    tokens based on whether they appear in the text so far,
                    increasing the model's likelihood to talk about new topics.`
                  "
                  class="flex items-center gap-1 w-40 text-sm text-primary"
                >
                  Presence penalty
                  <InformationCircleIcon class="w-4" />
                </div>
                <div class="w-8">
                  {{ modelPresencePenalty }}
                </div>
                <input
                  v-model.number="modelPresencePenalty"
                  type="range"
                  min="-2"
                  max="2"
                  step="0.1"
                />
              </div>
            </div>
          </div>
        </template>
      </ExpansionPanel>
      <div class="divide-y">
        <ExpansionPanel
          v-for="question in activeQuestions"
          v-model="panelsState[question.id]"
        >
          <template #activator="{ toggle }">
            <div class="bg-slate-600 flex justify-between items-center w-full">
              <button
                class="px-4 py-2 text-left text-white flex gap-2 items-center truncate w-full"
                @click="toggle"
              >
                <ChevronDownIcon class="w-5" />
                <p class="truncate">{{ question.label }}</p>
              </button>
              <div class="px-2 pt-2" v-if="loading[question.id]">
                <span
                  class="loader w-5 h-5 !border-white !border-b-transparent"
                />
              </div>

              <button
                class="p-2 text-white hover:text-slate-200"
                @click="generateSynthesis(question.id)"
                v-else
              >
                <SparklesIcon class="w-5" />
              </button>
            </div>
          </template>
          <template #default>
            <div
              class="px-4 py-2 w-full text-left text-wrap"
              v-if="!synthesisAnswers?.[question.id]?.length"
            >
              <div>No answers yet</div>
            </div>
            <div class="divide-y">
              <div v-for="answer in synthesisAnswers[question.id]">
                <div class="px-4 py-2 w-full text-left text-wrap">
                  <div>{{ answer }}</div>
                </div>
              </div>
            </div>
          </template>
        </ExpansionPanel>
      </div>
    </template>
  </ExpansionPanel>
</template>
<script setup lang="ts">
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import { EvidenceServiceApiKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { Review } from '@core/domain/models/review.model'
import {
  ChevronDownIcon,
  InformationCircleIcon,
  QueueListIcon,
  SparklesIcon,
} from '@heroicons/vue/24/outline'
import { useStorage } from '@vueuse/core'
import { computed, ref } from 'vue'

const evidenceServiceApi = injectStrict(EvidenceServiceApiKey)

const props = defineProps<{
  review: Review
  questions: { [questionId: string]: boolean }
}>()
const isSynthesisPanelExpanded = useStorage<boolean>(
  'prototype-synthesis-isSynthesisPanelExpanded',
  false,
)

const isSynthesisSettingsPanelExpanded = useStorage<boolean>(
  'prototype-synthesis-isSynthesisSettingsPanelExpanded',
  false,
)

const activeQuestions = computed(() => {
  if (Object.keys(props.questions)?.some((k) => props.questions[k])) {
    const questionIds = Object.keys(props.questions).filter(
      (k) => props.questions[k],
    )
    return questionIds.map((id) => ({
      id,
      label: props.review.plan?.synthesisPlan?.attributesStructure?.find(
        (a) => a.id === id,
      )?.label,
    }))
  }
  return (
    props.review.plan?.synthesisPlan?.attributesStructure.filter(
      (a) => a.shouldDoSynthesis,
    ) ?? []
  )
})

const modelPresencePenalty = useStorage<number>(
  'prototype-synthesis-model-presence-penalty',
  0.0,
)
const modelTemperature = useStorage<number>(
  'prototype-synthesis-model-presence-temp',
  0.8,
)

const summaryPrompt = useStorage<string>(
  'prototype-synthesis-summary-prompt',
  `You are tasked with summarizing the following answers extracted from a document.
Provide concise summaries based solely on the provided answers.
If no answers are available, respond with a dash "-". Ensure that key details such as percentages, numbers, or specific data points are preserved if mentioned in the answers.`,
)
const synthesisAnswers = useStorage<{
  [id: string]: string[]
}>('prototype-synthesis-synthesisAnswers', {})
const panelsState = useStorage<any>('prototype-synthesis-panelsState', {})

enum PanelToggleAction {
  Expand,
  Collapse,
}

function toggleAllPanels() {
  const action = Object.values(panelsState.value).some((v) => v)
    ? PanelToggleAction.Collapse
    : PanelToggleAction.Expand
  activeQuestions.value.forEach((q) => {
    panelsState.value[q.id] = action === PanelToggleAction.Expand
  })
}

const reviewItemsAnswers = useStorage<{
  [id: number]: { question: string; answer: string; id: string }[][]
}>('prototype-reviewItemsAnswers', [])

const loading = ref<{ [reviewId: string]: boolean }>({})
async function generateSynthesis(attributeId: string) {
  loading.value[attributeId] = true
  try {
    const answers = Object.values(reviewItemsAnswers.value)
      .flat()
      .filter((item) => item?.some((qa) => qa.id === attributeId))
      .map((item) => item?.find((qa) => qa.id === attributeId)?.answer)
      .filter((answer) => answer) as string[]

    const { data: answer } =
      await evidenceServiceApi.api.prototypeControllerGenerateSynthesis({
        reviewId: props.review.id,
        modelTemperature: modelTemperature.value,
        modelPresencePenalty: modelPresencePenalty.value,
        summaryPrompt: summaryPrompt.value,
        attributeId,
        answers,
      })
    if (!synthesisAnswers.value[attributeId]) {
      synthesisAnswers.value[attributeId] = []
    }
    synthesisAnswers.value[attributeId].push(answer)
  } catch (e) {
    console.error(e)
  } finally {
    loading.value[attributeId] = false
  }
}
</script>
