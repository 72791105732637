<template>
  <div class="flex flex-col">
    <template v-for="(tab, index) in tabs" :key="'tab-' + tab.number">
      <button :disabled="tab.isDisabled" class="" @click="onClick(tab)">
        <div
          class="group/tab relative bg-white w-full border-b border-mid-neutral-50"
          :class="[tab.isDisabled ? 'cursor-not-allowed' : 'text-slate-600']"
        >
          <!-- Active Tab Indicator -->
          <div
            class="absolute right-0 top-0 h-full w-[3px]"
            :class="{ '!bg-secondary-500': tab.number === activeTabNumber }"
          />
          <slot :tab="tab">
            <div class="flex items-center gap-3 px-6 py-3 h-[4rem]">
              <component
                :is="tab.icon"
                v-if="tab.icon"
                class="w-5 h-5"
                :class="
                  tab.number === activeTabNumber
                    ? 'text-secondary-500'
                    : 'text-dark-neutral-500'
                "
              />
              <div class="flex flex-wrap items-start min-w-48">
                <p
                  class="text-base font-medium truncate"
                  :class="
                    tab.number === activeTabNumber
                      ? 'text-secondary-500'
                      : 'text-dark-neutral-500'
                  "
                >
                  {{ tab.title }}
                </p>
              </div>
            </div>
          </slot>

          <template v-if="index !== 0">
            <div
              class="absolute inset-0 top-0 left-0 hidden h-3 w-full lg:block"
              aria-hidden="true"
            ></div>
          </template>
        </div>
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
type Tab = {
  number: number
  title?: string
  subtitle?: string
  icon?: string
  isDisabled?: boolean
}
defineProps<{
  tabs: Tab[]
  activeTabNumber: number
}>()
function onClick(tab: Tab) {
  emit('tab:clicked', tab)
}
const emit = defineEmits<(e: 'tab:clicked', tab: Tab) => void>()
</script>
