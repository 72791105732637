import { ArticleMetadata } from '@core/domain/models/reviewItemMetadata/articleMetadata'
import { PubmedApiClient } from '@infrastructure/pubmedApi/pubmedApi.client'

export class CochraneApiClient {
  constructor(private readonly pubmedApiClient: PubmedApiClient) {}

  async searchArticles(query: string): Promise<ArticleMetadata[]> {
    const { articles } = await this.pubmedApiClient.searchArticles(query)
    const cochraneArticles = this.filterLatestArticlesByTitle(articles)
    return cochraneArticles
  }

  private filterLatestArticlesByTitle(
    articles: ArticleMetadata[],
  ): ArticleMetadata[] {
    const articleMap = new Map<string, ArticleMetadata>()

    articles.forEach((article) => {
      if (article.title && article.doi) {
        const cleanedTitle = article.title.replace(/^WITHDRAWN: /, '')
        const cleanedDoi = article.doi.replace(/\.pub\d+$/, '')
        const key = cleanedDoi
        const existingArticle = articleMap.get(key)
        if (
          !existingArticle ||
          (existingArticle?.publishYear !== undefined &&
            article.publishYear !== undefined &&
            existingArticle.publishYear < article.publishYear)
        ) {
          articleMap.set(key, { ...article, title: cleanedTitle })
        }
      }
    })

    return Array.from(articleMap.values())
  }
}
