import axios from 'axios'
import { FieldSafetyNoticeMetadata } from '@core/domain/models/reviewItemMetadata/fieldSafetyNoticeMetadata'
import { fsnProviders } from '@app/utils/fsnProviders'

type FieldSafetyNoticeResult = {
  id: string
  title: string[]
  date: string[]
  link: string[]
  summary: string[]
  source: string[]
  fulltext?: string[]
  type: string[]
  pdfLinks?: string | string[]
}

export class FsnApiClient {
  private readonly baseUrl =
    location.protocol + '//' + location.host + '/fsn-api'

  async searchArticles(
    query: string,
    countries: string[],
    pagination = { limit: 5000, offset: 0 },
  ): Promise<FieldSafetyNoticeMetadata[]> {
    if (!query) return []

    const filteredSources = this.filterSourcesByCountries(countries)
    const searchResults = await this.search(query, filteredSources, pagination)

    return this.mapResultsToFieldSafetyNotices(searchResults)
  }

  private filterSourcesByCountries(
    countries: string[],
  ): { id: string; country: string }[] {
    return fsnProviders
      .filter((provider) => countries.includes(provider.countryCode))
      .flatMap((provider) =>
        provider.sources.map((source) => ({
          id: source.id,
          country: provider.countryCode,
        })),
      )
  }

  private async search(
    query: string,
    sources: { id: string; country: string }[],
    pagination: { limit: number; offset: number },
  ): Promise<FieldSafetyNoticeResult[]> {
    try {
      const solrQuery = `title:${encodeURIComponent(query)} OR summary:${encodeURIComponent(query)} OR fulltext:${encodeURIComponent(query)}`
      const sourceFilter = sources.length
        ? ` AND source:(${sources.map((s) => s.id).join(' OR ')})`
        : ''

      const solrUrl = `${this.baseUrl}/?q=${solrQuery}${sourceFilter}&start=${pagination.offset}&rows=${pagination.limit}&wt=json`

      const response = await axios.get(solrUrl)
      return response.data.response.docs
    } catch (error) {
      console.error('Error searching documents:', error)
      return []
    }
  }

  private mapResultsToFieldSafetyNotices(
    results: FieldSafetyNoticeResult[],
  ): FieldSafetyNoticeMetadata[] {
    return results?.map(
      (result) =>
        new FieldSafetyNoticeMetadata({
          fsnId: result.id,
          title: result.title[0],
          date: result.date[0],
          link: result.link[0],
          summary: result.summary[0],
          source: result.source[0],
          fulltext: result.fulltext?.[0],
          fsnType: result.type[0],
          potentialPdfUrl: Array.isArray(result.pdfLinks)
            ? result.pdfLinks[0]
            : result.pdfLinks,
        }),
    )
  }
}
