<template>
  <Modal ref="modal">
    <template #activator="{ show }">
      <ActionButton color="secondary" @click="show">
        <PlusIcon class="-ml-0.5 size-5 stroke-2" aria-hidden="true" />
        Add User
      </ActionButton>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Add User
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content>
      <div>
        <form class="w-full space-y-4">
          <TextInput
            ref="userFristNameInput"
            v-model="createUserModel.firstName"
            type="text"
            label="First name"
            placeholder="First name"
            :rules="[(v) => !!v || 'please enter the firstname']"
            :is-required="true"
          />
          <TextInput
            ref="userLastNameInput"
            v-model="createUserModel.lastName"
            type="text"
            label="Last name"
            placeholder="Last name"
            :rules="[(v) => !!v || 'please enter the lastname']"
            :is-required="true"
          />
          <TextInput
            ref="userEmailInput"
            v-model="createUserModel.email"
            type="text"
            label="Email"
            placeholder="Email"
            :rules="[
              (v) => !!v || 'Please enter an email',
              (v) => IsValidEmail(v) || 'Invalid email format',
            ]"
            :is-required="true"
          />
          <TextInput
            ref="userPasswordInput"
            v-model="createUserModel.password"
            type="password"
            label="Password"
            placeholder="Password"
            :rules="[(v) => !!v || 'please enter a password']"
            :is-required="true"
          />

          <Dropdown
            v-model="createUserModel.role"
            :display-value="(u: Role) => rolesText[u]"
            :filter="(u, q) => u.toLowerCase().includes(q.toLowerCase())"
            label="Role"
            placeholder="Role"
            :items="[Role.USER, Role.ADMIN, Role.READ_ONLY]"
          />
        </form>
      </div>
    </template>
    <template #footer="{ hide }">
      <div class="flex gap-2 justify-end">
        <ActionButton color="neutral" @click="hide"> Cancel </ActionButton>
        <ActionButton color="primary" @click="create(hide)">
          Confirm</ActionButton
        >
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { Role } from '@core/domain/types/role.type'
import useUsers from '@app/composables/use-users'
import { hideAll } from 'tippy.js'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import useLoading from '@app/composables/use-loading'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import XIcon from '@app/components/Icons/XIcon.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import ActionButton from '@app/components/Global/ActionButton.vue'
import PlusIcon from '@app/components/Icons/PlusIcon.vue'
import Dropdown from '@app/components/Global/Dropdown/Dropdown.vue'

const rolesText = {
  [Role.USER]: 'User',
  [Role.ADMIN]: 'Admin',
  [Role.READ_ONLY]: 'Read Only',
}
interface CreateUserModel {
  email: string
  password: string
  role: Role
  firstName: string
  lastName: string
}

const userEmailInput = ref<InstanceType<typeof TextInput>>()
const userPasswordInput = ref<InstanceType<typeof TextInput>>()

const snackbar = useSnackbar()
const loading = useLoading()
const createUserModel = ref<CreateUserModel>({
  email: '',
  role: Role.USER,
  password: '',
  firstName: '',
  lastName: '',
})
const users = useUsers()

async function create(cb: () => void = () => {}) {
  if (!validate()) return
  try {
    loading.start()
    await users.create(createUserModel.value)
    snackbar.show(SnackbarState.SUCCESS, 'User created successfully')
    reset()
    hideAll()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
    cb()
  }
}
function IsValidEmail(email: string) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/

  return emailPattern.test(email)
}

function validate() {
  const isEmailValid = IsValidEmail(createUserModel.value.email)
  const isPasswordValid = userPasswordInput.value?.validate()
  return isEmailValid && isPasswordValid
}

function reset() {
  createUserModel.value = {
    email: '',
    role: Role.USER,
    password: '',
    firstName: '',
    lastName: '',
  }
}
</script>
