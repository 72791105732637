<template>
  <slot
    name="activator"
    :show="() => drawerManager.showDrawer(id)"
    @click.capture.prevent=""
  />
  <teleport to="#drawer">
    <TransitionRoot as="template" :show="drawerManager.isDrawerVisible(id)">
      <div
        style="z-index: 999999999"
        class="fixed inset-0 bg-black/50 flex items-center justify-end"
        @click="dismissible && hide()"
      >
        <TransitionChild
          as="template"
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enter-from="translate-x-full"
          enter-to="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leave-from="translate-x-0"
          leave-to="translate-x-full"
        >
          <div
            class="flex flex-col bg-white max-w-full w-[40rem] h-full shadow-xl"
            @click.stop=""
          >
            <div
              class="border-b p-5 text-xl font-semibold flex justify-between text-dark-neutral-500 bg-white"
            >
              <slot name="header" :hide="hide"></slot>
            </div>

            <div class="flex-1 overflow-auto text-mid-neutral-500 bg-white">
              <slot name="content" :hide="hide"></slot>
            </div>

            <div
              v-if="props.showFooter"
              class="px-5 py-3 bg-white border-t border-mid-neutral-50"
            >
              <slot name="footer" :hide="hide"></slot>
            </div>
          </div>
        </TransitionChild>
      </div>
    </TransitionRoot>
  </teleport>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue'
import { TransitionRoot, TransitionChild } from '@headlessui/vue'
import useDrawerManager from './drawer-manager'
import { v4 as uuid4 } from 'uuid'

const id = uuid4()
const drawerManager = useDrawerManager()
drawerManager.addDrawer(id)

const props = withDefaults(
  defineProps<{
    dismissible?: boolean
    showFooter?: boolean
    position?: string
    contentPadding?: string
  }>(),
  {
    dismissible: false,
    showFooter: true,
    position: 'top-right',
    contentPadding: 'px-5 py-5',
  },
)

const emit = defineEmits<(e: 'hide') => void>()

function hide() {
  drawerManager.hideDrawer(id)
  emit('hide')
}

function dismissOnEsc(event: KeyboardEvent) {
  if (event.key === 'Escape' && props.dismissible) {
    hide()
  }
}
onMounted(() => {
  document.addEventListener('keydown', dismissOnEsc)
})

onBeforeUnmount(() => document.removeEventListener('keydown', dismissOnEsc))

defineExpose({
  hide: () => hide(),
  show: () => drawerManager.showDrawer(id),
})
</script>
