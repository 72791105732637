<template>
  <Modal ref="modal" position="center">
    <template #activator="{ show }">
      <ActionButton color="secondary" @click="show">
        <PlusIcon class="-ml-0.5 size-5 stroke-2" aria-hidden="true" />
        Add Team
      </ActionButton>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Add Team
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>
    <template #content>
      <div class="space-y-10">
        <div class="space-y-5">
          <TextInput
            ref="userFristNameInput"
            v-model="createTeamModel.name"
            type="text"
            label="Team name"
            placeholder="Team name"
            :is-required="true"
          />
        </div>
      </div>
    </template>
    <template #footer="{ hide }">
      <div class="flex gap-2 justify-end">
        <ActionButton color="neutral" @click="hide"> Cancel </ActionButton>
        <ActionButton color="primary" @click="createTeam(hide)"
          >Confirm</ActionButton
        >
      </div>
    </template>
  </Modal>
</template>
<script lang="ts" setup>
import ActionButton from '@app/components/Global/ActionButton.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import PlusIcon from '@app/components/Icons/PlusIcon.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { TeamsServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { Team } from '@core/domain/models/team.model'
import { isAxiosError } from 'axios'
import { onMounted, ref } from 'vue'

const teamsService = injectStrict(TeamsServiceKey)
const teams = ref<Team[]>()
const createTeamModel = ref<{ name: string }>({ name: '' })
const loading = useLoading()
const snackbar = useSnackbar()
async function createTeam(cb: () => void) {
  loading.start()
  try {
    const data = { name: createTeamModel.value.name }
    await teamsService.createTeam(data)
    reset()
    await fetchTeams()
    cb()
    snackbar.success('Team created successfully')
  } catch (e) {
    if (isAxiosError(e)) {
      snackbar.error(e.response?.data.message)
    } else {
      snackbar.error('An error occurred')
    }
  } finally {
    loading.stop()
  }
}

function reset() {
  createTeamModel.value = { name: '' }
}

async function fetchTeams() {
  teams.value = await teamsService.findTeams()
}

onMounted(async () => await fetchTeams())
</script>
