<template>
  <div
    class="grid grid-cols-[2fr_4fr_120px_150px_120px_50px] w-full items-center"
  >
    <TextInput
      v-model="updatedAttribute.label"
      :disabled="review.isPlanReadonly.value"
      placeholder="Etiology"
      multiline
      input-classes=" w-full group-last:hover:border-slate-300 hover:border-slate-300 hover:border-b-transparent hover:border-r-transparent focus:ring-1 focus:ring-inset group-last:focus:border-blue-500 group-first:rounded-tl-md rounded-none border-b-transparent border-r-transparent group-last:border-b group-last:border-slate-300 group-last:rounded-bl-md   group-last:hover:border-blue-500  group-last:focus-ring-1 group-last:focus-ring-inset group-last:border-r-transparent group-last:hover:border-r-transparent"
      @enter="editAttributeStructure()"
      @blur="editAttributeStructure()"
    />
    <TextInput
      v-model="updatedAttribute.question"
      :disabled="review.isPlanReadonly.value"
      multiline
      input-classes=" w-full group-last:hover:border-slate-300  hover:border-slate-300 hover:border-b-transparent hover:border-slate-300 hover:border-b-transparent focus:ring-1 focus:ring-inset group-last:focus:border-blue-500  rounded-none border-b-transparent group-last:border-slate-300 group-last:hover:border-blue-500  group-last:focus-ring-1 group-last:focus-ring-inset group-last:border-l"
      placeholder="What is the cause or origin of the disease?"
      @enter="editAttributeStructure()"
      @blur="editAttributeStructure()"
    />
    <FloatingMenu placement="bottom" :arrow="false" class="h-full">
      <template #activator="{ show }">
        <button
          class="border border-b-transparent border-l-transparent group-last:border-b-slate-300 p-3 border-slate-300 flex items-start justify-between gap-2 w-[120px] h-full"
          :disabled="review.isPlanReadonly.value"
          @click="show()"
        >
          {{ variableType }} <ChevronDownIcon class="w-4 h-4" />
        </button>
      </template>
      <template #content>
        <div class="flex flex-col w-[110px]">
          <button
            v-for="type in ['Textual', 'Numeric']"
            :key="'type-' + type"
            class="px-2 py-1 border-b last:border-b-0"
            :class="{
              'bg-slate-100': type === variableType,
              'hover:bg-slate-100': type !== 'Numeric',
              'text-slate-300 cursor-not-allowed': type === 'Numeric',
            }"
            :disabled="type === 'Numeric'"
          >
            {{ type }}
          </button>
        </div>
      </template>
    </FloatingMenu>

    <FloatingMenu placement="bottom" :arrow="false" class="h-full">
      <template #activator="{ show }">
        <button
          class="border border-b-transparent border-l-transparent group-last:border-b-slate-300 p-3 border-slate-300 flex items-start justify-between gap-2 w-[150px] h-full group-first:rounded-tr-md group-last:rounded-br-md"
          :disabled="review.isPlanReadonly.value"
          @click="show()"
        >
          {{ extractionType }} <ChevronDownIcon class="w-4 h-4" />
        </button>
      </template>
      <template #content="{ hide }">
        <div class="flex flex-col w-[140px]">
          <button
            class="px-2 py-1 border-b last:border-b-0 hover:bg-slate-100"
            :class="{
              'bg-slate-100': extractionType === 'Manual',
            }"
            @click="disableAiUse(hide)"
          >
            Manual
          </button>
          <button
            class="px-2 py-1 border-b last:border-b-0 hover:bg-slate-100"
            :class="{
              'bg-slate-100': extractionType === 'Automated',
            }"
            @click="enableAiUse(hide)"
          >
            Automated
          </button>
        </div>
      </template>
    </FloatingMenu>
    <FloatingMenu placement="bottom" :arrow="false" class="h-full">
      <template #activator="{ show }">
        <button
          class="border border-b-transparent border-l-transparent group-last:border-b-slate-300 p-3 border-slate-300 flex items-start justify-between gap-2 w-[120px] h-full group-first:rounded-tr-md group-last:rounded-br-md"
          :disabled="review.isPlanReadonly.value"
          @click="show()"
        >
          {{ synthesisText }} <ChevronDownIcon class="w-4 h-4" />
        </button>
      </template>
      <template #content="{ hide }">
        <div class="flex flex-col w-[110px]">
          <button
            class="px-2 py-1 border-b last:border-b-0 hover:bg-slate-100"
            :class="{
              'bg-slate-100': !updatedAttribute.shouldDoSynthesis,
            }"
            @click="disableSynthesisUse(hide)"
          >
            No
          </button>
          <button
            class="px-2 py-1 border-b last:border-b-0 hover:bg-slate-100"
            :class="{
              'bg-slate-100': updatedAttribute.shouldDoSynthesis,
            }"
            @click="enableSynthesisUse(hide)"
          >
            Yes
          </button>
        </div>
      </template>
    </FloatingMenu>
    <div class="flex items-center px-2 gap-2">
      <button
        :class="{
          'text-red-300': review.isPlanReadonly.value,
          'text-red-600': !review.isPlanReadonly.value,
        }"
        :disabled="review.isPlanReadonly.value"
        @click="emit('deleteAttributeStructure', structure.id)"
      >
        <TrashIcon class="w-4 h-4" />
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { computed, ref, toRaw } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { deepEqual } from '@app/utils/objects'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

const props = defineProps<{
  structure: AttributeStructure
  questionId: number
}>()

const variableType = ref('Textual')

const emit =
  defineEmits<(e: 'deleteAttributeStructure', structureId: string) => void>()
const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()
const updatedAttribute = ref<AttributeStructure>(
  structuredClone(toRaw(props.structure)),
)

const extractionType = computed(() =>
  updatedAttribute.value.shouldUseAi ? 'Automated' : 'Manual',
)
const synthesisText = computed(() =>
  updatedAttribute.value.shouldDoSynthesis ? 'Yes' : 'No',
)
async function editAttributeStructure() {
  if (deepEqual(props.structure, updatedAttribute.value)) return

  try {
    loading.start()
    await review.editAttributeStructure(
      review.entity.value.id,
      updatedAttribute.value,
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function enableAiUse(cb: () => void) {
  if (props.structure.shouldUseAi) {
    cb()
    return
  }
  loading.start()
  try {
    await review.editAttributeStructure(review.entity.value.id, {
      ...updatedAttribute.value,
      shouldUseAi: true,
    })
    updatedAttribute.value.shouldUseAi = true
    cb()
  } finally {
    loading.stop()
  }
}

async function disableAiUse(cb: () => void) {
  if (!props.structure.shouldUseAi) {
    cb()
    return
  }
  loading.start()
  try {
    await review.editAttributeStructure(review.entity.value.id, {
      ...updatedAttribute.value,
      shouldUseAi: false,
    })
    updatedAttribute.value.shouldUseAi = false
    cb()
  } finally {
    loading.stop()
  }
}

async function disableSynthesisUse(cb: () => void) {
  if (!props.structure.shouldDoSynthesis) {
    cb()
    return
  }
  loading.start()
  try {
    await review.editAttributeStructure(review.entity.value.id, {
      ...updatedAttribute.value,
      shouldDoSynthesis: false,
    })
    updatedAttribute.value.shouldDoSynthesis = false
    cb()
  } finally {
    loading.stop()
  }
}

async function enableSynthesisUse(cb: () => void) {
  if (props.structure.shouldDoSynthesis) {
    cb()
    return
  }
  loading.start()
  try {
    await review.editAttributeStructure(review.entity.value.id, {
      ...updatedAttribute.value,
      shouldDoSynthesis: true,
    })
    updatedAttribute.value.shouldDoSynthesis = true
    cb()
  } finally {
    loading.stop()
  }
}
</script>
