<template>
  <div>
    <div>
      <div class="overflow-hidden sm:rounded-lg">
        <table
          class="w-full divide-y divide-slate-200"
          aria-describedby="users table"
        >
          <thead class="bg-light-neutral-300 w-full">
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-[24px] text-left text-sm font-medium text-mid-neutral-500"
              >
                Username
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-medium text-mid-neutral-500"
              >
                Email
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-medium text-mid-neutral-500"
              >
                <UserRolesModal />
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-medium text-mid-neutral-500"
              >
                Status
              </th>
              <th
                scope="col"
                class="pr-[24px] py-3.5 text-right text-sm font-medium text-mid-neutral-500"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-slate-200 bg-white">
            <tr
              v-for="user in filteredUsers"
              :key="'user-' + user.id"
              class="hover:bg-light-neutral-200"
            >
              <td class="py-3 px-6 pr-[24px]">
                <div class="flex gap-2 items-center">
                  <div
                    class="size-10 flex items-center justify-center bg-primary-50 text-primary-500 rounded-full font-medium ring-2 ring-white"
                  >
                    <p class="text-sm font-medium">
                      {{
                        (
                          user.firstName.charAt(0) + user.lastName.charAt(0)
                        ).toUpperCase()
                      }}
                    </p>
                  </div>
                  <div class="flex gap-1">
                    <p class="text-dark-neutral-500">
                      {{ buildUserFullname(user) }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="py-3 px-3">
                <div>
                  <p class="text-dark-neutral-500">{{ user.email }}</p>
                </div>
              </td>
              <td class="py-3 px-3">
                <p class="text-dark-neutral-500">
                  {{
                    user.role === Role.ADMIN
                      ? 'Admin'
                      : user.role === Role.USER
                        ? 'User'
                        : 'Reader'
                  }}
                </p>
              </td>
              <td class="py-3 px-3">
                <div class="flex gap-2 items-center">
                  <div
                    :class="
                      user.isDisabled
                        ? 'text-mid-neutral-300'
                        : 'text-green-600'
                    "
                    class="text-sm"
                  >
                    {{ user.isDisabled ? 'Inactive' : 'Active' }}
                  </div>

                  <FloatingMenu>
                    <template #activator="{ show }">
                      <HeadlessSwitch
                        :model-value="!user.isDisabled"
                        :class="[
                          !user.isDisabled
                            ? 'bg-green-600'
                            : 'bg-light-neutral-600',
                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden focus:ring-2 focus:ring-green-600 focus:ring-offset-2',
                        ]"
                        @click="show"
                      >
                        <span
                          aria-hidden="true"
                          :class="[
                            !user.isDisabled
                              ? 'translate-x-5'
                              : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
                          ]"
                        />
                      </HeadlessSwitch>
                    </template>
                    <template #content="{ hide }">
                      <div class="p-4">
                        <div class="rounded-lg p-4">
                          <div class="flex">
                            <div class="flex-1 md:flex md:justify-between">
                              <p
                                v-if="!user.isDisabled"
                                class="text-md text-dark-neutral-500"
                              >
                                Are you sure you want to disable this user?<br />
                              </p>
                              <p v-else class="text-md text-dark-neutral-500">
                                Are you sure you want to enable this user?
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-end gap-2 mt-4">
                          <button
                            class="w-24 inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50"
                            @click="hide"
                          >
                            Cancel
                          </button>
                          <button
                            :class="[
                              'inline-flex w-24 justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-xs',
                              user.isDisabled
                                ? 'bg-green-600 hover:bg-green-500'
                                : 'bg-red-600 hover:bg-red-500',
                            ]"
                            @click="toggleUserState(user.id!, hide)"
                          >
                            {{ user.isDisabled ? 'Enable' : 'Disable' }}
                          </button>
                        </div>
                      </div>
                    </template>
                  </FloatingMenu>
                </div>
              </td>
              <td class="py-3 pr-[24px]">
                <div class="flex-end gap-2 text-right">
                  <button
                    class="inline-flex items-center gap-x-1 text-sm font-semibold text-gray-900"
                  >
                    <div
                      class="bg-light-neutral-500 hover:bg-light-neutral-600 rounded-full h-[30px] w-[30px] flex items-center justify-center text-mid-neutral-500"
                    >
                      <EditUserFloatingMenu
                        :data="{
                          firstName: user.firstName,
                          lastName: user.lastName,
                          role: user.role,
                          userId: user.id!,
                        }"
                      />
                    </div>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="border w-full flex justify-start">
        <Switch
          v-model="shouldShowInactive"
          class="text-sm text-dark-neutral-300 px-[24px] my-4"
        >
          Show inactive users
        </Switch>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { Role } from '@core/domain/types/role.type'
import EditUserFloatingMenu from './EditUserFloatingMenu.vue'
import { Switch as HeadlessSwitch } from '@headlessui/vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import useUsers from '@app/composables/use-users'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { User } from '@core/domain/models/user.model'
import Switch from '@app/components/Global/Switch.vue'
import UserRolesModal from './UserRolesModal.vue'

const shouldShowInactive = ref(false)

const snackbar = useSnackbar()
const loading = useLoading()
const users = useUsers()
users.refresh()

async function toggleUserState(userId: string, cb: () => void) {
  const user = users.users.value.find((u) => u.id === userId)
  try {
    loading.start()
    if (!user?.isDisabled) {
      await users.disableUser(userId)
      snackbar.show(SnackbarState.SUCCESS, 'User successfully disabled')
    } else {
      await users.enableUser(userId)
      snackbar.show(SnackbarState.SUCCESS, 'User successfully enabled')
    }
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

const filteredUsers = computed(() => {
  return shouldShowInactive.value
    ? users.users.value.filter((user) => user.isDisabled)
    : users.users.value.filter((user) => !user.isDisabled)
})

function buildUserFullname(user: User) {
  if (!user) return ''
  let fullName = ''
  if (user.firstName) {
    fullName += user.firstName
  }
  if (user.lastName) {
    fullName += ' ' + user.lastName
  }
  if (!fullName) {
    fullName = user.email
  }
  return fullName
}
</script>
