<template>
  <Modal ref="modal" position="center">
    <template #activator="{ show }">
      <button @click="show">
        <PenIcon class="w-4 h-4" />
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Edit user
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content>
      <div>
        <div class="space-y-6">
          <TextInput
            ref="userFristNameInput"
            v-model="editUserModel.firstName"
            type="text"
            label="First name"
            placeholder="First name"
            :is-required="true"
          />
          <TextInput
            ref="userLastNameInput"
            v-model="editUserModel.lastName"
            type="text"
            label="Last name"
            placeholder="Last name"
            :is-required="true"
          />

          <Dropdown
            v-model="editUserModel.role"
            :display-value="(u: Role) => rolesText[u]"
            :filter="(u, q) => u.toLowerCase().includes(q.toLowerCase())"
            label="Role"
            placeholder="Role"
            :items="[Role.USER, Role.ADMIN, Role.READ_ONLY]"
          />
        </div>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="flex gap-2 justify-end">
        <ActionButton color="neutral" @click=";[reset(), hide()]">
          Cancel
        </ActionButton>
        <ActionButton color="primary" @click="edit(hide)">
          Confirm
        </ActionButton>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { Role } from '@core/domain/types/role.type'
import useUsers from '@app/composables/use-users'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import useLoading from '@app/composables/use-loading'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import XIcon from '@app/components/Icons/XIcon.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import PenIcon from '@app/components/Icons/PenIcon.vue'
import Dropdown from '@app/components/Global/Dropdown/Dropdown.vue'
import ActionButton from '@app/components/Global/ActionButton.vue'

const rolesText = {
  [Role.USER]: 'User',
  [Role.ADMIN]: 'Admin',
  [Role.READ_ONLY]: 'Read Only',
}

interface EditUserModel {
  userId: string
  role: Role
  firstName: string
  lastName: string
}

const props = withDefaults(
  defineProps<{
    data: EditUserModel
  }>(),
  {},
)

const editUserModel = ref<EditUserModel>({
  role: props.data.role,
  firstName: props.data.firstName,
  lastName: props.data.lastName,
  userId: props.data.userId,
})

const snackbar = useSnackbar()
const loading = useLoading()

const users = useUsers()

async function edit(cb: () => void = () => {}) {
  try {
    loading.start()
    await users.edit(editUserModel.value)
    snackbar.show(SnackbarState.SUCCESS, 'User updated successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
    cb()
  }
}
function reset() {
  editUserModel.value = {
    role: props.data.role,
    firstName: props.data.firstName,
    lastName: props.data.lastName,
    userId: props.data.userId,
  }
}
</script>
