<template>
  <div
    class="border border-[#D9E4EA] py-3 px-2 items-center shrink-0 relative"
    :class="{
      'bg-azureish-white/25': isHoveredOver,
    }"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
  >
    <div class="flex gap-2">
      <StudyDuplicationButton
        :study="reviewItem"
        :is-loading="isLoading"
        @mark-as-duplicate="markStudyAsDuplicate"
        @mark-as-not-duplicate="markStudyAsNotDuplicate"
      />
      <StudyTitleAndAbstractScreeningButton
        v-if="
          review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria
            .length ?? 0 > 0
        "
        :study="reviewItem"
        :is-loading="isLoading"
        @clear-title-and-abstract-screening="clearTitleAndAbstractScreening"
        @set-title-and-abstract-screening="setTitleAndAbstractScreening"
      />
      <StudyFullTextScreeningButton
        :study="reviewItem"
        :is-loading="isLoading"
        @clear-full-text-screening="clearFullTextScreening"
        @set-full-text-screening="setFullTextScreening"
      />
      <ImportCitation
        v-if="reviewItem.state === StudyState.INCLUDED"
        :review-item="reviewItem"
        :is-review-read-only="review.isReviewReadonly.value"
      />
      <div
        v-if="isCitationImported"
        class="absolute top-0 -right-8 w-8 h-full bg-yellow-200 flex flex-col justify-between items-center px-2 py-4"
      >
        <button @click="scrollToCitationParent">
          <svg
            class="w-6 text-yellow-800"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="3.5957"
              y="14.4004"
              width="16.8"
              height="4.8"
              rx="2.4"
              fill="currentColor"
            />
            <circle cx="9.39615" cy="8.94303" r="3.50943" fill="currentColor" />
            <circle
              cx="16.189"
              cy="10.3013"
              r="2.15094"
              fill="currentColor"
              fill-opacity="0.5"
            />
          </svg>
        </button>
        <div class="w-5 h-px bg-yellow-500 rounded-full"></div>
        <button @click="deleteCitation">
          <svg
            class="w-6 text-yellow-800"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.667 4.66667L12.0888 12.7617C12.0389 13.4594 11.4584 14 10.7588 14H5.24182C4.5423 14 3.96171 13.4594 3.91187 12.7617L3.33366 4.66667M6.66699 7.33333V11.3333M9.33366 7.33333V11.3333M10.0003 4.66667V2.66667C10.0003 2.29848 9.70185 2 9.33366 2H6.66699C6.2988 2 6.00033 2.29848 6.00033 2.66667V4.66667M2.66699 4.66667H13.3337"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState, StudyState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { HttpException } from '@core/exceptions/http.exception'
import { hideAll } from 'tippy.js'

import { ref } from 'vue'
import StudyDuplicationButton from './StudyDuplicationButton.vue'
import StudyTitleAndAbstractScreeningButton from './StudyTitleAndAbstractScreeningButton.vue'
import StudyFullTextScreeningButton from './StudyFullTextScreeningButton.vue'
import ImportCitation from './ImportCitation.vue'

const props = defineProps<{
  reviewItem: ReviewItem
  screeningStage?: boolean
  expansionPanel: any
}>()

const isCitationImported = ref(false)
if (props.reviewItem.search.parentStudyId) {
  isCitationImported.value = true
}

const loading = useLoading()
const isLoading = ref(false)

const review = injectStrict(ReviewKey)

const snackbar = useSnackbar()
const isHoveredOver = defineModel<boolean>()

function onMouseOver() {
  isHoveredOver.value = true
}
function onMouseOut() {
  isHoveredOver.value = false
}

async function clearFullTextScreening() {
  try {
    startLoading()
    await review.clearStudyFullTextScreening(props.reviewItem.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully cleared full text screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

function startLoading() {
  loading.start()
  isLoading.value = true
}
function stopLoading() {
  loading.stop()
  isLoading.value = false
}

async function markStudyAsDuplicate(parentStudyId: number) {
  try {
    startLoading()
    await review.markStudyAsDuplicate(props.reviewItem.id, parentStudyId)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study marked as duplicate successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }

  stopLoading()
}

async function markStudyAsNotDuplicate() {
  try {
    startLoading()
    await review.markStudyAsNotDuplicate(props.reviewItem.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study marked as not duplicate successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function setTitleAndAbstractScreening(key: string) {
  if (
    !props.reviewItem.pdfFile &&
    review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria
      ?.length === 0 &&
    key === 'Included'
  ) {
    snackbar.show(
      SnackbarState.ERROR,
      'You must upload the study to continue. Please upload a clean document without highlights or comments',
    )
    return
  }
  try {
    startLoading()
    await review.setStudyTitleAndAbstractScreening(props.reviewItem.id, key)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully updated title and abstract screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function clearTitleAndAbstractScreening() {
  try {
    startLoading()
    await review.clearStudyTitleAndAbstractScreening(props.reviewItem.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully cleared title and abstract screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function setFullTextScreening(key: string) {
  try {
    startLoading()
    await review.setStudyFullTextScreening(props.reviewItem.id, key)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully updated full text screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function deleteCitation() {
  loading.start()
  try {
    const searchId = props.reviewItem?.search?.id
    if (!searchId) return
    await review.removeSearch(searchId)
  } finally {
    loading.stop()
  }
}

function scrollToCitationParent() {
  const parent = document.getElementById(
    `review-item-${props.reviewItem.search.parentStudyId}`,
  )
  if (parent) {
    document
      .querySelector(
        `#review-item-${props.reviewItem.search.parentStudyId} .scroll-anchor`,
      )
      ?.scrollIntoView({ behavior: 'smooth' })
    parent.classList.add('!bg-green-200')
    setTimeout(() => {
      parent.classList.remove('!bg-green-200')
    }, 3000)
  }
}
</script>
