<template>
  <template v-if="reviewExists">
    <div class="w-full">
      <teleport to="#header-middle">
        <ReviewTitle />
      </teleport>

      <teleport to="#toolbar">
        <Tabs v-model:tab="activeStep" :tabs="tabs">
          <template #tab-1="{ tab }">
            <div class="flex items-center px-6 py-5 w-full">
              <div
                v-if="planErrors.length <= 0"
                :class="[
                  'w-10 h-10 flex items-center justify-center shrink-0 rounded-full border-2 text-sm font-medium leading-4',
                  tab.number === activeStep
                    ? 'text-primary border-primary/60'
                    : 'bg-white border-slate-300 text-slate-500',
                ]"
              >
                <CheckIcon class="w-6" />
              </div>
              <div
                v-else
                v-tooltip="planErrors.join('<br/>')"
                :class="[
                  'w-10 h-10 flex items-center justify-center shrink-0 rounded-full border-2 font-medium leading-4 text-sm',
                  tab.number === activeStep
                    ? 'bg-orange-600 text-white border-transparent'
                    : 'bg-white border-orange-600 text-orange-600',
                ]"
              >
                0{{ tab.number }}
              </div>

              <div class="flex items-start flex-col px-4">
                <div class="text-slate-600 font-medium leading-5">
                  {{ tab.title }}
                </div>
                <div
                  v-if="tab.subtitle"
                  class="text-slate-400 text-sm leading-5"
                >
                  {{ tab.subtitle }}
                </div>
              </div>
            </div>
          </template>

          <template #tab-2="{ tab }">
            <div class="flex items-center px-6 py-5 w-full">
              <div
                v-if="executeErrors.length <= 0"
                :class="[
                  'w-10 h-10 flex items-center justify-center shrink-0 rounded-full border-2 text-sm font-medium leading-4',
                  tab.number === activeStep
                    ? 'text-primary border-primary/60'
                    : 'bg-white border-slate-300 text-slate-500',
                ]"
              >
                <CheckIcon class="w-6" />
              </div>
              <div
                v-else
                v-tooltip="executeErrors.join('<br/>')"
                :class="[
                  'w-10 h-10 flex items-center justify-center shrink-0 rounded-full border-2 font-medium leading-4 text-sm',
                  tab.number === activeStep
                    ? 'bg-orange-600 text-white border-transparent'
                    : 'bg-white border-orange-600 text-orange-600',
                ]"
              >
                0{{ tab.number }}
              </div>

              <div class="flex items-start flex-col px-4">
                <div class="text-slate-600 font-medium leading-5">
                  {{ tab.title }}
                </div>
                <div
                  v-if="tab.subtitle"
                  class="text-slate-400 text-sm leading-5"
                >
                  {{ tab.subtitle }}
                </div>
              </div>
            </div>
          </template>
        </Tabs>
      </teleport>
    </div>
    <KeepAlive>
      <component :is="reviewStepComponentName" />
    </KeepAlive>
  </template>
</template>

<script lang="ts" setup>
import useReview, { ReviewKey } from '@app/views/Review/use-review'
import {
  computed,
  inject,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch,
  watchEffect,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ReviewTitle from './Overview/ReviewTitle.vue'
import useLoading from '@app/composables/use-loading'
import Execute from './Execute/Execute.vue'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import Export from './Export/Export.vue'
import Plan from './Plan/Plan.vue'
import { ReviewStep } from './review.types'
import { isAxiosError } from 'axios'
import { CheckIcon } from '@heroicons/vue/24/outline'

const router = useRouter()
const route = useRoute()
const loading = useLoading()
const reviewExists = ref(false)
const review = useReview(parseInt(route.params.id as string))
provide(ReviewKey, review)

const activeStep = ref(1)
loading.start()
await review
  .refresh()
  .then(() => {
    activeStep.value =
      review.entity.value.plan?.lockState === ReviewLockState.LOCKED
        ? ReviewStep.execute
        : ReviewStep.plan
    reviewExists.value = true
  })
  .catch((e) => {
    if (isAxiosError(e) && e.response?.status === 404) {
      router.push('/404')
    } else throw e
  })
  .finally(() => {
    loading.stop()
  })

const reviewStepComponentName = computed(() => {
  switch (activeStep.value) {
    case ReviewStep.plan:
      return Plan
    case ReviewStep.execute:
      return Execute
    case ReviewStep.export:
      return Export
    default:
      return Plan
  }
})

const tabs = computed(() => {
  if (!review.entity.value) {
    return []
  }
  const tabs = [
    {
      number: ReviewStep.plan,
      title: 'Plan',
      subtitle: 'Outline objectives and criteria Edited',
    },
    {
      number: ReviewStep.execute,
      title: 'Execute',
      subtitle: 'Perform screening and analysis',
    },
    {
      number: ReviewStep.export,
      title: 'Export',
      subtitle: 'Generate and share results',
      isDisabled:
        (review.entity.value.plan?.lockState !== ReviewLockState.DISABLED &&
          !review.isPlanReadonly.value) ||
        review.isArchived.value,
    },
  ]

  return tabs
})

const planErrors = ref<string[]>([])
const executeErrors = ref<string[]>([])

const planValidationWorker = new Worker(
  new URL('./workers/planValidation.worker.ts', import.meta.url),
  {
    type: 'module',
  },
)

const executeValidationWorker = new Worker(
  new URL('./workers/executeValidation.worker.ts', import.meta.url),
  {
    type: 'module',
  },
)

watch(
  review.entity,
  () => {
    planValidationWorker.postMessage(JSON.stringify(review.entity.value))
    executeValidationWorker.postMessage(JSON.stringify(review.entity.value))
  },
  {
    immediate: true,
    deep: true,
  },
)
onMounted(() => {
  planValidationWorker.onmessage = (e) => {
    planErrors.value = JSON.parse(e.data)
  }
  executeValidationWorker.onmessage = (e) => {
    executeErrors.value = JSON.parse(e.data)
  }
})

const setSidebarSize = inject('setSidebarSize') as (size: string) => void

const sidebarSize = computed(() => (reviewExists.value ? 'small' : 'large'))

watchEffect(() => {
  if (setSidebarSize) {
    setSidebarSize(sidebarSize.value)
  }
})

onUnmounted(() => {
  if (setSidebarSize) {
    setSidebarSize('large')
  }

  planValidationWorker.terminate()
  executeValidationWorker.terminate()
})
</script>

<style>
.ripple {
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
