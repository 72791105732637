<template>
  <FloatingMenu v-model="isShown">
    <template #activator="{ show }">
      <button
        class="inline-flex items-center gap-x-1 text-sm font-semibold text-gray-900"
        @click="show"
      >
        <div
          class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center text-mid-neutral-500"
        >
          <EllipsisHorizontalIcon class="size-6" aria-hidden="true" />
        </div>
      </button>
    </template>

    <template #content>
      <div class="text-sm w-[11rem]">
        <div class="flex flex-col">
          <ProjectMembersAssignment :project="project" :is-shown="isShown" />
          <ProjectSettings :project="project" :is-shown="isShown" />
          <button
            class="px-4 py-3 flex items-center gap-2 hover:bg-light-neutral-500 w-full"
            @click="modal?.show()"
          >
            <TrashIcon class="size-5 text-error-500" />
            <span
              class="font-medium text-dark-neutral-500 text-sm text-error-500"
            >
              Delete Project
            </span>
          </button>
        </div>
      </div>
    </template>
  </FloatingMenu>

  <Modal
    v-if="isAdmin"
    ref="modal"
    :show-footer="true"
    dismissible
    position="center"
  >
    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Delete Project
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content>
      <p class="text-base text-dark-neutral-500">
        Are you sure you want to delete this project? This action cannot be
        undone.
      </p>
    </template>

    <template #footer="{ hide }">
      <div class="flex justify-end gap-2">
        <ActionButton color="neutral" @click="hide"> Cancel </ActionButton>
        <ActionButton color="error" @click.stop="deleteProject(project.id!)">
          Delete
        </ActionButton>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import ProjectSettings from './ProjectSettings.vue'
import ProjectMembersAssignment from '../ProjectMembersAssignment/ProjectMembersAssignment.vue'
import EllipsisHorizontalIcon from '@app/components/Icons/EllipsisHorizontalIcon.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { Project } from '@core/domain/models/project.model'
import { TrashIcon } from '@heroicons/vue/24/outline'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import useProjects from '@app/views/Projects/use-projects'
import useModalManager from '@app/components/Global/Modal/modal-manager'
import { hideAll } from 'tippy.js'
import { Id } from '@core/domain/types/id.type'
import { SnackbarState } from '@app/types'
import ActionButton from '@app/components/Global/ActionButton.vue'

defineProps<{ project: Project }>()
const isShown = ref(true)
const modal = ref<InstanceType<typeof Modal>>()

const { user } = injectStrict(AuthKey)

const isAdmin = computed(() => user.value?.role === Role.ADMIN)

const loading = useLoading()
const snackbar = useSnackbar()
const projects = useProjects()
const modalManager = useModalManager()

async function deleteProject(projectId: Id) {
  if (!projectId) {
    snackbar.error('Project ID is missing')
    return
  }
  loading.start()
  try {
    await projects.deleteProject(projectId)
    hideAll()
    modalManager.hideAllModals()
    snackbar.show(SnackbarState.SUCCESS, 'Project deleted successfully')
  } catch (e) {
    snackbar.error('An error has occurred')
    throw e
  } finally {
    loading.stop()
  }
}
</script>
