type WorkerRequest<T = unknown> = { id: number; action: string; payload?: T }
type WorkerResponse<T = unknown> = { id: number; result: T }

export class BackgroundTaskRunner {
  private worker: Worker
  private resolverMap: Map<number, (value: any) => void>
  private idCounter: number
  constructor(worker: Worker) {
    this.worker = worker
    this.resolverMap = new Map()
    this.idCounter = 0
    this.worker.addEventListener('message', this.onMessage.bind(this))
  }
  private onMessage(event: MessageEvent<WorkerResponse>) {
    const { id, result } = event.data
    const resolve = this.resolverMap.get(id)
    if (resolve) {
      resolve(result)
      this.resolverMap.delete(id)
    }
  }
  runTask<T = unknown, R = unknown>(action: string, payload?: T): Promise<R> {
    const id = this.idCounter++
    const message: WorkerRequest<T> = { id, action, payload }
    return new Promise((resolve) => {
      this.resolverMap.set(id, resolve)
      this.worker.postMessage(message)
    })
  }
}
