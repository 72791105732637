<template>
  <button
    class="flex gap-2 items-center"
    :class="{ 'cursor-not-allowed': disabled }"
    @click.stop="disabled ? '' : (model = !model)"
  >
    <div
      class="inline-flex rounded-full relative transition-colors duration-200 ease-in-out border-2 border-transparent focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
      :class="[
        model ? 'bg-green-600' : ' bg-light-neutral-600',
        {
          'w-11 h-6': size === '',
          'w-14 h-8': size === 'lg',
          'bg-gray-300 cursor-not-allowed': disabled,
        },
      ]"
    >
      <div
        class="absolute flex items-center rounded-full bg-white transition duration-200 ease-in-out shadow-sm pointer-events-none"
        :class="[
          model ? 'translate-x-full' : 'translate-x-0',
          {
            'w-5 h-5': size === '',
            'w-6 h-6 left-1': size === 'lg',
            'opacity-50': disabled,
          },
        ]"
      />
    </div>
    <slot />
  </button>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    size?: 'lg' | ''
    disabled?: boolean
  }>(),
  {
    size: '',
  },
)

const model = defineModel<boolean>()
</script>
