import { ReviewPreset } from '../types/review-preset.type'
import { ReviewPresetEntry } from '../types/reviewPresetEntry'

class MedicalBackgroundPreset {
  preset: ReviewPreset.MEDICAL_BACKGROUND
  problem: string
  purpose: string
  problemName: string

  constructor(data: { problem: string; purpose: string; problemName: string }) {
    this.preset = ReviewPreset.MEDICAL_BACKGROUND
    this.problem = data.problem
    this.purpose = data.purpose
    this.problemName = data.problemName
  }
}

class GenericDeviceGroupPreset {
  preset: ReviewPreset.GENERIC_DEVICE_GROUP
  alternativeName: string

  constructor(data: { alternativeName: string }) {
    this.preset = ReviewPreset.GENERIC_DEVICE_GROUP
    this.alternativeName = data.alternativeName
  }
}

class MedicalAlternativePreset {
  preset: ReviewPreset.MEDICAL_ALTERNATIVE
  alternativeName: string

  constructor(data: { alternativeName: string }) {
    this.preset = ReviewPreset.MEDICAL_ALTERNATIVE
    this.alternativeName = data.alternativeName
  }
}

class DeviceSpecificSearchPreset {
  preset: ReviewPreset.DEVICE_SPECIFIC_SEARCH
  deviceName: string
  manufacturerName: string
  alternativeDeviceNames: string[]
  alternativeManufacturerNames: string[]

  constructor(data: {
    deviceName: string
    manufacturerName: string
    alternativeDeviceNames: string[]
    alternativeManufacturerNames: string[]
  }) {
    this.preset = ReviewPreset.DEVICE_SPECIFIC_SEARCH
    this.deviceName = data.deviceName
    this.manufacturerName = data.manufacturerName
    this.alternativeDeviceNames = data.alternativeDeviceNames
    this.alternativeManufacturerNames = data.alternativeManufacturerNames
  }
}

class DefaultPreset {
  preset: ReviewPreset.DEFAULT

  constructor() {
    this.preset = ReviewPreset.DEFAULT
  }
}

export class ReviewPresetFactory {
  static createPreset(data: ReviewPresetEntry) {
    switch (data.preset) {
      case ReviewPreset.MEDICAL_BACKGROUND:
        return new MedicalBackgroundPreset({
          problem: data.problem,
          purpose: data.purpose,
          problemName: data.problemName,
        })
      case ReviewPreset.GENERIC_DEVICE_GROUP:
        return new GenericDeviceGroupPreset({
          alternativeName: data.alternativeName,
        })
      case ReviewPreset.MEDICAL_ALTERNATIVE:
        return new MedicalAlternativePreset({
          alternativeName: data.alternativeName,
        })
      case ReviewPreset.DEVICE_SPECIFIC_SEARCH:
        return new DeviceSpecificSearchPreset({
          deviceName: data.deviceName,
          manufacturerName: data.manufacturerName,
          alternativeDeviceNames: data.alternativeDeviceNames,
          alternativeManufacturerNames: data.alternativeManufacturerNames,
        })
      case ReviewPreset.DEFAULT:
      case ReviewPreset.CUSTOM:
      default:
        return new DefaultPreset()
    }
  }
}
