<template>
  <div v-if="isInExecuteStep">
    <teleport to="#toolbar">
      <Tabs
        v-model:tab="activeStep"
        class="p-4"
        :tabs="tabs"
        :style="2"
        @update:tab="(v) => toggleStep(v!)"
      >
        <template #[lockPlanTabSlotName]>
          <div
            v-if="
              !review.entity.value?.isLocked &&
              review.entity.value.plan?.lockState === ReviewLockState.LOCKED
            "
            v-tooltip="'Lock review'"
            class="flex items-center gap-2 bg-green-600 text-white px-3 py-[10px] text-xs rounded-sm font-medium"
          >
            <LockOpenIcon class="w-4 h-auto" />
          </div>
          <div
            v-else
            v-tooltip="'Unlock review'"
            class="flex items-center gap-2 bg-red-600 text-white px-3 py-[10px] text-xs rounded-sm font-medium"
          >
            <LockClosedIcon class="w-4 h-auto" />
          </div>
        </template>
      </Tabs>
      <div
        v-if="warningMessage"
        class="bg-yellow-100 mb-2 text-yellow-700 px-4 py-2 flex items-center gap-2 rounded-lg mx-6"
      >
        <InformationCircleIcon class="w-5 h-5" />
        {{ warningMessage }}
      </div>
      <Pagination
        v-model:page="currentPage"
        v-model:items-per-page="itemsPerPage"
        :total-items="totalItems"
      />
    </teleport>

    <div>
      <template v-if="review.entity.value?.studies?.length > 0">
        <div class="inline-grid pr-4" :style="gridStyle">
          <template v-if="shouldShowHeaders">
            <div
              class="bg-white border-t-[24px] border-t-white border-l-[24px] border-l-white sticky top-0 left-0 z-[51]"
            >
              <div
                class="rounded-tl-md bg-primary-500 justify-between border-r border-mid-neutral-50 text-white flex p-3.5 items-center h-full font-medium"
              >
                <div class="flex items-center gap-2">
                  <Sort />
                  <div>
                    <button
                      class="text-white block"
                      @click="isMetadataExpanded = !isMetadataExpanded"
                    >
                      <ArrowsPointingInIcon
                        v-if="isMetadataExpanded"
                        class="h-5"
                      />
                      <ArrowsPointingOutIcon v-else class="h-5" />
                    </button>
                  </div>
                </div>
                <div class="flex items-center gap-2">
                  <button
                    v-if="review.displayOptions.isScreeningStageActive.value"
                    v-tooltip="'Toggle showing only favourites'"
                    class="block"
                    @click.stop="toggleFavoriteFilter"
                  >
                    <StarIcon
                      class="w-6 h-6 stroke-2"
                      :class="[
                        showOnlyFavourites
                          ? 'fill-amber-400 stroke-amber-400'
                          : 'fill-transparent stroke-[#A8B0B9] hover:fill-amber-400/80 hover:stroke-amber-400/80',
                      ]"
                    />
                  </button>
                  <SearchAndHighlight />
                </div>
              </div>
            </div>
            <template v-if="review.displayOptions.isScreeningStageActive.value">
              <div
                class="box-border sticky top-0 left-0 z-40 border-t-[24px] border-t-white"
              >
                <div
                  class="bg-primary-500 rounded-tr-md border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium justify-between"
                >
                  Screening
                  <button class="relative" @click="toggleChart">
                    <div
                      v-if="review.filtering.hasFiltersApplied.value"
                      class="absolute"
                    >
                      <span class="relative flex h-2 w-2">
                        <span
                          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
                        ></span>
                        <span
                          class="relative inline-flex rounded-full h-2 w-2 bg-red-500"
                        ></span>
                      </span>
                    </div>
                    <ChartPieIcon class="w-5 h-auto" />
                  </button>
                </div>
                <div
                  class="bg-white -top-4 -right-10 w-10 h-[calc(100%+16px)] absolute"
                ></div>
              </div>
            </template>
            <template
              v-if="review.displayOptions.isStudyDesignStageActive.value"
            >
              <div
                class="sticky top-0 left-0 z-40 border-t-[24px] border-t-white"
              >
                <div
                  class="bg-primary-500 justify-between border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
                >
                  Study characteristis
                </div>
              </div>
            </template>
            <template v-if="review.displayOptions.isAppraisalStageActive.value">
              <div
                v-if="
                  review.entity.value?.plan?.appraisalPlan
                    .isImdrfMdce2019Applicable
                "
                class="sticky top-0 left-0 z-40 border-t-[24px] border-t-white"
              >
                <div
                  class="bg-primary-500 border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
                >
                  IMDRF MDCE 2019
                </div>
              </div>
              <div
                v-if="
                  review.entity.value?.plan?.appraisalPlan
                    .isOxfordLevelOfEvidenceApplicable
                "
                class="sticky top-0 left-0 z-40 border-t-[24px] border-t-white"
              >
                <div
                  class="bg-primary-500 border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
                >
                  Oxford LOE
                </div>
              </div>
              <div
                v-if="
                  review.entity.value?.plan?.appraisalPlan
                    .isPeerReviewStatusApplicable
                "
                class="sticky top-0 left-0 z-40 border-t-[24px] border-t-white"
              >
                <div
                  class="bg-primary-500 rounded-tr-md border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
                >
                  Peer review status
                </div>
              </div>
            </template>
            <template v-if="review.displayOptions.isSynthesisStageActive.value">
              <div
                v-for="(attributeStructure, attributeStructureIndex) in review
                  .sortedAttributes.value"
                :key="attributeStructure.id"
                class="sticky top-0 left-0 z-40 border-t-[24px] border-t-white"
              >
                <div
                  v-tooltip="
                    attributeStructure.label +
                    '<br/>' +
                    attributeStructure.question
                  "
                  class="bg-primary-500 justify-between border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
                  :class="{
                    'rounded-tr-md':
                      attributeStructureIndex ===
                      review.sortedAttributes.value.length - 1,
                  }"
                >
                  <div class="truncate">{{ attributeStructure.label }}</div>
                </div>
              </div>
            </template>
          </template>
          <template v-if="review.displayOptions.isScreeningStageActive.value">
            <StudyCard
              v-for="study in paginatedReviewItems"
              :key="study.id"
              :study="study"
              :screening-stage="
                review.displayOptions.isScreeningStageActive.value
              "
              :synthesis-stage="
                review.displayOptions.isSynthesisStageActive.value
              "
              :appraisal-stage="
                review.displayOptions.isAppraisalStageActive.value
              "
              :study-design-stage="
                review.displayOptions.isStudyDesignStageActive.value
              "
              :is-metadata-expanded="isMetadataExpanded"
            />
          </template>
          <template v-else>
            <StudyCard
              v-for="study in paginatedIncludedReviewItems"
              :key="study.id"
              :study="study"
              :screening-stage="
                review.displayOptions.isScreeningStageActive.value
              "
              :synthesis-stage="
                review.displayOptions.isSynthesisStageActive.value
              "
              :appraisal-stage="
                review.displayOptions.isAppraisalStageActive.value
              "
              :study-design-stage="
                review.displayOptions.isStudyDesignStageActive.value
              "
              :is-metadata-expanded="isMetadataExpanded"
            />
          </template>
        </div>
        <Synthesis
          v-if="isSynthesisStageActiveWithIncludedStudies() && isCuttingEdge"
          :grid-style="gridStyle"
        />
      </template>
      <div v-else class="p-6 inline-flex flex-col">This review is empty</div>
      <ScreeningChart
        :model-value="shouldShowChart"
        @update:model-value="setChartShown"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import StudyCard from '@app/views/Review/Execute/Studies/Card/StudyCard.vue'
import {
  computed,
  onActivated,
  onDeactivated,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'
import Sort from '@app/views/Review/Execute/Sort/Sort.vue'
import SearchAndHighlight from './SearchAndFilters/SearchAndHighlight.vue'
import {
  ArrowsPointingOutIcon,
  ChartPieIcon,
  StarIcon,
  ArrowsPointingInIcon,
} from '@heroicons/vue/24/outline'
import { SnackbarState, StudyState } from '@app/types'
import ScreeningChart from './ScreeningChart.vue'
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { DevToolboxKey } from '@app/injectionKeys'
import {
  InformationCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/vue/24/solid'
import { ExecuteStep } from '../review.types'
import { useStorage } from '@vueuse/core'
import Synthesis from './Synthesis/Synthesis.vue'
import Pagination from '@app/components/Global/Pagination/Pagination.vue'
import { useRoute, useRouter } from 'vue-router'
const shouldShowNoArticlesIncludedMessage = computed(() => {
  return (
    review.includedReviewItems.value.length <= 0 &&
    !review.displayOptions.isScreeningStageActive.value
  )
})

const warningMessage = computed(() => {
  if (review.isArchived.value)
    return "The review is archived, you can't make changes."
  if (shouldShowNoArticlesIncludedMessage.value)
    return 'No articles have been included.'
  if (
    review.isLocked.value ||
    review.entity.value.plan?.lockState === ReviewLockState.UNLOCKED
  )
    return 'The review is locked, unlock it to make changes.'
  return ''
})
const review = injectStrict(ReviewKey)
const route = useRoute()
const router = useRouter()

const currentPage = ref(Number(route.query.page) || 1)
const itemsPerPageStorage = useStorage('review-items-per-page', 10)
const itemsPerPage = ref(
  Number(route.query.itemsPerPage) || itemsPerPageStorage.value,
)
const totalItems = computed(() =>
  review.displayOptions.isScreeningStageActive.value
    ? review.filteredReviewItems.value.length
    : review.filteredIncludedReviewItems.value.length,
)

watch(
  currentPage,
  (newPage) => {
    router.replace({
      query: {
        ...route.query,
        page: newPage.toString(),
        itemsPerPage: itemsPerPage.value.toString(),
      },
    })
  },
  {
    immediate: true,
  },
)

watch(
  itemsPerPage,
  (newItemsPerPage) => {
    itemsPerPageStorage.value = newItemsPerPage
    router.replace({
      query: {
        ...route.query,
        page: currentPage.value.toString(),
        itemsPerPage: newItemsPerPage.toString(),
      },
    })
  },
  {
    immediate: true,
  },
)

watch(route, (newRoute) => {
  if (newRoute.query.page) {
    currentPage.value = Number(newRoute.query.page)
  }
  if (newRoute.query.itemsPerPage) {
    itemsPerPage.value = Number(newRoute.query.itemsPerPage)
  }
})

const paginatedReviewItems = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value
  return review.filteredReviewItems.value.slice(
    start,
    start + itemsPerPage.value,
  )
})

const paginatedIncludedReviewItems = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value
  return review.filteredIncludedReviewItems.value.slice(
    start,
    start + itemsPerPage.value,
  )
})

const { isCuttingEdge } = injectStrict(DevToolboxKey)
const activeStep = ref<ExecuteStep>(ExecuteStep.screening)

const lockPlanTabSlotName = `tab-${ExecuteStep.reviewLock}`
const tabs = [
  {
    number: ExecuteStep.screening,
    title: 'Screening',
  },

  ...(isCuttingEdge
    ? [
        {
          number: ExecuteStep.studyDesign,
          title: 'Study design',
        },
      ]
    : []),

  ...(review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable ||
  review.entity.value?.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable ||
  review.entity.value?.plan?.appraisalPlan.isPeerReviewStatusApplicable
    ? [
        {
          number: ExecuteStep.appraisal,
          title: 'Appraisal',
        },
      ]
    : []),
  {
    number: ExecuteStep.synthesis,
    title: 'Extraction',
  },
  {
    number: ExecuteStep.reviewLock,
    isDisabled: review.isArchived.value,
    handler: async () => {
      await toggleReviewLock()
    },
  },
]

const loading = useLoading()
const snackbar = useSnackbar()

watch(activeStep, (v) => {
  toggleStep(v)
})

onUnmounted(() => {
  toggleStep(ExecuteStep.screening)
})

function toggleStep(step: ExecuteStep) {
  switch (step) {
    case ExecuteStep.screening:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.screening)
      break
    case ExecuteStep.appraisal:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.appraisal)
      break
    case ExecuteStep.synthesis:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.synthesis)
      break
    case ExecuteStep.studyDesign:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.studyDesign)
      break
    case ExecuteStep.reviewLock:
      break
  }
}

const showOnlyFavourites = computed(() => {
  const filters = review.filtering.filters.value ?? {}
  return Object.keys(filters).every(
    (k) =>
      (k === StudyState.FAVOURITE && filters[k] === true) ||
      (k !== StudyState.FAVOURITE && filters[k as StudyState] === false),
  )
})
function toggleFavoriteFilter() {
  if (!showOnlyFavourites.value)
    review.filtering.filterStudiesByState(StudyState.FAVOURITE)
  else review.filtering.resetFilters()
}

const isMetadataExpanded = useStorage(
  'isMetadataExpanded-' + review.entity.value.id,
  true,
)

const gridStyle = computed(() => {
  let style = `grid-template-columns:${isMetadataExpanded.value ? 600 : 400}px`
  if (review.displayOptions.isScreeningStageActive.value)
    style += ' max-content'
  if (review.displayOptions.isStudyDesignStageActive.value) style += ' 700px'
  if (review.displayOptions.isAppraisalStageActive.value) {
    if (review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable) {
      style += ' max-content'
    }
    if (
      review.entity.value?.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable
    ) {
      style += ' max-content'
    }
    if (review.entity.value?.plan?.appraisalPlan.isPeerReviewStatusApplicable) {
      style += ' max-content'
    }
  }

  if (review.displayOptions.isSynthesisStageActive.value) {
    review.sortedAttributes.value.forEach(() => {
      style += ' 400px'
    })
  }
  return style
})

const isChartShown = ref(true)

const shouldShowChart = computed(() => {
  return (
    isChartShown.value &&
    review.displayOptions.isScreeningStageActive.value &&
    review.entity.value?.studies?.length > 0 &&
    isInExecuteStep.value
  )
})

function toggleChart() {
  isChartShown.value = !isChartShown.value
}

function setChartShown(value: boolean | undefined) {
  isChartShown.value = !!value
}

const shouldShowHeaders = computed(() => {
  return (
    isScreeningStageActiveWithStudies() ||
    isAppraisalStageActiveWithIncludedStudies() ||
    isSynthesisStageActiveWithIncludedStudies() ||
    isStudyDesignStageActiveWithIncludedStudies()
  )
})

function isAppraisalStageActiveWithIncludedStudies() {
  return (
    review.displayOptions.isAppraisalStageActive.value &&
    review.includedReviewItems.value.length > 0
  )
}

function isSynthesisStageActiveWithIncludedStudies() {
  return (
    review.displayOptions.isSynthesisStageActive.value &&
    review.includedReviewItems.value.length > 0
  )
}

function isStudyDesignStageActiveWithIncludedStudies() {
  return (
    review.displayOptions.isStudyDesignStageActive.value &&
    review.includedReviewItems.value.length > 0
  )
}

function isScreeningStageActiveWithStudies() {
  return (
    review.displayOptions.isScreeningStageActive.value &&
    review.entity.value?.studies?.length > 0
  )
}

async function toggleReviewLock() {
  loading.start()
  try {
    if (review.entity.value.isLocked) {
      await unlockReview()
      if (review.entity.value.plan?.lockState === ReviewLockState.UNLOCKED) {
        await lockPlan()
      }
    } else {
      if (review.entity.value.plan?.lockState === ReviewLockState.UNLOCKED) {
        await lockPlan()
      } else {
        await lockReview()
      }
    }
  } finally {
    loading.stop()
  }
}

async function unlockReview() {
  try {
    await review.unlockReview(review.entity.value?.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while unlocking review, please try again.',
      )
    }
    throw e
  }
}

async function lockPlan() {
  try {
    await review.lockPlan(review.entity.value.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while locking review plan, please try again.',
      )
    }
    throw e
  }
}

async function lockReview() {
  try {
    await review.lockReview(review.entity.value?.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while locking review, please try again.',
      )
    }
    throw e
  }
}

const isInExecuteStep = ref(true)
onDeactivated(() => {
  isInExecuteStep.value = false
})

onActivated(() => {
  isInExecuteStep.value = true
})

watch(activeStep, () => {
  currentPage.value = 1
})

watch(showOnlyFavourites, () => {
  currentPage.value = 1
})

watch(
  () => review.filtering.terms.value,
  () => {
    currentPage.value = 1
  },
)

watch(
  () => review.filtering.filters.value,
  () => {
    currentPage.value = 1
  },
  { deep: true },
)

watch(itemsPerPage, () => {
  currentPage.value = 1
})
</script>
