<template>
  <div class="flex flex-col p-6 space-y-0 container">
    <div class="mb-4 text-blue-700 font-semibold tracking-[-0.14px] text-base">
      Specify the objective
    </div>
    <div class="space-y-8">
      <TextInput
        ref="scopeSummary"
        v-model="summary"
        :placeholder="`Summarize the objective`"
        type="text"
        :multiline="true"
        input-classes="min-w-[653px] min-h-[110px] max-w-[930px]  focus:ring-1 focus:ring-inset resize text-sm border-slate-300 shadow"
        :disabled="review.isPlanReadonly.value"
        @enter="(v) => updateSynthesisPlanSummary(v)"
        @blur="(v) => updateSynthesisPlanSummary(v)"
      />

      <AttributesStructureBuilder />
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'

import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import AttributesStructureBuilder from './AttributeStructureBuilder/AttributesStructureBuilder.vue'
import { ref } from 'vue'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
const snackbar = useSnackbar()
const loading = useLoading()
const review = injectStrict(ReviewKey)
const summary = ref(review.entity.value.plan?.synthesisPlan.summary)

async function updateSynthesisPlanSummary(newSummary: string) {
  try {
    loading.start()
    await review.updateSynthesisPlanSummary(newSummary)

    snackbar.show(SnackbarState.SUCCESS, 'Scope summary updated successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
