<template>
  <div class="space-y-6 border border-slate-300 rounded-md p-6">
    <h3
      class="tracking-[-0.14px] text-base text-blue-700 font-semibold flex items-center gap-2"
    >
      Exclusion criteria
    </h3>
    <div class="space-y-4">
      <div class="space-y-4">
        <div
          v-if="
            !review.isPlanReadonly.value ||
            titleAndAbstractScreeingCriteria.length > 0
          "
          class="flex justify-between"
        >
          <div class="flex gap-2 flex-col">
            <h3 class="text-base font-medium text-gray-700">
              Screening on title and abstract
            </h3>
            <div>
              <p class="text-slate-500 text-sm font-[400]">
                Screen studies based on titles and abstracts. Use this step to
                narrow down results before full-text screening.
              </p>
            </div>
          </div>
          <Switch
            :disabled="review.isPlanReadonly.value"
            :model-value="isTitleAndAbstractScreeingCriteriaEnabled"
            :class="[
              isTitleAndAbstractScreeingCriteriaEnabled
                ? 'bg-green-600'
                : 'bg-gray-200',
              'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden ',
            ]"
            @update:model-value="toggleTitleAndAbstractScreeingCriteria"
          >
            <span
              aria-hidden="true"
              :class="[
                isTitleAndAbstractScreeingCriteriaEnabled
                  ? 'translate-x-5'
                  : 'translate-x-0',
                'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
              ]"
            />
          </Switch>
        </div>

        <div class="flex flex-wrap gap-4 items-center">
          <div
            v-for="criterion in titleAndAbstractScreeingCriteria"
            :key="'title-and-abstract-criterion-' + criterion"
            class="flex items-center gap-1 px-4 py-2.5 text-sm font-medium rounded-full text-red-600 bg-red-50"
          >
            <span
              v-tooltip="criterion.length > 35 ? criterion : ''"
              class="truncate max-w-[252px]"
              >{{ criterion }}</span
            >
            <button
              v-if="!review.isPlanReadonly.value"
              @click="deleteTitleAndAbstractCriterion(criterion)"
            >
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>
          <FloatingTextInput
            v-if="
              !review.isPlanReadonly.value &&
              isTitleAndAbstractScreeingCriteriaEnabled
            "
            @save="addTitleAndAbstractCriterion($event)"
          >
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" />Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="text-base font-medium text-gray-700">
          Screening on full text
        </h3>

        <div class="flex flex-wrap gap-4 items-center">
          <div
            v-for="criterion in fullTextScreeingCriteria"
            :key="'full-text-criterion-' + criterion"
            class="flex items-center gap-1 px-4 py-2.5 text-sm font-medium rounded-full text-red-600 bg-red-50"
          >
            <span
              v-tooltip="criterion.length > 35 ? criterion : ''"
              class="truncate max-w-[252px]"
              >{{ criterion }}</span
            >
            <button
              v-if="!review.isPlanReadonly.value"
              @click="deleteFullTextCriterion(criterion)"
            >
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="addFullTextCriterion($event)"
          >
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" /> Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import { Switch } from '@headlessui/vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { HttpException } from '@core/exceptions/http.exception'
import { ref, watchEffect } from 'vue'
import PlusIcon from '@app/components/Icons/PlusIcon.vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'

const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)

const fullTextScreeingCriteria = ref<string[]>([])
const titleAndAbstractScreeingCriteria = ref<string[]>([])

const isTitleAndAbstractScreeingCriteriaEnabled = ref(
  (review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria?.length ??
    0) > 0,
)

watchEffect(() => {
  titleAndAbstractScreeingCriteria.value =
    review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria ?? []

  fullTextScreeingCriteria.value =
    review.entity.value?.plan?.screeningPlan?.fullTextCriteria ?? []
})

async function toggleTitleAndAbstractScreeingCriteria() {
  if (titleAndAbstractScreeingCriteria.value.length > 0) {
    loading.start()
    try {
      if (titleAndAbstractScreeingCriteria.value.length > 0) {
        await review.disableTitleAndAbstractScreening()
        snackbar.show(
          SnackbarState.SUCCESS,
          'Title and abstract screening disabled successfully',
        )
        isTitleAndAbstractScreeingCriteriaEnabled.value = false
      } else {
        await review.enableTitleAndAbstractScreening()
        snackbar.show(
          SnackbarState.SUCCESS,
          'Title and abstract screening enabled successfully',
        )
        isTitleAndAbstractScreeingCriteriaEnabled.value = true
      }
    } catch (e) {
      const error = e as HttpException
      if (
        error.response.data.statusCode >= 400 &&
        error.response.data.statusCode < 500
      ) {
        snackbar.show(SnackbarState.ERROR, error.response.data.message)
      } else {
        snackbar.show(
          SnackbarState.ERROR,
          'An error occured while enabling/disabling title and abstract screening, please try again.',
        )
      }
      throw e
    } finally {
      loading.stop()
    }
  } else {
    isTitleAndAbstractScreeingCriteriaEnabled.value =
      !isTitleAndAbstractScreeingCriteriaEnabled.value
    snackbar.show(
      SnackbarState.SUCCESS,
      `Title and abstract screening ${isTitleAndAbstractScreeingCriteriaEnabled.value ? 'enabled' : 'disabled'} successfully`,
    )
  }
}

async function addFullTextCriterion(criterion: string) {
  loading.start()
  try {
    await review.addFullTextCriterion(criterion)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Full text criterion added successfully',
    )
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while adding full text criterion, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function addTitleAndAbstractCriterion(criterion: string) {
  loading.start()
  try {
    await review.addTitleAndAbstractCriterion(criterion)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Title and abstract criterion added successfully',
    )
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while adding title and abstract  criterion, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteFullTextCriterion(criterion: string) {
  loading.start()
  try {
    await review.deletFullTextCriterion(criterion)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Full text criterion deleted successfully',
    )
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while deleting full text criterion, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteTitleAndAbstractCriterion(criterion: string) {
  loading.start()
  try {
    await review.deleteTitleAndAbstractCriterion(criterion)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Title and abstract criterion deleted successfully',
    )
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while deleting title and abstract criterion, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}
</script>
