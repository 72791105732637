<template>
  <div class="container px-6 my-6 space-y-4">
    <button
      class="px-3.5 py-2 bg-primary-500 text-white rounded-md hover:brightness-110 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:hover:brightness-100"
      :disabled="!isExtensionInstalled"
      @click="login"
    >
      Login extension
    </button>
    <div v-if="isExtensionAuthenticated" class="text-green-600">
      Extension is authenticated
    </div>
    <div
      v-else-if="!isExtensionAuthenticated && isExtensionInstalled"
      class="text-red-600"
    >
      Extension is not authenticated
    </div>
    <div v-else class="text-red-600">Extension is not installed</div>
  </div>
</template>
<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import useWebExtension from '@app/composables/useWebExtension'
import { AuthKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { onMounted } from 'vue'

const {
  loginExtension,
  isExtensionAuthenticated,
  checkExtensionAuthenticated,
  isExtensionInstalled,
} = useWebExtension()
const { getRecoveryLink } = injectStrict(AuthKey)

const snackbar = useSnackbar()
const loading = useLoading()
async function login() {
  loading.start()
  try {
    const recoveryLink = await getRecoveryLink()
    await loginExtension(recoveryLink)
    snackbar.success('Logged in extension')
  } catch (e) {
    snackbar.error('Failed to login extension')
    throw e
  } finally {
    loading.stop()
  }
}

onMounted(async () => {
  await checkExtensionAuthenticated()
})
</script>
