<template>
  <button
    v-if="isAssistantConfigured"
    v-tooltip="'Generate answer'"
    class="bg-white rounded-lg p-1"
    @click="generateAnswer"
  >
    <SparklesIcon v-if="!isGeneratingAnswer" class="w-5 h-5" />
    <span v-else class="loader w-5 h-5"></span>
  </button>

  <FloatingMenu v-else :offset="[0, 5]" placement="bottom" trigger="hover">
    <template #activator>
      <button class="bg-white rounded-lg p-1">
        <SparklesIcon v-if="!isGeneratingAnswer" class="w-5 h-5" />
        <span v-else class="loader w-5 h-5"></span>
      </button>
    </template>
    <template #content>
      <div class="w-64 p-1">
        The AI model is not configured. Please configure it in the
        <RouterLink to="/settings" class="text-primary underline"
          >settings</RouterLink
        >.
      </div>
    </template>
  </FloatingMenu>
</template>
<script setup lang="ts">
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { ref } from 'vue'
import { ReviewKey } from '../../../use-review'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import useAiAssistant from '@app/composables/useAiAssistant'
import { SparklesIcon } from '@heroicons/vue/24/outline'
const props = withDefaults(
  defineProps<{
    study: ReviewItem
    attributeStructure: AttributeStructure
    query: string
  }>(),
  {
    query: '',
  },
)

const snackbar = useSnackbar()
const emit = defineEmits<(e: 'answerGenerated', answer: string) => void>()
const { isConfigured: isAssistantConfigured } = useAiAssistant()

const isGeneratingAnswer = ref(false)

const review = injectStrict(ReviewKey)
const loading = useLoading()

async function generateAnswer() {
  const question = props.attributeStructure.question
  if (!question) {
    return
  }
  loading.start()
  isGeneratingAnswer.value = true
  try {
    const generatedAnswer = await review.answerStudyQuestion(
      props.study.id,
      question,
    )

    emit('answerGenerated', generatedAnswer.replace(/【.*?】/g, ''))
    snackbar.success('Answer generated successfully.')
  } catch (_) {
    snackbar.error('The AI model timed out. Please try again.')
  } finally {
    loading.stop()
    isGeneratingAnswer.value = false
  }
}
</script>
