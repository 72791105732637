<template>
  <Modal ref="modal">
    <template #activator="{ show }">
      <button
        class="bg-amber-100 text-amber-800 px-5 py-2 font-medium rounded-full flex items-center gap-1"
        @click="areAllStudiesProcessed ? exportPrismaDiagram() : show()"
      >
        <PrismaIcon class="w-5" /> PRISMA 2021<ArrowDownTrayIcon class="w-4" />
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        PRISMA 2021
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content>
      <p v-if="!areAllStudiesProcessed" class="text-sm text-gray-500">
        Some studies are missing their appraisal or screening, are you sure you
        would like to proceed with the export?
      </p>
    </template>

    <template #footer="{ hide }">
      <div class="sm:flex sm:flex-row-reverse gap-2">
        <button
          type="button"
          class="inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-primary-700 sm:w-auto"
          @click=";[hide(), exportPrismaDiagram()]"
        >
          Proceed
        </button>
        <button
          type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          @click="hide"
        >
          Cancel
        </button>
      </div>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import Modal from '@app/components/Global/Modal/Modal.vue'
import { ref, nextTick } from 'vue'
import { toPng } from 'html-to-image'
import ArrowDownTrayIcon from '@heroicons/vue/24/outline/ArrowDownTrayIcon'
import { ReviewKey } from '../use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import useExport from './useExport'
import { injectStrict } from '@app/utils/injectStrict'
import PrismaIcon from '@app/components/Icons/PrismaIcon.vue'
import XIcon from '@app/components/Icons/XIcon.vue'

const loading = useLoading()
const snackbar = useSnackbar()
const review = injectStrict(ReviewKey)

const { areAllStudiesProcessed } = useExport(review.entity.value)

const modal = ref<InstanceType<typeof Modal>>()

const isProcessing = ref(false)

const exportPrismaDiagram = async () => {
  loading.start()

  if (isProcessing.value) return
  isProcessing.value = true

  try {
    await generatePrismaImage()
    snackbar.success('PRISMA diagram exported successfully')
  } catch (error) {
    snackbar.error('Failed to export PRISMA diagram')
    throw error
  } finally {
    isProcessing.value = false
    loading.stop()
  }
}

const generatePrismaImage = async () => {
  if (!review) {
    throw new Error('Review context not available')
  }

  const tempDiv = document.createElement('div')
  tempDiv.style.position = 'fixed'
  tempDiv.style.left = '-9999px'
  tempDiv.style.top = '-9999px'
  tempDiv.style.width = 'auto'
  document.body.appendChild(tempDiv)

  try {
    const { createApp } = await import('vue')
    const { default: PrismaHtmlComponent } = await import(
      './utils/PrismaHtml.vue'
    )

    const app = createApp(PrismaHtmlComponent)

    app.provide(ReviewKey, review)

    app.mount(tempDiv)

    await nextTick()
    await new Promise((resolve) => setTimeout(resolve, 500))

    const contentElement = tempDiv.firstChild as HTMLElement
    if (!contentElement) {
      throw new Error('PRISMA content failed to render')
    }

    await waitForImagesToLoad(contentElement)

    const dataUrl = await toPng(contentElement, {
      width: contentElement.scrollWidth,
      height: contentElement.scrollHeight,
      backgroundColor: '#ffffff',
      pixelRatio: 2,
      cacheBust: true,
    })

    downloadImage(dataUrl, review.entity.value?.name)
  } finally {
    document.body.removeChild(tempDiv)
  }
}

const waitForImagesToLoad = async (element: HTMLElement) => {
  // Wait for images
  await Promise.all(
    Array.from(element.querySelectorAll('img')).map((img) =>
      img.complete
        ? Promise.resolve()
        : new Promise((resolve) => {
            img.onload = resolve
            img.onerror = resolve
          }),
    ),
  )
  // Additional delay for other async content
  await new Promise((resolve) => setTimeout(resolve, 200))
}

const downloadImage = (dataUrl: string, reviewName: string) => {
  const link = document.createElement('a')
  link.href = dataUrl
  link.download = reviewName + '.png'
  document.body.appendChild(link)
  link.click()
  setTimeout(() => document.body.removeChild(link), 100)
}
</script>
