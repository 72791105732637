import { Article } from '@core/domain/models/article.model'
import { FieldSafetyNotice } from '@core/domain/models/fieldSafetyNotice.model'
import { Guidance } from '@core/domain/models/guidance.model'
import { Incident } from '@core/domain/models/incident.model'
import { Registration } from '@core/domain/models/registration.model'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'

export function doesReviewItemContainSearchTerms({
  reviewItem,
  terms: searchTerms = [],
  ignoreAbstract = false,
}: {
  reviewItem: ReviewItem
  terms?: string[]
  ignoreAbstract?: boolean
}): boolean {
  switch (reviewItem.type) {
    case ReviewItemType.Article:
      return doesArticleContainSearchTerms(
        reviewItem as Article,
        searchTerms,
        ignoreAbstract,
      )
    case ReviewItemType.Registration:
      return doesRegistrationContainSearchTerms(
        reviewItem as Registration,
        searchTerms,
      )
    case ReviewItemType.Incident:
      return doesIncidentContainSearchTerms(reviewItem as Incident, searchTerms)
    case ReviewItemType.FieldSafetyNotice:
      return doesFieldSafetyNoticeContainSearchTerms(
        reviewItem as FieldSafetyNotice,
        searchTerms,
      )
    case ReviewItemType.Guidance:
      return doesGuidanceContainSearchTerms(reviewItem as Guidance, searchTerms)
    case ReviewItemType.default:
    default:
      return true
  }
}

function doesArticleContainSearchTerms(
  article: Article,
  searchTerms: string[],
  ignoreAbstract: boolean = false,
): boolean {
  if (searchTerms.length <= 0) return true
  const searchTagsRegexString =
    searchTerms.map((st) => `(?=.*${st})`).join('') + '.*'
  const searchRegex = new RegExp(`${searchTagsRegexString}`, 'i')

  const searchableString =
    article.metadata?.title?.toLocaleLowerCase() +
    ' ' +
    (!ignoreAbstract ? article.metadata?.abstract?.toLocaleLowerCase() : ' ') +
    article.id.toString() +
    ' ' +
    article.metadata?.publishYear?.toString() +
    ' ' +
    (article.metadata?.authors &&
      article.metadata?.authors.length > 0 &&
      article.metadata?.authors.join(' ').toLocaleLowerCase())
  return searchRegex.test(searchableString)
}

function doesRegistrationContainSearchTerms(
  registration: Registration,
  searchTerms: string[],
): boolean {
  if (searchTerms.length <= 0) return true
  const searchTagsRegexString =
    searchTerms.map((st) => `(?=.*${st})`).join('') + '.*'
  const searchRegex = new RegExp(`${searchTagsRegexString}`, 'i')

  const searchableString =
    registration.metadata?.title?.toLocaleLowerCase() +
    ' ' +
    registration.metadata?.registrationId?.toLocaleLowerCase() +
    registration.id.toString()
  return searchRegex.test(searchableString)
}

function doesIncidentContainSearchTerms(
  incident: Incident,
  searchTerms: string[],
): boolean {
  if (searchTerms.length <= 0) return true
  const searchTagsRegexString =
    searchTerms.map((st) => `(?=.*${st})`).join('') + '.*'
  const searchRegex = new RegExp(`${searchTagsRegexString}`, 'i')

  const searchableString =
    incident.metadata?.title?.toLocaleLowerCase() +
    ' ' +
    incident.metadata?.reportNumber?.toLocaleLowerCase() +
    incident.id.toString() +
    ' ' +
    incident.metadata?.summary?.toLocaleLowerCase()
  return searchRegex.test(searchableString)
}

function doesFieldSafetyNoticeContainSearchTerms(
  fieldSafetyNotice: FieldSafetyNotice,
  searchTerms: string[],
): boolean {
  if (searchTerms.length <= 0) return true
  const searchTagsRegexString =
    searchTerms.map((st) => `(?=.*${st})`).join('') + '.*'
  const searchRegex = new RegExp(`${searchTagsRegexString}`, 'i')

  const searchableString =
    fieldSafetyNotice.metadata?.title?.toLocaleLowerCase() +
    ' ' +
    fieldSafetyNotice.metadata?.summary?.toLocaleLowerCase() +
    fieldSafetyNotice.id.toString() +
    ' ' +
    fieldSafetyNotice.metadata?.fsnType?.toLocaleLowerCase() +
    ' ' +
    fieldSafetyNotice.metadata?.source?.toLocaleLowerCase()
  return searchRegex.test(searchableString)
}

function doesGuidanceContainSearchTerms(
  guidance: Guidance,
  searchTerms: string[],
): boolean {
  if (searchTerms.length <= 0) return true
  const searchTagsRegexString =
    searchTerms.map((st) => `(?=.*${st})`).join('') + '.*'
  const searchRegex = new RegExp(`${searchTagsRegexString}`, 'i')

  const searchableString =
    guidance.metadata?.title?.toLocaleLowerCase() +
    ' ' +
    guidance.metadata?.reference?.toLocaleLowerCase() +
    guidance.id.toString() +
    ' ' +
    guidance.metadata?.publishYear?.toLocaleLowerCase()
  return searchRegex.test(searchableString)
}
