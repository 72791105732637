import { RegistrationMetadata } from '@core/domain/models/reviewItemMetadata/registrationMetadata'
import { parseRISText } from '@core/utils/parse-studies-file'
import axios from 'axios'

export class ProsperoApiClient {
  async searchArticles(query: string): Promise<RegistrationMetadata[]> {
    try {
      const lineID = await this.getLineID(query)
      const articles = await this.getRegistrationsMetadata(lineID)
      return articles
    } catch (error) {
      console.error('Failed to get articles', error)
      throw new Error('Failed to get articles')
    }
  }

  private async getLineID(query: string): Promise<string> {
    try {
      const response = await axios.post(
        '/prospero-proxy/prospero/searchfor.php',
        { SearchFor: query },
        {
          headers: {
            // Explicitly set content type
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          timeout: 30000,
        },
      )
      const lineID = response.data.split(' ')[0].trim()
      return lineID
    } catch (error: any) {
      console.error('Failed to search for lineID', error)
      throw new Error('Failed to search for lineID')
    }
  }

  private async getRegistrationsMetadata(
    lineID: string,
  ): Promise<RegistrationMetadata[]> {
    try {
      const response = await axios.get(
        `/prospero-proxy/prospero/export.php?lineID=${lineID}&type=RIS`,
      )
      const articles = parseRISText(response.data)

      return articles.map((article) => {
        return new RegistrationMetadata({
          title: article.title,
          url: article.url,
          registrationId: article.registrationId,
          registrationDate: article.registrationDate,
        })
      })
    } catch (error: any) {
      console.error('Failed to get registrations metadata', error)
      throw new Error('Failed to get registrations metadata')
    }
  }
}
