<template>
  <div id="modal" />

  <div id="app-wrapper">
    <Sidebar :size="sidebarSize" />

    <div id="toolbar" class="z-[51] bg-white" />
    <Navbar />

    <main
      id="main"
      :class="[
        modalManager.modals.value.some((m) => m.isVisible)
          ? 'overflow-hidden'
          : 'overflow-auto',
      ]"
    >
      <slot />
    </main>

    <div id="drawer" class="shadow-lg overflow-y-auto" />
  </div>

  <div
    id="off-screen"
    class="overflow-hidden absolute top-0 left-0 -z-50"
  ></div>
</template>

<script lang="ts" setup>
import { ref, onMounted, provide, computed } from 'vue'
import Sidebar from './SideBar.vue'
import Navbar from './Navbar.vue'
import useModalManager from '@app/components/Global/Modal/modal-manager'
import useAiAssistant from '@app/composables/useAiAssistant'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'

const { init } = useAiAssistant()
const auth = injectStrict(AuthKey)
if (auth.user.value?.email) init()
const modalManager = useModalManager()
const isDarkMode = ref(false)

const isLarge = ref(true)

const sidebarSize = computed(() => (isLarge.value ? 'large' : 'small'))

provide('sidebarSize', sidebarSize)

onMounted(() => {
  const activeTheme = localStorage.getItem('theme')
  if (!activeTheme || activeTheme === 'light') {
    document.body.classList.remove('dark')
    isDarkMode.value = false
  } else {
    document.body.classList.add('dark')
    isDarkMode.value = true
  }
})

provide('setSidebarSize', (size: 'large' | 'small') => {
  isLarge.value = size === 'large'
})
</script>
