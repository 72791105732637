<template>
  <div class="w-full h-full">
    <div class="w-full px-[24px] py-3 border-b-1 border-mid-neutral-50">
      <div class="flex items-center justify-between">
        <div class="grid grid-cols-1">
          <input
            id="search-projects"
            v-model="searchQuery"
            type="text"
            name="search-projects"
            class="col-start-1 row-start-1 block w-80 rounded-md bg-white py-2 pr-10 pl-3 text-base text-dark-neutral-500 outline-1 -outline-offset-1 outline-mid-neutral-50 placeholder:text-mid-neutral-200 focus:outline-2 focus:-outline-offset-2 focus:outline-primary-500 sm:pr-9 sm:text-sm/6"
            placeholder="Search For Projects"
          />
          <SearchIcon
            class="pointer-events-none col-start-1 row-start-1 mr-3 size-5 self-center justify-self-end text-gray-400 sm:size-4"
            aria-hidden="true"
          />
        </div>
        <CreateProject v-if="isAdmin" />
      </div>
    </div>

    <div v-if="projects.length > 0">
      <div v-if="filteredProjects.length > 0" class="flex flex-col">
        <ProjectTable
          v-for="project in filteredProjects.sort(
            (y, x) => Number(x.isFavorite) - Number(y.isFavorite),
          )"
          :key="project.id"
          :project="project"
          :users="users.users.value"
          :review="project.reviews?.[0]"
        />
      </div>
      <div v-else class="w-full flex items-center justify-center pt-10">
        <p class="text-dark-neutral-500">No projects matched your search.</p>
      </div>
    </div>
    <div
      v-else-if="!loading.isLoading.value"
      class="w-full flex items-center justify-center pt-28"
    >
      <div
        class="border-2 border-dashed bg-white border-slate-300 rounded-lg text-center px-80 py-32 space-y-2"
      >
        <CreateProjectIcon class="w-10 h-10 text-primary mx-auto" />
        <p class="pb-3">
          There are no projects yet, <br />start by creating one
        </p>
        <CreateProject v-if="isAdmin" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import useProjects from '@app/views/Projects/use-projects'
import useLoading from '@app/composables/use-loading'
import ProjectTable from './Project/ProjectTable.vue'
import CreateProjectIcon from '@app/components/Icons/CreateProjectIcon.vue'
import useUsers from '@app/composables/use-users'
import CreateProject from './CreateProject.vue'
import { Role } from '@core/domain/types/role.type'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import SearchIcon from '@app/components/Icons/SearchIcon.vue'

const loading = useLoading()
const { projects, refresh } = useProjects()
const searchQuery = ref('')

const users = useUsers()
users.refresh()
const { user } = injectStrict(AuthKey)
const isAdmin = computed(() => user.value?.role === Role.ADMIN)

const filteredProjects = computed(() => {
  return projects.value.filter((project) =>
    project.name.toLowerCase().includes(searchQuery.value.toLowerCase()),
  )
})

loading.start()
refresh().then(() => loading.stop())
</script>
