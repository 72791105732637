<template>
  <div
    class="flex items-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
  >
    <div class="flex flex-1 sm:hidden">
      <button
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        @click="prevPage"
      >
        Previous
      </button>
      <button
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        @click="nextPage"
      >
        Next
      </button>
    </div>
    <div class="flex items-center gap-4">
      <div>
        <nav
          class="isolate inline-flex -space-x-px rounded-md shadow-xs"
          aria-label="Pagination"
        >
          <button
            class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            :disabled="page === 1"
            :class="{ 'opacity-50 cursor-not-allowed': page === 1 }"
            title="First page"
            @click="goToPage(1)"
          >
            <span class="sr-only">First</span>
            <ChevronDoubleLeftIcon class="size-5" aria-hidden="true" />
          </button>
          <button
            class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            :disabled="page === 1"
            :class="{ 'opacity-50 cursor-not-allowed': page === 1 }"
            @click="prevPage"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="size-5" aria-hidden="true" />
          </button>

          <template v-for="(item, index) in paginationItems" :key="index">
            <button
              v-if="item !== '...'"
              :class="[
                'relative inline-flex items-center justify-center px-4 py-2 text-sm font-semibold w-12',
                {
                  'bg-primary-500 text-white': page === item,
                  'text-gray-900 ring-1 ring-gray-300 ring-inset hover:bg-gray-50':
                    page !== item,
                },
              ]"
              @click="goToPage(item as number)"
            >
              {{ item }}
            </button>
            <span
              v-else
              class="relative inline-flex items-center justify-center px-4 py-2 text-sm font-semibold w-12 text-gray-700 ring-1 ring-gray-300 ring-inset"
            >
              {{ item }}
            </span>
          </template>

          <button
            class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            :disabled="page === totalPages"
            :class="{ 'opacity-50 cursor-not-allowed': page === totalPages }"
            @click="nextPage"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="size-5" aria-hidden="true" />
          </button>
          <button
            class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-gray-300 ring-inset hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            :disabled="page === totalPages"
            :class="{ 'opacity-50 cursor-not-allowed': page === totalPages }"
            title="Last page"
            @click="goToPage(totalPages)"
          >
            <span class="sr-only">Last</span>
            <ChevronDoubleRightIcon class="size-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
      <div>
        <label for="itemsPerPage" class="text-sm text-gray-700"
          >Items per page:</label
        >
        <select
          id="itemsPerPage"
          :value="itemsPerPage"
          class="ml-2 border-gray-300 rounded-md"
          @change="
            (e) =>
              (itemsPerPage = Number((e.target as HTMLSelectElement).value))
          "
        >
          <option v-for="option in [5, 10, 20]" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ ' ' }}
          <span class="font-medium">{{ startItem }}</span>
          {{ ' ' }}
          to
          {{ ' ' }}
          <span class="font-medium">{{ endItem }}</span>
          {{ ' ' }}
          of
          {{ ' ' }}
          <span class="font-medium">{{ totalItems }}</span>
          {{ ' ' }}
          results
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/vue/24/outline'
import { computed } from 'vue'

const page = defineModel<number>('page', { default: 1 })
const itemsPerPage = defineModel<number>('itemsPerPage', { default: 5 })

const props = defineProps<{
  totalItems: number
}>()

const totalPages = computed(() =>
  Math.ceil(props.totalItems / itemsPerPage.value),
)

const startItem = computed(() => (page.value - 1) * itemsPerPage.value + 1)

const endItem = computed(() =>
  Math.min(page.value * itemsPerPage.value, props.totalItems),
)

const paginationItems = computed(() => {
  const items = []

  if (totalPages.value <= 7) {
    for (let i = 1; i <= totalPages.value; i++) {
      items.push(i)
    }
  } else {
    items.push(1)

    if (page.value <= 3) {
      items.push(2, 3, 4, 5)
      items.push('...')
      items.push(totalPages.value)
    } else if (page.value >= totalPages.value - 2) {
      items.push('...')
      items.push(
        totalPages.value - 4,
        totalPages.value - 3,
        totalPages.value - 2,
        totalPages.value - 1,
        totalPages.value,
      )
    } else {
      items.push('...')
      items.push(page.value - 1, page.value, page.value + 1)
      items.push('...')
      items.push(totalPages.value)
    }
  }

  return items
})

function prevPage() {
  if (page.value > 1) {
    page.value--
  }
}

function nextPage() {
  if (page.value < totalPages.value) {
    page.value++
  }
}

function goToPage(newPage: number) {
  page.value = newPage
}
</script>
