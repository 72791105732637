<template>
  <div class="space-y-6 border border-slate-300 rounded-md p-6">
    <h3
      class="tracking-[-0.14px] text-base text-blue-700 font-semibold flex items-center gap-2"
    >
      Exclusion criteria
    </h3>
    <div class="space-y-4">
      <div class="space-y-4">
        <div class="flex justify-between">
          <div class="flex gap-2 flex-col">
            <h3 class="text-base font-medium text-gray-700">
              Screening on title and abstract
            </h3>
            <div>
              <p class="text-slate-500 text-sm font-[400]">
                Screen studies based on titles and abstracts. Use this step to
                narrow down results before full-text screening.
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap gap-4 items-center">
          <div
            v-for="criterion in titleAndAbstractCriteria"
            :key="'title-and-abstract-criterion-' + criterion"
            class="flex items-center gap-1 px-4 py-2.5 text-sm font-medium rounded-full text-red-600 bg-red-50"
          >
            <span
              v-tooltip="criterion.length > 35 ? criterion : ''"
              class="truncate max-w-[252px]"
              >{{ criterion }}</span
            >
            <button @click="deleteTitleAndAbstractCriterion(criterion)">
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>
          <FloatingTextInput @save="addTitleAndAbstractCriterion($event)">
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" />Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="text-base font-medium text-gray-700">
          Screening on full text
        </h3>

        <div class="flex flex-wrap gap-4 items-center">
          <div
            v-for="criterion in fullTextCriteria"
            :key="'full-text-criterion-' + criterion"
            class="flex items-center gap-1 px-4 py-2.5 text-sm font-medium rounded-full text-red-600 bg-red-50"
          >
            <span
              v-tooltip="criterion.length > 35 ? criterion : ''"
              class="truncate max-w-[252px]"
              >{{ criterion }}</span
            >
            <button @click="deleteFullTextCriterion(criterion)">
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>
          <FloatingTextInput @save="addFullTextCriterion($event)">
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 pr-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" /> Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import { PlusIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue'

const titleAndAbstractCriteria = ref<string[]>([
  'Randomized controlled trial',
  'Systematic review',
])
const fullTextCriteria = ref<string[]>([
  'Randomized controlled trial',
  'Systematic review',
])

function addTitleAndAbstractCriterion(criterion: string) {
  titleAndAbstractCriteria.value.push(criterion)
}
function deleteTitleAndAbstractCriterion(criterion: string) {
  const index = titleAndAbstractCriteria.value.indexOf(criterion)
  if (index !== -1) {
    titleAndAbstractCriteria.value.splice(index, 1)
  }
}
function addFullTextCriterion(criterion: string) {
  fullTextCriteria.value.push(criterion)
}
function deleteFullTextCriterion(criterion: string) {
  const index = fullTextCriteria.value.indexOf(criterion)
  if (index !== -1) {
    fullTextCriteria.value.splice(index, 1)
  }
}
</script>
