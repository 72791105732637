<template>
  <FloatingMenu placement="bottom" :offset="[0, 10]">
    <template #activator="{ show }">
      <button
        v-tooltip="
          review.entity.value.studies.length === 0
            ? 'You need to import at least one study'
            : 'Sort'
        "
        class="block"
        :class="[
          review.entity.value.studies.length === 0 ? 'cursor-not-allowed' : '',
        ]"
        @click="review.entity.value.studies.length > 0 ? show() : ''"
      >
        <ArrowsUpDownIcon class="h-5" />
      </button>
    </template>
    <template #content>
      <div class="w-[232px] flex flex-col text-[#516A75] border">
        <button
          class="py-2 px-4 hover:bg-black/5 w-full text-left block"
          :class="{
            'bg-black/5  ': review.sorting.sort.value.by === SortBy.None,
          }"
          @click="review.sorting.sort.value.by = SortBy.None"
        >
          None
        </button>
        <button
          v-for="sortBy in [SortBy.Title, SortBy.Author, SortBy.Year]"
          :key="'sort' + sortBy"
          class="py-2 px-4 hover:bg-black/5 w-full flex justify-between"
          :class="{
            'bg-black/5 ': review.sorting.sort.value.by === sortBy,
          }"
          @click="review.sorting.updateSort(sortBy)"
        >
          <div>{{ SortBy[sortBy] }}</div>
          <div v-if="review.sorting.sort.value.by === sortBy">
            <BarsArrowDown
              v-if="review.sorting.sort.value.order === SortOrder.Descending"
              class="h-5.5 w-5.5"
            />
            <BarsArrowUp v-else class="h-5.5 w-5.5" />
          </div>
        </button>
      </div>
    </template>
  </FloatingMenu>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import BarsArrowUp from '@app/components/Icons/BarsArrowUp.vue'
import BarsArrowDown from '@app/components/Icons/BarsArrowDown.vue'
import { SortBy, SortOrder } from '@app/types'
import ArrowsUpDownIcon from '@app/components/Icons/ArrowsUpDownIcon.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../use-review'

const review = injectStrict(ReviewKey)
</script>
