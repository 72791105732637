<template>
  <ExpansionPanel
    class="border-gray-500 border-6"
    v-model="isDataExtractionPanelExpanded"
  >
    <template #activator="{ toggle }">
      <div class="flex items-center gap-2 bg-gray-500">
        <button
          @click="toggle"
          class="w-full text-white text-left px-2 py-2 flex items-center gap-2"
        >
          <ChevronDownIcon class="w-5" />
          Data extraction
        </button>
        <button
          class="text-white p-2 hover:text-slate-200"
          @click="toggleAllPanels"
        >
          <QueueListIcon class="w-5" />
        </button>
      </div>
    </template>
    <template #default>
      <ExpansionPanel
        v-if="review.id"
        class="border-b-1"
        v-model="isDataExtractionSettingsPanelExpanded"
      >
        <template #activator="{ toggle }">
          <div
            class="bg-slate-600 text-white flex justify-between items-center"
          >
            <button
              class="px-4 py-2 truncate w-full text-left flex gap-2 items-center"
              @click="toggle"
            >
              <ChevronDownIcon class="w-5" />
              Settings
            </button>
          </div>
        </template>
        <template #default>
          <div class="p-2 space-y-2">
            <div>
              <label class="text-sm text-primary">QA Prompt</label>
              <textarea
                v-model="qaPrompt"
                class="w-full border border-gray-300 h-30 px-2 py-1"
              />
            </div>
            <div>
              <label class="text-sm text-primary">Summary Prompt</label>
              <textarea
                v-model="summaryPrompt"
                class="w-full border border-gray-300 h-30 px-2 py-1"
              />
            </div>
            <div>
              <div class="flex items-center gap-2">
                <div
                  v-tooltip="
                    `What sampling temperature to use, between 0 and 2.
                     Higher values like 0.8 will make the output more random, while
                     lower values like 0.2 will make it more focused and deterministic.`
                  "
                  class="flex items-center gap-1 w-40 text-sm text-primary"
                >
                  temperature <InformationCircleIcon class="w-4" />
                </div>
                <div class="w-8">
                  {{ modelTemperature }}
                </div>
                <input
                  v-model.number="modelTemperature"
                  type="range"
                  min="0.2"
                  max="1.0"
                  step="0.1"
                />
              </div>
              <div class="flex items-center gap-2">
                <div
                  v-tooltip="
                    `Number between -2.0 and 2.0. Positive values penalize new
                    tokens based on whether they appear in the text so far,
                    increasing the model's likelihood to talk about new topics.`
                  "
                  class="flex items-center gap-1 w-40 text-sm text-primary"
                >
                  Presence penalty
                  <InformationCircleIcon class="w-4" />
                </div>
                <div class="w-8">
                  {{ modelPresencePenalty }}
                </div>
                <input
                  v-model.number="modelPresencePenalty"
                  type="range"
                  min="-2"
                  max="2"
                  step="0.1"
                />
              </div>
            </div>
          </div>
        </template>
      </ExpansionPanel>
      <div class="divide-y">
        <ExpansionPanel
          v-for="reviewItem in includedItems"
          v-model="panelsState[reviewItem.id]"
        >
          <template #activator="{ toggle }">
            <div class="bg-slate-600 flex justify-between items-center w-full">
              <button
                class="px-4 py-2 text-left text-white flex gap-2 items-center truncate w-full"
                @click="toggle"
              >
                <ChevronDownIcon class="w-5" />
                <p class="truncate">{{ reviewItem.metadata.title }}</p>
              </button>
              <div class="px-2 pt-2" v-if="loading[reviewItem.id]">
                <span
                  class="loader w-5 h-5 !border-white !border-b-transparent"
                />
              </div>
              <button
                v-else
                class="p-2 text-white hover:text-slate-200"
                @click="generateAnswers(reviewItem)"
              >
                <SparklesIcon class="w-5" />
              </button>
            </div>
          </template>
          <template #default>
            <div class="px-4 py-2 w-full text-left text-wrap">
              <div v-if="!reviewItemsAnswers[reviewItem.id]?.length">
                No answers yet
              </div>
              <div class="divide-y" v-else>
                <div
                  v-for="(answers, index) in reviewItemsAnswers[reviewItem.id]"
                  :key="'answer-history-' + index"
                  class="py-2"
                >
                  <div v-for="answer in answers" class="py-2">
                    <div class="font-bold">{{ answer.question }}</div>
                    <div>{{ answer.answer }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ExpansionPanel>
      </div>
    </template>
  </ExpansionPanel>
</template>
<script setup lang="ts">
import { EvidenceServiceApiKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { computed, ref, watch } from 'vue'
import { useStorage } from '@vueuse/core'
import { Review } from '@core/domain/models/review.model'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import {
  ChevronDownIcon,
  InformationCircleIcon,
  QueueListIcon,
  SparklesIcon,
} from '@heroicons/vue/24/outline'
import { ReviewItem } from '@core/domain/models/reviewItem.model'

const props = defineProps<{
  review: Review
  questions: { [questionId: string]: boolean }
}>()
const evidenceServiceApi = injectStrict(EvidenceServiceApiKey)

const panelsState = useStorage<any>('prototype-panelsState', {})

const modelPresencePenalty = useStorage<number>(
  'prototype-model-presence-penalty',
  0.0,
)
const modelTemperature = useStorage<number>(
  'prototype-model-presence-temp',
  0.8,
)
const qaPrompt = useStorage<string>(
  'prototype-qa-prompt',
  `I will provide a scientific document and a series of questions. Please analyze it and extract answers based only on the content of the document. 
For each question:

Answers must address the specific question asked and should not extend to other related topics unless explicitly requested but include relevant details directly from the document while staying concise and focused on the question.
-If the document does not provide an answer, respond with a '-'.
-Do not infer or assume information beyond what is explicitly stated in the document.`,
)
const summaryPrompt = useStorage<string>(
  'prototype-summary-prompt',
  `You are tasked with summarizing the following question-answer pairs extracted from a document.
Provide concise summaries for each question based solely on the provided answers.
If no answers are available, respond with a dash "-". Ensure that key details such as percentages, numbers, or specific data points are preserved if mentioned in the answers.`,
)
const reviewItemsAnswers = useStorage<{
  [id: number]: { question: string; answer: string; id: string }[][]
}>('prototype-reviewItemsAnswers', [])

const isDataExtractionPanelExpanded = useStorage<boolean>(
  'prototype-data-extraction-isDataExtractionPanelExpanded',
  false,
)

const isDataExtractionSettingsPanelExpanded = useStorage<boolean>(
  'prototype-data-extraction-isDataExtractionSettingsPanelExpanded',
  false,
)
const includedItems = computed(() => {
  return props.review.studies.filter((s) => s.fullTextScreening === 'Included')
})

const loading = ref<{ [reviewId: number]: boolean }>({})
async function generateAnswers(reviewItem: ReviewItem) {
  loading.value[reviewItem.id] = true
  try {
    const { data: answers } =
      await evidenceServiceApi.api.prototypeControllerExtractAnswersForReviewItem(
        {
          reviewItemId: reviewItem.id,
          modelTemperature: modelTemperature.value,
          modelPresencePenalty: modelPresencePenalty.value,
          qaPrompt: qaPrompt.value,
          summaryPrompt: summaryPrompt.value,
          attributeIds: Object.keys(props.questions).filter(
            (k) => props.questions[k],
          ),
        },
      )
    if (!reviewItemsAnswers.value[reviewItem.id]) {
      reviewItemsAnswers.value[reviewItem.id] = []
    }
    reviewItemsAnswers.value[reviewItem.id].push(answers)
  } catch (e) {
    console.error(e)
  } finally {
    loading.value[reviewItem.id] = false
  }
}
enum PanelToggleAction {
  Expand,
  Collapse,
}
function toggleAllPanels() {
  const action = Object.values(panelsState.value).some((v) => v)
    ? PanelToggleAction.Collapse
    : PanelToggleAction.Expand
  includedItems.value.forEach((item) => {
    panelsState.value[item.id] = action === PanelToggleAction.Expand
  })
}

watch(
  props.review,
  (v) => {
    if (!v) return
  },
  {
    immediate: true,
  },
)
</script>
