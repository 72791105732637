import {
  AustraliaFlag,
  BrazilFlag,
  CanadaFlag,
  CyprusFlag,
  FranceFlag,
  GermanyFlag,
  ItalyFlag,
  SwitzerlandFlag,
  UKFlag,
  USAFlag,
} from '@app/components/Icons/flags'

export const fsnProviders: {
  countryCode: string
  countryFlag: any
  sources: { id: string; name: string }[]
}[] = [
  {
    countryCode: 'de',
    countryFlag: GermanyFlag,
    sources: [{ id: 'bfarm', name: 'BfArM' }],
  },
  {
    countryCode: 'fr',
    countryFlag: FranceFlag,
    sources: [{ id: 'ansm', name: 'ANSM' }],
  },
  {
    countryCode: 'au',
    countryFlag: AustraliaFlag,
    sources: [{ id: 'sara', name: 'TGA' }],
  },
  {
    countryCode: 'gb',
    countryFlag: UKFlag,
    sources: [{ id: 'mhra', name: 'MHRA' }],
  },
  {
    countryCode: 'us',
    countryFlag: USAFlag,
    sources: [
      { id: 'fda', name: 'FDA Safety Communications' },
      { id: 'fda', name: 'FDA Letters to Health Care Providers' },
      { id: 'fda', name: 'FDA Medical Device Bans' },
      { id: 'fda', name: 'FDA Medical Device Recalls' },
    ],
  },
  {
    countryCode: 'cy',
    countryFlag: CyprusFlag,
    sources: [{ id: 'moh', name: 'ΙΥ&ΥΔΥ' }],
  },
  {
    countryCode: 'ca',
    countryFlag: CanadaFlag,
    sources: [{ id: 'health-canada', name: 'Health Canada' }],
  },
  {
    countryCode: 'br',
    countryFlag: BrazilFlag,
    sources: [{ id: 'anvisa', name: 'ANVISA' }],
  },
  {
    countryCode: 'ch',
    countryFlag: SwitzerlandFlag,
    sources: [{ id: 'swissmedic', name: 'Swissmedic' }],
  },
  {
    countryCode: 'it',
    countryFlag: ItalyFlag,
    sources: [{ id: 'avvisi', name: 'Ministero della Salute' }],
  },
]
