<template>
  <div class="fixed bottom-5 flex left-20 w-full" style="z-index: 999999999999">
    <transition name="slide-fade" mode="out-in">
      <div
        v-if="!hasAcceptedCookies"
        class="rounded-md bg-white drop-shadow-md py-1 px-4 min-w-[800px]"
      >
        <div class="flex items-center">
          <div>
            <p class="text-sm font-medium text-black">
              We use cookies to keep you signed in. By using this app, you
              accept our use of cookies.
            </p>
          </div>
          <div class="ml-auto">
            <button
              type="button"
              class="inline rounded-md py-2 pr-1 text-black hover:text-black/80 focus:outline-hidden"
              @click="hasAcceptedCookies = true"
            >
              <span class="sr-only">Dismiss</span>
              Ok
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { useStorage } from '@vueuse/core'

const hasAcceptedCookies = useStorage('cookies-accepted', false)
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
  transform: translateY(100%);
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
  transform: translateY(100%);
}

.slide-fade-enter-to {
  transform: translateY(0);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
