<template>
  <Modal ref="modal">
    <template #activator="{ show }">
      <button
        class="bg-blue-100 text-blue-800 px-4 py-2 rounded-full whitespace-nowrap flex items-center gap-1"
        @click="areAllStudiesProcessed ? exportProtocol() : show()"
      >
        <DocumentTextIcon class="w-5" /> Plan and report
        <ArrowDownTrayIcon class="w-4" />
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold text-center flex gap-2 items-center"
      >
        Plan and report
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>
    <template #content>
      <p v-if="!areAllStudiesProcessed" class="text-sm text-gray-500">
        Some studies are missing their appraisal or screening, are you sure you
        would like to proceed with the export?
      </p>
    </template>
    <template #footer="{ hide }">
      <div class="sm:flex sm:flex-row-reverse gap-2">
        <button
          type="button"
          class="inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-primary-700 sm:w-auto"
          @click=";[hide(), exportProtocol()]"
        >
          Proceed
        </button>
        <button
          type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          @click="hide"
        >
          Cancel
        </button>
      </div></template
    >
  </Modal>
</template>

<script lang="ts" setup>
import { ReviewKey } from '@app/views/Review/use-review'
import { hideAll } from 'tippy.js'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { injectStrict } from '@app/utils/injectStrict'
import useUsers from '@app/composables/use-users'
import { generateProtocol } from './utils/generate-protocol'
import { generatePrismaDiagram } from './utils/generate-prisma-diagram'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
import { DocumentTextIcon } from '@heroicons/vue/24/solid'
import useExport from './useExport'
import XIcon from '@app/components/Icons/XIcon.vue'

const review = injectStrict(ReviewKey)
const { areAllStudiesProcessed } = useExport(review.entity.value)

const users = useUsers()
users.refresh()
const loading = useLoading()
const snackbar = useSnackbar()
const dataExtraction = review.getDataExtraction()
async function exportProtocol(): Promise<void> {
  loading.start()
  try {
    const prismaDiagramImage = await generatePrismaDiagram(review.entity.value)

    const protocol = await generateProtocol({
      review: review.entity.value,
      appraisalCriteria: review.entity.value.plan?.appraisalCriteria ?? [],
      attributesStructure: review.sortedAttributes.value,
      prismaDiagramImage,
      inclusionCriteria: review.inclusionCriteria.value!,
      isPlanIncluded: true,
      isReportIncluded: true,
      dataExtraction: dataExtraction,
    })

    const downloadElement = document.createElement('a')
    downloadElement.style.display = 'none'
    downloadElement.href = window.URL.createObjectURL(new Blob([protocol]))
    downloadElement.download =
      review.entity.value?.project?.name +
      ' - ' +
      review.entity.value?.name +
      '.docx'
    document.body.appendChild(downloadElement)
    downloadElement.click()
    window.URL.revokeObjectURL(downloadElement.href)
    downloadElement.remove()
    hideAll()
    snackbar.success('Protocol and report exported successfully')
  } catch (error) {
    snackbar.error('Failed to export protocol and report')
    throw error
  } finally {
    loading.stop()
  }
}
</script>
