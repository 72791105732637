<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="text-sm text-slate-700 font-medium pointer-events-none"
    >
      {{ label }}
      <span v-if="isRequired" class="text-red-600">*</span>
    </label>
    <div>
      <div
        class="cursor-text h-full flex gap-2 justify-between items-center w-full"
      >
        <input
          :id="id"
          ref="input"
          v-model="displayValue"
          type="number"
          class="w-full focus:outline-hidden disabled:bg-transparent font-normal truncate px-2 py-1 flex items-center justify-between rounded-md bg-white border"
          :class="[
            {
              ' bg-white hover:border-blue-500 focus:border-blue-500 border-slate-300':
                !disabled && !computedError,
              'bg-black/5': disabled,
              'border-red-600 text-red-600': computedError,
            },
            inputClasses,
          ]"
          :placeholder="placeholder ?? label"
          :disabled="disabled"
          :tabindex="tabindex"
          :min="min"
          :max="max"
          @input="handleInput"
          @keydown.enter="emit('enter', value)"
        />
      </div>
      <div>
        <InformationCircleIcon
          v-if="computedError"
          v-tooltip.error="computedError ? computedError : ''"
          class="w-4 h-4 mr-2 text-red-600 outline-hidden"
          :class="{
            'text-transparent': !computedError,
          }"
        />
      </div>
    </div>
    <div tabindex="-1" class="h-full flex top-1/2 text-black">
      <button v-if="clearable && value" tabindex="-1" @click="clear()">
        <XCircleIcon class="w-5 h-5" />
      </button>
      <label tabindex="-1" :for="id" class="flex items-center">
        <slot name="suffix" />
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import XCircleIcon from '../../Icons/XCircleIcon.vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

const input = ref<HTMLInputElement>()
const id = uuidv4()

const props = withDefaults(
  defineProps<{
    label?: string
    clearable?: boolean
    placeholder?: string
    rules?: ((v: number | null) => boolean | string)[]
    disabled?: boolean
    error?: string
    tabindex?: number
    inputClasses?: string
    isRequired?: boolean
    min?: number
    max?: number
  }>(),
  {
    clearable: false,
    rules: () => [],
    label: '',
    placeholder: '',
    disabled: false,
    error: '',
    tabindex: 0,
    inputClasses: '',
    isRequired: false,
    min: -Infinity,
    max: Infinity,
  },
)

const emit = defineEmits<{
  enter: [value: number]
  'update:modelValue': [value: number]
}>()

const value = defineModel<number>({
  default: 0,
})

const displayValue = computed({
  get: () => value.value?.toString() ?? '',
  set: (newValue: string) => {
    const parsed = newValue === '' ? 0 : parseFloat(newValue)
    if (parsed === 0 || !isNaN(parsed)) {
      value.value = parsed
    }
  },
})

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement
  displayValue.value = target.value
  validate()
}

function clear() {
  value.value = 0
  displayValue.value = ''
}

const internalError = ref<string>()

const computedError = computed(() =>
  props.error ? props.error : internalError.value,
)

function validate(): boolean {
  const errors = props.rules
    .map((r) => r(value.value))
    .filter((r) => typeof r === 'string')
  internalError.value = errors.join(' ')
  return errors.length <= 0
}

function focus() {
  input.value?.focus({
    preventScroll: true,
  })
}

function select() {
  input.value?.select()
}

defineExpose({
  validate,
  focus,
  select,
})
</script>
