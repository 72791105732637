import { ref } from 'vue'
export type Drawer = {
  id: string
  isVisible: boolean
}

const drawers = ref<Drawer[]>([])

export default () => {
  function addDrawer(drawerId: string) {
    drawers.value.push({
      id: drawerId,
      isVisible: false,
    })
  }

  function showDrawer(drawerId: string) {
    updateDrawerVisibility(drawerId, true)
  }
  function hideDrawer(drawerId: string) {
    updateDrawerVisibility(drawerId, false)
  }
  function hideAllDrawers() {
    drawers.value.forEach((d) => (d.isVisible = false))
  }

  function updateDrawerVisibility(drawerId: string, isVisible: boolean) {
    const drawerIndex = drawers.value.findIndex((d) => d.id === drawerId)
    drawers.value[drawerIndex].isVisible = isVisible
  }

  function isDrawerVisible(drawerId: string) {
    return drawers.value.find((d) => d.id === drawerId)?.isVisible
  }

  return {
    addDrawer,
    showDrawer,
    hideDrawer,
    isDrawerVisible,
    hideAllDrawers,
    drawers,
  }
}
