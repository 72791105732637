<template>
  <div class="p-6 space-y-4 container">
    <h3 class="mb-4 text-blue-700 font-semibold tracking-[-0.14px] text-base">
      Specify the appraisal strategy
    </h3>
    <div class="max-w-[653px]">
      <div class="space-y-4">
        <div>
          <div class="space-y-6 border border-slate-300 rounded-md p-6">
            <div class="flex justify-between items-start gap-8">
              <div class="space-y-2">
                <h3 class="text-slate-700 font-medium flex items-center gap-2">
                  Oxford level of evidence
                </h3>
                <p class="text-slate-500 text-sm font-[400]">
                  The Oxford CEBM Levels of Evidence is a grading systems for
                  assessing the quality of evidence for claims about prognosis,
                  diagnosis, treatment benefits, treatment harms, and screening.
                </p>
              </div>
              <Switch
                :disabled="review.isPlanReadonly.value"
                :model-value="
                  review.entity.value?.plan?.appraisalPlan
                    .isOxfordLevelOfEvidenceApplicable
                "
                :class="[
                  review.entity.value?.plan?.appraisalPlan
                    .isOxfordLevelOfEvidenceApplicable
                    ? 'bg-green-600'
                    : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden ',
                ]"
                @update:model-value="toggleAppraisalIsOxfordLevelOfEvidence"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    review.entity.value?.plan?.appraisalPlan
                      .isOxfordLevelOfEvidenceApplicable
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
                  ]"
                />
              </Switch>
            </div>
          </div>
        </div>
        <AppraisalCriteria />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import AppraisalCriteria from './AppraisalCriteria.vue'
import { HttpException } from '@core/exceptions/http.exception'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import useLoading from '@app/composables/use-loading'

const review = injectStrict(ReviewKey)
const snackbar = useSnackbar()
const loading = useLoading()
async function toggleAppraisalIsOxfordLevelOfEvidence(value: boolean) {
  loading.start()
  try {
    if (value) {
      await review.enableStudiesAppraisalOxfordLevelOfEvidence()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Appraisal oxford level of evidence enabled successfully',
      )
    } else {
      await review.disableStudiesAppraisalOxfordLevelOfEvidence()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Appraisal oxford level of evidence disabled successfully',
      )
    }
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while enabling/disabling appraisal oxford level of evidence, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}
</script>
