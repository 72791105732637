import { Article } from '../models/article.model'
import { FieldSafetyNotice } from '../models/fieldSafetyNotice.model'
import { Guidance } from '../models/guidance.model'
import { Incident } from '../models/incident.model'
import { Registration } from '../models/registration.model'
import { ReviewItem } from '../models/reviewItem.model'
import { ReviewItemType } from '../types/reviewItemType.type'

export function createReviewItem(data: ReviewItem): ReviewItem {
  if (data.type === ReviewItemType.Incident) {
    return new Incident(data)
  } else if (data.type === ReviewItemType.Guidance) {
    return new Guidance(data)
  } else if (data.type === ReviewItemType.Registration) {
    return new Registration(data)
  } else if (data.type === ReviewItemType.FieldSafetyNotice) {
    return new FieldSafetyNotice(data)
  } else {
    return new Article(data)
  }
}
