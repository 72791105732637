<template>
  <div>
    <!-- Mobile Dropdown -->
    <div class="grid grid-cols-1 sm:hidden">
      <select
        v-model="selectedTab"
        aria-label="Select a tab"
        class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pr-8 pl-3 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
        @change="handleChange"
      >
        <option
          v-for="tab in tabs"
          :key="'tab-' + tab.number"
          :value="tab.number"
          :disabled="tab.isDisabled"
        >
          {{ tab.title }}
        </option>
      </select>
      <ChevronDownIcon
        class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
        aria-hidden="true"
      />
    </div>

    <div class="hidden sm:block">
      <div class="px-[24px]">
        <nav class="-mb-px flex space-x-4" aria-label="Tabs">
          <button
            v-for="tab in tabs"
            :key="'tab-' + tab.number"
            class="border-b-2 px-1 py-5 text-sm font-medium whitespace-nowrap"
            :class="[
              tab.number === activeTabNumber
                ? 'border-primary-500 text-primary-500'
                : 'border-transparent text-mid-neutral-500 hover:text-mid-neutral-500',
              tab.isDisabled ? 'cursor-not-allowed text-gray-400' : '',
            ]"
            :aria-current="tab.number === activeTabNumber ? 'page' : undefined"
            :disabled="tab.isDisabled"
            @click="onClick(tab)"
          >
            {{ tab.title }}
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/16/solid'

type Tab = {
  number: number
  title?: string
  subtitle?: string
  icon?: string
  isDisabled?: boolean
}

const props = defineProps<{
  tabs: Tab[]
  activeTabNumber: number
}>()

const emit = defineEmits<(e: 'tab:clicked', tab: Tab) => void>()


const selectedTab = computed({
  get: () => props.activeTabNumber,
  set: (newVal) => {
    const tab = props.tabs.find((t) => t.number === newVal)
    if (tab) {
      emit('tab:clicked', tab)
    }
  },
})

function onClick(tab: Tab) {
  emit('tab:clicked', tab)
}

function handleChange(event: Event) {
  const newTabNumber = Number((event.target as HTMLSelectElement).value)
  selectedTab.value = newTabNumber
}
</script>
