<template>
  <Modal v-if="isAdmin" ref="modal" position="center">
    <template #activator="{ show }">
      <button
        :class="[
          'px-4 py-2 flex items-center gap-2',
          isShown ? 'hover:bg-light-neutral-500' : '',
        ]"
        @click.stop="show"
      >
        <Cog6ToothIcon class="size-5 text-dark-neutral-500" />
        <a class="font-medium text-dark-neutral-500 relative text-sm">
          Rename Project
          <span class="absolute inset-0"></span>
        </a>
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Rename Project
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content="{ hide }">
      <TextInput
        v-model="internalProjectName"
        type="text"
        placeholder="Project Name"
        @enter="updateProjectName(project.id!, internalProjectName, hide)"
      >
      </TextInput>
    </template>

    <template #footer="{ hide }">
      <div class="flex justify-end gap-2">
        <ActionButton color="neutral" @click="hide"> Cancel </ActionButton>
        <ActionButton
          color="primary"
          @click="updateProjectName(project.id!, internalProjectName, hide)"
        >
          Update
        </ActionButton>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import useProjects from '@app/views/Projects/use-projects'
import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { computed, ref } from 'vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { Cog6ToothIcon } from '@heroicons/vue/24/outline'
import XIcon from '@app/components/Icons/XIcon.vue'
import ActionButton from '@app/components/Global/ActionButton.vue'

const { user } = injectStrict(AuthKey)
const isAdmin = computed(() => user.value?.role === Role.ADMIN)

const props = defineProps<{
  project: Project
  isShown?: boolean
}>()

const internalProjectName = ref(props.project.name ?? '')

const loading = useLoading()
const snackbar = useSnackbar()
const projects = useProjects()

async function updateProjectName(
  projectId: Id,
  name: string,
  cb: () => void,
): Promise<void> {
  if (!name.trim()) {
    snackbar.error('Project name cannot be empty')
    return
  }
  try {
    loading.start()
    await projects.updateName(projectId, name)
    internalProjectName.value = name
    snackbar.success('Project name updated successfully')
    cb()
  } finally {
    loading.stop()
  }
}
</script>
