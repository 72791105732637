<template>
  <div class="flex h-full">
    <div class="w-[20rem] border-r border-mid-neutral-50">
      <Tabs
        v-model:tab="activeStepNumber"
        :tabs="steps"
        :style="4"
        class="h-full"
        @update:step="updateStep"
      />
    </div>

    <!-- Content Area -->
    <div class="flex-1">
      <div
        class="flex items-center gap-3 px-6 py-3 h-[4rem] border-b border-mid-neutral-50"
      >
        <div class="flex flex-wrap items-start min-w-48">
          <div v-if="activeStep" class="flex items-center gap-2">
            <component
              :is="activeStep.icon"
              class="w-5 h-5 text-dark-neutral-500"
            />
            <p class="text-base font-semibold text-dark-neutral-500 truncate">
              {{ activeStep.title }}
            </p>
          </div>
        </div>
      </div>
      <template v-if="activeStepNumber === Tab.AI_ASSISTANT">
        <AiAssistantSettings :steps="steps" />
      </template>
      <template v-else-if="activeStepNumber === Tab.STRATEGY">
        <div class="container px-6 my-6 space-y-4 text-sm">
          <StrategySettings />
        </div>
      </template>

      <template v-else-if="activeStepNumber === Tab.EXTENSION">
        <ExtensionSettings />
      </template>
      <template v-else-if="activeStepNumber === Tab.ABOUT">
        <div class="container px-6 my-6 space-y-4 text-sm">
          version: {{ version }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import AiAssistantSettings from './AiAssistantSettings.vue'
import ExtensionSettings from './ExtensionSettings.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey, DevToolboxKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import {
  BookOpenIcon,
  InformationCircleIcon,
  PuzzlePieceIcon,
  SparklesIcon,
} from '@heroicons/vue/24/outline'
import StrategySettings from './StrategySettings.vue'

const version = import.meta.env.VITE_EVIDENCE_VERSION
const { user } = injectStrict(AuthKey)
const { isCuttingEdge } = injectStrict(DevToolboxKey)
enum Tab {
  AI_ASSISTANT,
  STRATEGY,
  EXTENSION,
  ABOUT,
}

const steps = [
  ...(user.value?.role === Role.ADMIN
    ? [
        {
          number: Tab.AI_ASSISTANT,
          title: 'AI Assistant',
          subtitle:
            'Manage your AI assistant settings for a personalized experience.',
          icon: SparklesIcon,
        },
        ...(isCuttingEdge
          ? [
              {
                number: Tab.STRATEGY,
                title: 'Strategy',
                icon: BookOpenIcon,
              },
            ]
          : []),
      ]
    : []),

  ...(user.value?.role !== Role.READ_ONLY
    ? [
        {
          number: Tab.EXTENSION,
          title: 'Extension',
          icon: PuzzlePieceIcon,
        },
      ]
    : []),
  {
    number: Tab.ABOUT,
    title: 'About',
    icon: InformationCircleIcon,
  },
]

const route = useRoute()
const router = useRouter()
const activeStepNumber = ref(steps[0].number)
const activeSubStepNumber = ref(1)
const activeStep = computed(() => {
  return steps.find((step) => step.number === activeStepNumber.value)
})

if (route.query.tab) {
  activeStepNumber.value = Number(route.query.tab)
}
if (route.query.subtab) {
  activeSubStepNumber.value = Number(route.query.subtab)
}

function updateStep(step: number) {
  router.push({ query: { tab: step, subtab: 1 } })
}
</script>
