<template>
  <Drawer
    ref="drawer"
    content-padding="px-0 py-0"
    :show-footer="true"
    dismissible
    @hide="reviewCreate.reset()"
  >
    <template #activator="{ show }">
      <button
        :class="props.class"
        class="text-secondary inline-flex items-center gap-1 text-base hover:bg-light-neutral-300 text-secondary-500"
        @click.stop="show"
      >
        <PlusIcon class="w-4 h-4" />
        <span class="hover:cursor-pointer text-base leading-5">
          Add Review
        </span>
      </button>
    </template>

    <template #header="{ hide }">
      <div class="flex items-center gap-2">
        <button
          class="text-mid-neutral-500 text-base rounded-full"
          :disabled="
            reviewCreate.currentStep.value ===
              CreateReviewStep.PresetSelection || isLoading
          "
          :class="{
            'opacity-50 cursor-not-allowed':
              reviewCreate.currentStep.value ===
              CreateReviewStep.PresetSelection,
            'hover:bg-mid-neutral-300':
              reviewCreate.currentStep.value !==
              CreateReviewStep.PresetSelection,
          }"
          @click="reviewCreate.goToPreviousStep"
        >
          <div
            class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center hover:bg-mid-neutral-100"
          >
            <ChevronLeftIcon
              class="stroke-[2.5px] text-mid-neutral-500 size-4"
            />
          </div>
        </button>
        <p
          class="text-dark-neutral-500 text-lg font-semibold capitalize text-center flex gap-2 items-center"
        >
          Review wizard
        </p>
      </div>

      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content="{ hide }">
      <div class="flex-1 overflow-auto">
        <div
          v-if="isLoading && loadingMessage"
          class="p-2"
          v-html="loadingMessage + animatedDots"
        />
        <KeepAlive v-else>
          <component
            :is="currentStepComponent"
            @create-review="createReview(hide)"
          />
        </KeepAlive>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="flex justify-end">
        <button
          v-if="
            [
              CreateReviewStep.Summary,
              CreateReviewStep.PmsDeviceLevelMinimal,
              CreateReviewStep.MedicalBackgroundConfiguration,
            ].includes(reviewCreate.currentStep.value)
          "
          class="bg-primary-500 text-white rounded-md text-base tracking-tighter px-4 py-2 font-medium"
          :disabled="!reviewCreate.isCurrentStepValid.value || isLoading"
          :class="{
            'opacity-50 cursor-not-allowed':
              !reviewCreate.isCurrentStepValid.value || isLoading,
            'hover:brightness-125':
              reviewCreate.isCurrentStepValid.value && !isLoading,
          }"
          @click="createReview(hide)"
        >
          Confirm
        </button>
      </div>
    </template>
  </Drawer>
</template>

<script lang="ts" setup>
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import { provide, computed, ref } from 'vue'
import useSnackbar from '@app/composables/use-snackbar'
import { HttpException } from '@core/exceptions/http.exception'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import XIcon from '@app/components/Icons/XIcon.vue'
import useCreateProjectReview, {
  CreateReviewKey,
  CreateReviewStep,
} from './useCreateProjectReview'
import useProjects from '../../use-projects'
import PresetSelection from './ReviewPreset/PresetSelection.vue'
import DeviceSpecificSearchLevel from './ReviewPreset/Presets/DeviceSpecificSearchPreset/DeviceSpecificSearchLevel.vue'
import Summary from './Summary/Summary.vue'
import GenericDeviceGroupPreset from './ReviewPreset/Presets/GenericDeviceGroupPreset.vue'
import MedicalAlternativePreset from './ReviewPreset/Presets/MedicalAlternativePreset.vue'
import DeviceSpecificSearchMinimalPreset from './ReviewPreset/Presets/DeviceSpecificSearchPreset/DeviceSpecificSearchMinimalPreset.vue'
import { useRouter } from 'vue-router'
import { generateMessageWithDelay } from '@app/utils/generateTextWithDelay'
import MedicalBackgroundConfiguration from './ReviewPreset/Presets/MedicalBackgroundPreset/MedicalBackgroundConfiguration.vue'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import useLoading from '@app/composables/use-loading'
import MedicalBackgroundIndication from './ReviewPreset/Presets/MedicalBackgroundPreset/MedicalBackgroundIndication.vue'
import { ChevronLeftIcon, PlusIcon } from '@heroicons/vue/24/outline'
import Drawer from '@app/components/Global/Drawer/Drawer.vue'

const props = defineProps<{
  projectId: Id
  class?: string
}>()

const isLoading = ref(false)
const loadingMessage = ref('')
const stepsComponents = {
  [CreateReviewStep.PresetSelection]: PresetSelection,
  [CreateReviewStep.PmsDeviceLevelSelection]: DeviceSpecificSearchLevel,
  [CreateReviewStep.PmsDeviceLevelMinimal]: DeviceSpecificSearchMinimalPreset,
  [CreateReviewStep.genericDeviceGroupConfiguration]: GenericDeviceGroupPreset,
  [CreateReviewStep.MedicalAlternativeConfiguration]: MedicalAlternativePreset,
  [CreateReviewStep.MedicalBackgroundConfiguration]:
    MedicalBackgroundConfiguration,
  [CreateReviewStep.MedicalBackgroundIndication]: MedicalBackgroundIndication,
  [CreateReviewStep.Summary]: Summary,
}
const reviewCreate = useCreateProjectReview(props.projectId)

const currentStepComponent = computed(() => {
  return stepsComponents[reviewCreate.currentStep.value]
})
const projects = useProjects()
provide(CreateReviewKey, reviewCreate)
const snackbar = useSnackbar()
const loading = useLoading()
const router = useRouter()

async function createReview(cb: () => void) {
  if (!reviewCreate.isCurrentStepValid.value) return

  try {
    isLoading.value = true
    const presetType = reviewCreate.preset.value.preset
    let reviewId = 0
    switch (presetType) {
      case ReviewPreset.MEDICAL_BACKGROUND:
        reviewId = await handleMedicalBackgroundPreset()
        break

      case ReviewPreset.DEVICE_SPECIFIC_SEARCH:
        reviewId = await handleDevicePreset()
        break

      case ReviewPreset.MEDICAL_ALTERNATIVE:
      case ReviewPreset.GENERIC_DEVICE_GROUP:
      case ReviewPreset.CUSTOM:
      case ReviewPreset.DEFAULT:
      default:
        loading.start()
        reviewId = await reviewCreate.createReview().finally(() => {
          loading.stop()
        })
    }
    if (!reviewId) throw new Error('Something went wrong, please try again')

    router.push(`/reviews/${reviewId}`)
    snackbar.show(SnackbarState.SUCCESS, 'Review created successfully')
  } catch (e) {
    handleError(e)
  } finally {
    cleanup(cb)
  }
}

async function handleMedicalBackgroundPreset() {
  const [_, reviewId] = await Promise.all([
    appendMessage('Creating review ' + reviewCreate.reviewName.value),
    reviewCreate.createReview(),
  ])
  await appendMessage('<br/>Generating plan')
  await runAutoImports(reviewId)
  return reviewId
}

async function handleDevicePreset() {
  reviewCreate.generateReviewName()
  const [_, reviewId] = await Promise.all([
    appendMessage('Creating review ' + reviewCreate.reviewName.value),
    reviewCreate.createReview(),
  ])
  await appendMessage('<br/>Generating plan')
  await runAutoImports(reviewId)
  return reviewId
}

async function runAutoImports(reviewId: Id) {
  for await (const message of reviewCreate.autoImportItems(reviewId)) {
    await appendMessage('<br/>' + message)
  }
  await appendSuccessMessage()
}

async function appendSuccessMessage() {
  await appendMessage(
    '<br/><span class="text-green-600">Review created successfully</span>',
  )
}

function handleError(e: unknown) {
  const error = e as HttpException
  snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  if (error.response.data.statusCode >= 500) throw e
}

function cleanup(cb: () => void) {
  isLoading.value = false
  reviewCreate.reset()
  projects.refresh()
  hideAll()
  cb()
}

async function appendMessage(
  message: string,
  delayInMs: number = 1000,
): Promise<void> {
  const generator = generateMessageWithDelay(message)
  for await (const word of generator) {
    loadingMessage.value += `${word} `
  }
  await new Promise<void>((resolve) => setTimeout(resolve, delayInMs))
}

const animatedDots = ref('')

setInterval(() => {
  animatedDots.value =
    animatedDots.value.length < 3 ? animatedDots.value + '.' : '.'
}, 200)
</script>
<style>
.create-review-modal {
  height: min(800px, calc(100vh - 300px));
}
</style>
