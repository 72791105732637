<template>
  <div class="relative" :class="[isExpanded ? 'h-full' : 'h-32']">
    <div class="h-full">
      <editor-content :editor="editor" class="h-full bg-transparent" />
      <bubble-menu
        v-if="editor"
        :editor="editor"
        :tippy-options="{ duration: 100, theme: 'light-border' }"
      >
        <div class="bubble-menu">
          <button
            v-for="style in bubbleMenuStyles"
            :key="style.id"
            class="p-2 border border-transparent"
            :class="{
              'bg-slate-200 border-white': editor.isActive(style.id),
            }"
            @click="style.handler(editor)"
            @mousedown.prevent
          >
            <component :is="style.icon" class="w-5" />
          </button>
        </div>
      </bubble-menu>
      <floating-menu
        v-if="editor"
        :editor="editor"
        :tippy-options="{
          duration: 100,
          placement: 'top',
          theme: 'light-border',
        }"
      >
        <div class="floating-menu">
          <button
            v-for="style in floatingMenuStyles"
            :key="style.id"
            class="p-2 border border-transparent"
            :class="{
              'bg-slate-200 border-white': editor.isActive(style.id),
            }"
            @click="style.handler(editor)"
            @mousedown.prevent
          >
            <component :is="style.icon" class="w-5" />
          </button>
        </div>
      </floating-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import HardBreak from '@tiptap/extension-hard-break'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Dropcursor from '@tiptap/extension-dropcursor'
import ListKeymap from '@tiptap/extension-list-keymap'
import {
  EditorContent,
  useEditor,
  BubbleMenu,
  Editor,
  FloatingMenu,
} from '@tiptap/vue-3'

import Text from '@tiptap/extension-text'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import { ref, watch, watchEffect, onBeforeUnmount } from 'vue'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import OrderedList from '@tiptap/extension-ordered-list'
import {
  BoldIcon,
  ItalicIcon,
  ListBulletIcon,
  NumberedListIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from '@heroicons/vue/24/outline'
import { HocuspocusProvider } from '@hocuspocus/provider'

const props = defineProps<{
  isExpanded: boolean
  disabled: boolean
  field: string
  provider: HocuspocusProvider
  placeholder?: string
  userName: string
  userColor: string
  editorClasses?: string
}>()
const html = defineModel<string>('html', { default: '' })
const text = defineModel<string>('text')

const editorExtensions = [
  Document,
  Paragraph,
  Strike,
  OrderedList.configure({
    HTMLAttributes: {
      class: 'list-decimal pl-4',
    },
  }),

  Text,
  Italic,
  Underline,
  Bold,
  HardBreak,
  BulletList.configure({
    HTMLAttributes: {
      class: 'list-disc pl-4',
    },
  }),
  ListItem,
  Dropcursor,
  ListKeymap,
  Placeholder.configure({
    placeholder: props.placeholder,
  }),
  Collaboration.configure({
    document: props.provider.document,
    field: props.field,
  }),
  CollaborationCursor.configure({
    provider: props.provider,
    user: {
      name: props.userName,
      color: props.userColor,
    },
  }),
]
const isFocused = ref(false)

const editor = useEditor({
  editable: !props.disabled,
  extensions: editorExtensions,
  injectCSS: false,
  onBlur: ({ editor }) => {
    isFocused.value = false
    editor.commands.setTextSelection(0)
  },
  onFocus: () => {
    isFocused.value = true
  },
  onUpdate({ editor }) {
    html.value = editor.getHTML()
    text.value = editor.getText()
  },
})

const boldStyle = {
  id: 'bold',
  handler: (editor: Editor) => editor.chain().focus().toggleBold().run(),
  title: 'Bold',
  icon: BoldIcon,
}

const italicStyle = {
  id: 'italic',
  handler: (editor: Editor) => editor.chain().focus().toggleItalic().run(),
  title: 'Italic',
  icon: ItalicIcon,
}

const bulletListStyle = {
  id: 'bulletList',
  handler: (editor: Editor) => editor.chain().focus().toggleBulletList().run(),
  title: 'List',
  icon: ListBulletIcon,
}

const underlineStyle = {
  id: 'underline',
  handler: (editor: Editor) => editor.chain().focus().toggleUnderline().run(),
  title: 'Underline',
  icon: UnderlineIcon,
}

const strikeStyle = {
  id: 'strike',
  handler: (editor: Editor) => editor.chain().focus().toggleStrike().run(),
  title: 'Strike',
  icon: StrikethroughIcon,
}

const orderedListStyle = {
  id: 'orderedList',
  handler: (editor: Editor) => editor.chain().focus().toggleOrderedList().run(),
  title: 'Ordered list',
  icon: NumberedListIcon,
}

const bubbleMenuStyles = [
  boldStyle,
  italicStyle,
  underlineStyle,
  strikeStyle,
  bulletListStyle,
  orderedListStyle,
]

const floatingMenuStyles = [bulletListStyle, orderedListStyle]

watch(
  () => html.value,
  (value) => {
    const content = editor.value?.getHTML()
    if (content !== value) {
      setEditorContent(value ?? '')
    }
  },
)
watch(
  () => props.disabled,
  (isReadonly) => {
    if (editor.value) {
      editor.value?.setEditable(!isReadonly)
    }
  },
)

function setEditorContent(value: string) {
  editor.value?.commands.setContent(value ?? '')
}

watchEffect(() => {
  if (editor.value)
    editor.value.setOptions({
      editorProps: {
        attributes: {
          class: props.editorClasses
            ? props.editorClasses
            : [
                'p-2 pr-8  w-full min-h-[8rem]',
                isFocused.value
                  ? ' overflow-auto absolute top-0 left-0 z-[51] bg-white'
                  : 'h-full overflow-hidden',
                isFocused.value && props.isExpanded && 'h-full',
                isFocused.value && !props.isExpanded && 'max-h-80',
              ].join(' '),
        },
      },
    })
})

onBeforeUnmount(() => {
  editor.value?.destroy()
})

defineExpose({
  setEditorContent,
})
</script>
<style>
.tiptap p.is-editor-empty:first-child::before {
  color: var(--color-slate-400);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap .collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.tiptap .collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: +1.4em;
  user-select: none;
  white-space: nowrap;
}
</style>
