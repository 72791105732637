<template>
  <ImportSourceCard
    :import-source="importSource"
    @import-source-removed="emit('importSourceRemoved')"
  />
</template>
<script setup lang="ts">
import { ImportSource } from '@core/domain/models/import-source.model'
import ImportSourceCard from '../../Components/ImportSourceCard.vue'

const emit = defineEmits<(e: 'importSourceRemoved') => void>()

defineProps<{
  importSource: ImportSource
  readonly?: boolean
}>()
</script>
