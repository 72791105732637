<template>
  <div
    class="flex justify-center items-center border rounded-[4px] border-black/30 hover:cursor-pointer hover:border-black/70 w-4 h-4"
    :class="[value === CheckboxState.checked ? 'bg-primary-500' : 'bg-white']"
  >
    <CheckIcon
      v-if="value === CheckboxState.checked"
      class="w-3 h-3 stroke-white stroke-[4]"
    />

    <div
      v-if="value === CheckboxState.undetermined"
      class="w-3 h-3 bg-white flex place-items-center place-content-center"
    >
      <div class="h-[3px] w-[10px] rounded-lg bg-primary-500" />
    </div>
  </div>
</template>

<script lang="ts">
export enum CheckboxState {
  checked,
  uncheked,
  undetermined,
}
</script>
<script lang="ts" setup>
import CheckIcon from '@app/components/Icons/CheckIcon.vue'

const value = defineModel<CheckboxState>()
</script>
