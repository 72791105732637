<template>
  <Menu as="div" class="relative inline-block w-full text-left">
    <div class="mb-1">
      <slot name="label">
        <MenuButton
          class="block w-full text-sm text-left font-medium leading-5 text-dark-neutral-500"
        >
          {{ label }}
        </MenuButton>
      </slot>
    </div>

    <div>
      <MenuButton
        class="w-full rounded-md bg-white px-3.5 py-2.5 text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-1 focus:-outline-offset-1 focus:outline-blue-500 sm:text-sm flex justify-between items-center"
        @click="scrollToLastItem"
      >
        <span>{{
          selectedItem ? displayValue(selectedItem) : placeholder
        }}</span>
        <ChevronUpDownIcon class="size-5 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
      @after-enter="scrollToLastItem"
    >
      <MenuItems
        ref="dropdownMenu"
        class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
      >
        <ul>
          <MenuItem
            v-for="item in props.items"
            :key="item.id"
            v-slot="{ active }"
            as="template"
          >
            <li
              ref="menuItems"
              :class="[
                'relative cursor-pointer select-none py-2 pl-8 pr-4',
                active ? 'bg-primary-500 text-white' : 'text-gray-900',
              ]"
              @click="selectedItem = item"
            >
              <span
                :class="[
                  'block truncate',
                  selectedItem === item ? 'font-semibold' : '',
                ]"
              >
                {{ displayValue(item) }}
              </span>

              <span
                v-if="selectedItem === item"
                :class="[
                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  active ? 'text-white' : 'text-primary',
                ]"
              >
                <CheckIcon class="size-5" aria-hidden="true" />
              </span>
            </li>
          </MenuItem>
        </ul>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ref, nextTick } from 'vue'

const props = withDefaults(
  defineProps<{
    items: any[]
    label?: string
    filter?: (item: any, query: string) => boolean
    displayValue?: (item: any) => string
    placeholder?: string
  }>(),
  {
    displayValue: (item: any) => item,
    filter: (item: any, query: string) => item.includes(query),
    placeholder: '',
    label: '',
  },
)

const selectedItem = defineModel<any>()

const dropdownMenu = ref<HTMLElement | null>(null)
const menuItems = ref<HTMLElement[]>([])

const scrollToLastItem = async () => {
  await nextTick()
  if (dropdownMenu.value && menuItems.value.length > 0) {
    const lastItem = menuItems.value[menuItems.value.length - 1]
    lastItem?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
}
</script>
