<template>
  <slot
    name="activator"
    :show="() => modalManager.showModal(id)"
    @click.capture.prevent=""
  />
  <teleport to="#modal">
    <div
      v-if="modalManager.isModalVisible(id)"
      style="z-index: 999999999"
      class="fixed inset-0 bg-black/50 flex items-center justify-center"
      @click="dismissible && hide()"
    >
      <div
        class="flex flex-col overflow-visible min-w-[35rem] bg-white rounded-lg"
        @click.stop=""
      >
        <div
          class="border-b p-5 text-xl font-semibold flex justify-between text-dark-neutral-900"
        >
          <slot name="header" :hide="() => hide()"></slot>
        </div>

        <div
          class="flex-1 overflow-visible text-mid-neutral-500"
          :class="contentPaddingClass"
        >
          <slot name="content" :hide="() => hide()"></slot>
        </div>

        <div
          v-if="props.showFooter"
          class="px-5 py-3 border-t border-mid-neutral-50"
        >
          <slot class="" name="footer" :hide="() => hide()"></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import useModalManager from './modal-manager'
import { v4 as uuid4 } from 'uuid'
import { computed, onBeforeUnmount, onMounted } from 'vue'

const id = uuid4()
const modalManager = useModalManager()
modalManager.addModal(id)

const props = withDefaults(
  defineProps<{
    dismissible?: boolean
    showFooter?: boolean
    position?: string
    contentPadding?: string
  }>(),
  {
    dismissible: false,
    showFooter: true,
    position: 'top-right',
    contentPadding: 'px-5 py-5',
  },
)

const emit = defineEmits<(e: 'hide') => void>()

function hide() {
  modalManager.hideModal(id)
  emit('hide')
}

function dismissOnEsc(event: KeyboardEvent) {
  if (event.key === 'Escape' && props.dismissible) {
    hide()
  }
}
onMounted(() => {
  document.addEventListener('keydown', dismissOnEsc)
})

onBeforeUnmount(() => document.removeEventListener('keydown', dismissOnEsc))

defineExpose({
  hide: () => hide(),
  show: () => modalManager.showModal(id),
})

const contentPaddingClass = computed(() => {
  return props.contentPadding || 'px-5 py-5'
})
</script>
