<template>
  <div class="w-[1000px]">
    <div class="mb-5 text-blue-700 font-semibold tracking-[-0.14px] text-base">
      Specify the questions
    </div>
    <div
      v-if="
        review.entity.value.plan!.synthesisPlan!.attributesStructure.length > 0
      "
    >
      <div class="w-full border-collapse border-spacing-0">
        <div
          class="grid grid-cols-[2fr_4fr_120px_150px_120px_50px] font-medium text-sm pb-2 pl-6"
        >
          <div>Question ID</div>
          <div>Question</div>
          <div>Variable type</div>
          <div>Extraction type</div>
          <div>Synthesis</div>
        </div>
        <Sortable
          :items="sortedAttributes"
          @item:moved="
            ({ fractionalIndex, item }) => moveAttribute(item, fractionalIndex)
          "
        >
          <template #item="{ item, index }">
            <AttributeStructureBuilder
              :key="item.id"
              :structure="item"
              :question-id="index + 1"
              @delete-attribute-structure="deleteAttributesStructure"
            />
          </template>
        </Sortable>
      </div>
    </div>
    <div
      v-else
      class="w-[calc(100%-25px)] text-slate-500 text-sm font-[400] px-2 py-6 bg-slate-50 border border-slate-200 rounded-xl flex flex-col items-center text-center justify-center gap-4"
    >
      <SearchNotFoundIcon />
      <div class="flex flex-col items-center gap-2">
        <p>You haven’t added any question yet.</p>
      </div>
    </div>
    <button
      :class="
        review.entity.value.plan!.synthesisPlan!.attributesStructure.length
          ? 'ml-5 w-[calc(100%-45px)]'
          : 'w-[calc(100%-25px)]'
      "
      class="flex items-center justify-center mt-8 border-2 border-dashed border-blue-300 bg-blue-50 rounded-md text-blue-700 text-sm font-medium px-5 py-3"
      @click="addAttributesStructure()"
    >
      <PlusIcon class="text-blue-800 w-4" /> Add question
    </button>
  </div>
</template>
<script setup lang="ts">
import AttributeStructureBuilder from './AttributeStructureBuilder.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import Sortable from '@app/components/Global/Sortable/Sortable.vue'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { computed } from 'vue'
import { generateKeyBetween } from 'fractional-indexing'
import SearchNotFoundIcon from '@app/components/Icons/SearchNotFoundIcon.vue'
import { PlusIcon } from '@heroicons/vue/24/outline'

const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()
const sortedAttributes = computed(() =>
  review.entity.value.plan!.synthesisPlan!.attributesStructure.toSorted(
    (a, b) => {
      if (a.order === null && b.order === null) return 0
      if (a.order === null) return -1
      if (b.order === null) return 1
      return a.order > b.order ? 1 : -1
    },
  ),
)

async function deleteAttributesStructure(attributeStructureId: string) {
  try {
    loading.start()
    await review.deleteAttributeStructure(
      review.entity.value.id,
      attributeStructureId,
    )
    snackbar.show(
      SnackbarState.SUCCESS,
      'Attribute structure deleted successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function addAttributesStructure() {
  try {
    loading.start()
    let order = 'a0'
    const lastAttribute = sortedAttributes.value.at(-1)
    if (lastAttribute) {
      order = generateKeyBetween(lastAttribute.order, null)
    }

    await review.addAttributeStructure(review.entity.value.id, {
      label: '',
      question: '',
      order,
    })
  } catch (e) {
    const error = e as HttpException

    snackbar.show(
      SnackbarState.ERROR,
      'An error occured during adding attribute structure',
    )
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function moveAttribute(attribute: AttributeStructure, order: string) {
  loading.start()
  try {
    await review.editAttributeStructure(review.entity.value.id, {
      ...attribute,
      order,
    })
  } finally {
    loading.stop()
  }
}
</script>
