<template>
  <div ref="editorContainer">
    <Editor
      v-if="isVisible"
      v-model:html="synthesis"
      :is-expanded="false"
      :provider="review.dataExtractionProvider"
      :user-name="userName"
      :user-color="color"
      :disabled="review.isReviewReadonly.value"
      :field="
        'review-' +
        review.entity.value.id +
        '-synthesis-' +
        attributeStructure.id
      "
      :class="isExpanded ? 'h-[200px] bg-blue-100' : 'h-[50px] bg-blue-100'"
      :placeholder="'Enter the synthesis of ' + attributeStructure.label"
      editor-classes="overflow-auto h-full px-2 py-1 pr-6"
    />
    <div class="absolute top-1 right-1">
      <button
        v-if="!loading"
        @click="synthesisAttribute(attributeStructure.id)"
      >
        <SparklesIcon class="w-5" />
      </button>
      <div v-else>
        <span class="loader w-5 h-5" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../use-review'
import { computed, onMounted, ref, useTemplateRef } from 'vue'
import { AuthKey, EvidenceServiceApiKey } from '@app/injectionKeys'
import { SparklesIcon } from '@heroicons/vue/24/outline'
import Editor from '../components/Editor.vue'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'

const evidenceServiceApi = injectStrict(EvidenceServiceApiKey)
defineProps<{
  attributeStructure: AttributeStructure
  isExpanded: boolean
}>()
const synthesis = ref('')
const review = injectStrict(ReviewKey)
const { user, color } = injectStrict(AuthKey)
const userName = computed(
  () => user.value?.firstName + ' ' + user.value?.lastName,
)
const loading = ref(false)

async function synthesisAttribute(attributeId: string) {
  loading.value = true
  try {
    const { data } =
      await evidenceServiceApi.api.reviewsControllerSynthesizeDataExtractionAttribute(
        review.entity.value.id,
        {
          reviewId: review.entity.value.id,
          attributeId: attributeId,
        },
      )
    synthesis.value = data
  } finally {
    loading.value = false
  }
}

const isVisible = ref(false)
const editorContainer = useTemplateRef('editorContainer')

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        isVisible.value = true
        observer.disconnect()
      }
    },
    {
      rootMargin: '50px',
      threshold: 0.1,
    },
  )

  if (editorContainer.value) {
    observer.observe(editorContainer.value)
  }
})
</script>
