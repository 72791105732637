import OpenAI from 'openai'
import { zodResponseFormat } from 'openai/helpers/zod'
import { z } from 'zod'

export function useMedicalBackgroundPresetAssistant() {
  const model = 'gpt-4o'

  const openai = new OpenAI({
    baseURL: location.protocol + '//' + location.host + '/ai-assistant',
    dangerouslyAllowBrowser: true,
    apiKey: '',
  })

  const ConditionNames = z.object({
    conditionNames: z
      .array(z.string())
      .describe('Array of alternative medical condition names and synonyms'),
  })

  async function findConditionNames(condition: string) {
    const prompt = `Are there other medical names for ${condition} that are truly common and commonly used?
return an empty array if there are no other names that are truly common and commonly used.`
    const response = await openai.beta.chat.completions.parse({
      temperature: 0.1,
      model,
      messages: [
        {
          role: 'user',
          content: prompt,
        },
      ],
      response_format: zodResponseFormat(
        ConditionNames,
        'condition_medical_names',
      ),
    })
    return response.choices[0].message.parsed?.conditionNames ?? []
  }

  const levelOfEvidence = z.object({
    levelOfEvidence: z
      .enum(['a', 'b', 'c', 'd', 'e'])
      .describe(
        'The evidence level grade (a-e) based on available research quality',
      ),
  })

  async function generateLevelOfEvidence(conditionNames: string[]) {
    const prompt = `Which level of evidence is available concerning the treatment or diagnosis of ${conditionNames.join(', ')}?
Select one of the following options:
a) Evidence-based clinical guidelines that summarize the results of several systematic reviews and meta-analyses;
b) Systematic review and meta analyses;
c) Randomized controlled trials;
d) Observational studies and cohort studies;
e) Case studies, mechanistic reasoning, and low-quality evidence.`
    const response = await openai.beta.chat.completions.parse({
      temperature: 0.1,
      model,
      messages: [
        {
          role: 'user',
          content: prompt,
        },
      ],
      response_format: zodResponseFormat(levelOfEvidence, 'level_of_evidence'),
    })
    return response.choices[0].message.parsed?.levelOfEvidence ?? ''
  }

  const timeFrameSchema = z.object({
    timeFrame: z
      .enum(['1', '5', '10'])
      .describe(
        'The selected time frame option: a) 1 year; b) 5 years; c) 10 years.',
      ),
  })

  async function determineTimeFrame(conditionNames: string[]): Promise<number> {
    const prompt = `Considering the rapid evolution in the field, what is the proper time frame for investigating the state of the art in treatment or diagnosis of ${conditionNames.join(', ')}?
Select one of the following options:
 1 year; 5 years; 10 years.`

    const response = await openai.beta.chat.completions.parse({
      model,
      messages: [{ role: 'user', content: prompt }],
      response_format: zodResponseFormat(
        timeFrameSchema,
        'time_frame_selection',
      ),
    })

    return parseInt(response.choices[0].message.parsed?.timeFrame ?? '0')
  }

  const conditionCategorySchema = z.object({
    category: z
      .enum(['Disease', 'Injury', 'Disability'])
      .describe(
        'The medical classification category for the given condition. "Disease" includes conditions that arise from pathology or dysfunction. "Injury" includes physical damage caused by trauma or external forces. "Disability" includes conditions that result in significant functional limitations.',
      ),
  })

  async function classifyConditionCategory(conditionName: string) {
    const prompt = `Please classify the following condition into one of these categories:
1. Disease: Conditions that arise from pathological or biological dysfunctions.
2. Injury: Physical damage caused by trauma, external forces, or accidents.
3. Disability: Conditions that result in significant functional limitations or impairments.

Condition: "${conditionName}"

Select one of the categories: Disease, Injury, or Disability. Provide your classification in the following format:
{
  "category": "SelectedCategory"
}`
    const response = await openai.beta.chat.completions.parse({
      temperature: 0.1,
      model,
      messages: [
        {
          role: 'user',
          content: prompt,
        },
      ],
      response_format: zodResponseFormat(conditionCategorySchema, 'category'),
    })
    return response.choices[0].message.parsed?.category ?? ''
  }

  return {
    findConditionNames,
    generateLevelOfEvidence,
    determineTimeFrame,
    classifyConditionCategory,
  }
}
