<template>
  <aside
    :class="[
      sidebarClasses,
      'flex flex-col p-1 space-y-3 overflow-y-auto bg-gradient-to-t from-primary-800  to-primary-500',
      sidebarSize === 'small' ? 'px-2' : 'px-6',
    ]"
  >
    <div class="flex h-16 shrink-0 items-center">
      <button
        class="flex gap-1.5 text-xl font-bold px-2 py-1 items-center"
        @click="$router.push('/')"
      >
        <EvidenceIcon v-if="sidebarSize === 'large'" />
        <span v-if="sidebarSize === 'small'">
          <EvidenceLogo class="text-white" />
        </span>
      </button>
    </div>

    <div class="space-y-1 mt-2">
      <RevManRouterLink
        v-for="routeName in [RouteName.PROJECTS, RouteName.USERS]"
        :key="routeName"
        :route-name="routeName"
        :sidebar-size="sidebarSize"
      />
    </div>

    <hr class="border-t border-primary-400" />

    <div class="space-y-1">
      <RevManRouterLink
        :route-name="RouteName.SETTINGS"
        :sidebar-size="sidebarSize"
      />
    </div>
  </aside>
</template>

<script lang="ts" setup>
import RevManRouterLink from '@app/components/Global/RevManRouterLink.vue'
import { RouteName } from '@app/router'
import EvidenceIcon from '@app/components/Icons/EvidenceIcon.vue'
import { computed, inject, ref } from 'vue'
import EvidenceLogo from '@app/components/Icons/EvidenceLogo.vue'

const sidebarSize = inject('sidebarSize', ref('large'))

const sidebarClasses = computed(() => ({
  'w-72': sidebarSize.value === 'large',
  'w-full': sidebarSize.value === 'small',
}))
</script>
