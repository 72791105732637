<template>
  <div class="h-screen p-4">
    <div class="w-2/4">
      <TextInput
        ref="reviewNameInput"
        v-model="reviewName"
        label="Review name"
        type="text"
        placeholder="Review name"
        :rules="[(v: string) => !!v || 'Please enter a review name']"
        @enter="emit('createReview')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { CreateReviewKey } from '../useCreateProjectReview'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'

const { reviewName } = injectStrict(CreateReviewKey)
const emit = defineEmits<(e: 'createReview') => void>()
</script>
