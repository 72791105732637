<template>
  <div class="flex items-center gap-1">
    <div class="text-dark-neutral-500 font-bold">
      {{ review.entity?.value?.project?.name }}
    </div>
    /
    <FloatingMenu placement="bottom-start" :arrow="false">
      <template #activator="{ show }">
        <button
          class="flex items-center gap-1 text-dark-neutral-500"
          @click="show"
        >
          {{ review.entity.value.name }}
          <ChevronDownIcon class="w-4 h-4" />
        </button>
      </template>

      <template #content>
        <div class="flex flex-col bg-white text-dark-neutral-500 w-full">
          <button
            v-for="currentReview in review.entity?.value?.project?.reviews?.sort(
              (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
            )"
            :key="'review-' + currentReview.id"
            class="py-2 px-4 hover:bg-black/5 flex justify-start gap-1"
            :class="{
              'bg-primary-50 text-primary-500':
                currentReview.id === review.entity.value.id,
            }"
            @click="$router.push(`/reviews/${currentReview.id}`)"
          >
            <span
              :class="{
                '/80': currentReview.id === review.entity.value.id,
              }"
              >{{ review.entity?.value?.project?.name }}</span
            >
            -
            <span
              class="font-medium"
              :class="{
                '': currentReview.id === review.entity.value.id,
              }"
              >{{ currentReview.name }}</span
            >
          </button>
        </div>
      </template>
    </FloatingMenu>
  </div>
</template>
<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import { ReviewKey } from '../use-review'
import { injectStrict } from '@app/utils/injectStrict'

const review = injectStrict(ReviewKey)
</script>
