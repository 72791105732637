<template>
  <Suspense>
    <PrototypeAsync />
  </Suspense>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import PrototypeAsync from './PrototypeAsync.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { DevToolboxKey } from '@app/injectionKeys'
const router = useRouter()
const { isCuttingEdge } = injectStrict(DevToolboxKey)
if (!isCuttingEdge) {
  router.push('/')
}
</script>
