<template>
  <div class="flex-grow p-5">
    <div
      class="mb-4 text-dark-neutral-500 font-semibold tracking-tighter text-md"
    >
      Performance and safety of the device-specific intervention
    </div>
    <div
      class="text-base font-medium tracking-tighter text-slate-700 flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-6"
    >
      The goal is to identify the following information concerning
      <input
        v-model="preset.alternativeName"
        type="text"
        class="flex-1 pl-1 focus:outline-hidden border-b-2 min-w-[350px] py-2 transition-colorsfont-normal"
        :class="[isFocused ? 'border-blue-500' : 'border-slate-300']"
        placeholder="Type the alternative here and press enter"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ref } from 'vue'
import { CreateReviewKey } from '../../useCreateProjectReview'
const { preset } = injectStrict(CreateReviewKey)
const isFocused = ref()
</script>
