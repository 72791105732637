<template>
  <div class="space-y-6 border border-slate-300 rounded-md p-6 pb-12">
    <div class="flex justify-between items-start gap-8">
      <div class="space-y-2">
        <h3 class="text-slate-700 font-medium">
          IMDRF MDCE 2019 appraisal Criteria
        </h3>
        <p class="text-slate-500 text-sm">
          IMDRF criteria for assessing clinical evidence, focusing on relevance,
          reliability, and robustness of data. Use to evaluate the quality of
          studies supporting device safety and performance.
        </p>
      </div>

      <Switch
        :disabled="review.isPlanReadonly.value"
        :model-value="
          review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable
        "
        :class="[
          review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable
            ? 'bg-green-600'
            : 'bg-gray-200',
          'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden ',
        ]"
        @update:model-value="toggleAppraisalImdrfMdce2019"
      >
        <span
          aria-hidden="true"
          :class="[
            review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable
              ? 'translate-x-5'
              : 'translate-x-0',
            'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
    </div>
    <div
      v-if="review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable"
      class="space-y-6"
    >
      <div
        v-for="criterion in reviewPlanAppraisalCriteria"
        :key="criterion.id"
        class="space-y-0"
      >
        <div class="pb-4 space-y-2">
          <p class="text-sm font-semibold text-gray-700">
            {{ criterion.id }} - Title: {{ criterion.title }}
          </p>
          <p class="text-sm text-slate-500 font-[400]">
            <span class="">Question:</span>
            {{ criterion.question }}
          </p>
        </div>
        <div class="flex flex-wrap gap-2 items-center text-primary">
          <div
            v-for="(answer, index) in criterion.answers"
            :key="answer.id"
            class="flex items-center text-sm gap-1 p-2 rounded-full text-blue-800 bg-blue-200 font-semibold leading-none"
          >
            <div class="flex items-center gap-1">
              <div
                class="rounded-3xl bg-white/40 font-normal text-sm px-2 py-1"
              >
                {{ criterion.id + (index + 1) }}
              </div>
              {{ answer.title }}
            </div>
            <button
              v-if="!review.isPlanReadonly.value"
              @click="deleteAnswer(answer.id, criterion.id)"
            >
              <XMarkIcon class="w-4 text-primary" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="
              addAnswer({ appraisalCriterionId: criterion.id, answer: $event })
            "
          >
            <template #activator="{ show }">
              <button
                key=""
                class="flex items-center gap-1 text-sm border-2 p-2 px-3 rounded-full text-slate-500 border-slate-300 border-dashed font-semibold"
                @click.stop="show"
              >
                <PlusIcon class="w-4 h-4" />Add answer
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { SnackbarState } from '@app/types'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { HttpException } from '@core/exceptions/http.exception'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import { ref, watchEffect } from 'vue'
import { AppraisalCriterion } from '@core/domain/models/appraisal-criterion.model'
import { Switch } from '@headlessui/vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import PlusIcon from '@app/components/Icons/PlusIcon.vue'

const snackbar = useSnackbar()
const loading = useLoading()

const review = injectStrict(ReviewKey)

const reviewPlanAppraisalCriteria = ref<AppraisalCriterion[]>([])

watchEffect(() => {
  reviewPlanAppraisalCriteria.value =
    review.entity.value.plan?.appraisalCriteria ?? []
})

async function addAnswer(data: {
  appraisalCriterionId: string
  answer: string
}) {
  loading.start()
  try {
    await review.addAppraisalCriterionAnswer(data)
    snackbar.show(SnackbarState.SUCCESS, 'Answer added successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while adding answer, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteAnswer(answerId: string, appraisalCriterionId: string) {
  loading.start()
  try {
    await review.deleteAppraisalCriterionAnswer({
      answerId: answerId,
      appraisalCriterionId: appraisalCriterionId,
    })
    snackbar.show(SnackbarState.SUCCESS, 'Answer deleted successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while deleting answer, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function toggleAppraisalImdrfMdce2019(value: boolean) {
  loading.start()
  try {
    if (value) {
      await review.enableStudiesAppraisalImdrfMdce2019()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Appraisal IMDRF MDCE 2019 enabled successfully',
      )
    } else {
      await review.disableStudiesAppraisal()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Appraisal IMDRF MDCE 2019 disabled successfully',
      )
    }
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while enabling/disabling appraisal IMDRF MDCE 2019, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}
</script>
