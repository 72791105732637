<template>
  <Modal ref="modal">
    <template #activator="{ show }">
      <button
        class="bg-red-100 text-red-800 px-5 py-2 font-medium rounded-full whitespace-nowrap flex items-center gap-1"
        @click="areAllStudiesProcessed ? downloadPdfZip() : show()"
      >
        <PdfIcon class="w-5" />
        PDFs (all)<ArrowDownTrayIcon class="w-4" />
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Export Pdf
      </p>
      <button @click="hide">
        <XIcon class="w-6 h-6" />
      </button>
    </template>
    <template #content>
      <div>
        <div>
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg
                class="h-6 w-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <p v-if="!areAllStudiesProcessed" class="text-sm text-gray-500">
                Some studies are missing their appraisal or screening, are you
                sure you would like to proceed with the export?
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="sm:flex sm:flex-row-reverse gap-2">
        <button
          type="button"
          class="inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-primary-700 sm:w-auto"
          @click=";[hide(), downloadPdfZip()]"
        >
          Proceed
        </button>
        <button
          type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          @click="hide"
        >
          Cancel
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@app/components/Global/Modal/Modal.vue'
import { injectStrict } from '@app/utils/injectStrict'
import XIcon from '@app/components/Icons/XIcon.vue'
import { ReviewKey } from '../use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
import PdfIcon from '@app/components/Icons/PdfIcon.vue'
import useExport from './useExport'
const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()

async function downloadPdfZip() {
  loading.start()
  try {
    const buffer = await review.downloadPdfZip()
    const downloadElement = document.createElement('a')
    downloadElement.style.display = 'none'
    downloadElement.href = window.URL.createObjectURL(new Blob([buffer]))
    downloadElement.download =
      review.entity.value?.project?.name +
      ' - ' +
      review.entity.value?.name +
      '.zip'
    document.body.appendChild(downloadElement)
    downloadElement.click()
    window.URL.revokeObjectURL(downloadElement.href)
    downloadElement.remove()

    snackbar.success('PDFs exported successfully')
  } catch (error) {
    snackbar.error('Failed to export PDFs')
    throw error
  } finally {
    loading.stop()
  }
}

const { areAllStudiesProcessed } = useExport(review.entity.value)
</script>
