<template>
  <button
    class="px-4 py-3 flex items-center gap-2 hover:bg-light-neutral-500 w-full"
    @click="createReviewUpdate"
  >
    <ArrowPathIcon class="size-5 text-dark-neutral-500" />
    <a class="font-medium text-dark-neutral-500 relative text-sm">
      Update
      <span class="absolute inset-0" />
    </a>
  </button>
</template>

<script lang="ts" setup>
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { ReviewsServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import useProjects from '@app/views/Projects/use-projects'
import { Review } from '@core/domain/models/review.model'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'
const reviewsService = injectStrict(ReviewsServiceKey)
const { refresh } = useProjects()
const props = defineProps<{ review: Review }>()
const loading = useLoading()
const snackbar = useSnackbar()
async function createReviewUpdate() {
  loading.start()
  try {
    await reviewsService.createReviewUpdate(props.review.id)
    refresh()
    snackbar.success('Review updated successfully')
  } catch (error) {
    snackbar.error('Failed to update review')
    throw error
  } finally {
    loading.stop()
  }
}
</script>
