<template>
  <ExpansionPanel
    :key="sourceType"
    class="w-full"
    :model-value="expansionPanel.isShown.value"
  >
    <template #activator="{ isShown }">
      <button
        class="flex justify-between items-center w-full py-[10px] pl-4 pr-2"
        :class="[
          buttonClass,
          {
            'bg-blue-50': isShown,
          },
        ]"
        @click="togglePanel"
      >
        <div class="flex gap-2 items-center">
          <div
            class="bg-slate-300 rounded-full w-2 h-2"
            :class="{
              '!bg-blue-800': isShown,
            }"
          />
          <div class="text-sm font-medium text-slate-700">{{ sourceType }}</div>
        </div>
        <ChevronDownBoldIcon v-if="isShown" class="w-5 h-5 text-primary" />
        <ChevronRightBoldIcon v-else class="w-5 h-5 text-primary" />
      </button>
    </template>
    <template #default>
      <ReviewItemTypeCard
        v-if="sourceType === 'Cross-publisher'"
        :sources="scholarlyArticlesAggregatedSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
      <ReviewItemTypeCard
        v-if="sourceType === 'Single-publisher'"
        :sources="scholarlyArticlesPublisherSpecificSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
      <ReviewItemTypeCard
        v-if="sourceType === 'Systematic reviews'"
        :sources="scholarlyArticlesSystematicReviewSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
      <ReviewItemTypeCard
        v-if="sourceType === 'Clinical trials'"
        :sources="clinicalTrialsSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      >
        <div class="pl-6 pt-0">
          <ul class="ml-1 pl-2 space-y-2 tree">
            <li
              v-for="(provider, index) in clinicalTrialDataProviders"
              :key="index"
              class="sub-item"
            >
              <div class="flex items-center gap-3 flex-1">
                <component :is="provider.icon" class="rounded-full w-5 h-5" />
                <span
                  class="text-[13px] line-clamp-1 w-4/5 font-medium text-slate-700"
                >
                  {{ provider.name }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </ReviewItemTypeCard>
      <ReviewItemTypeCard
        v-if="sourceType === 'Incidents'"
        :sources="incidentSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />

      <div v-if="sourceType === 'Safety information'">
        <Modal>
          <template #activator="{ show }">
            <div class="relative group w-full">
              <div class="flex-1 text-left w-full">
                <button
                  class="w-full hover:bg-blue-50 pl-7 py-2"
                  :class="{
                    'cursor-not-allowed': !isPlanEditable,
                  }"
                  @click="() => (isPlanEditable ? show() : null)"
                >
                  <ul class="space-y-2">
                    <li
                      v-for="(provider, index) in fieldSafetyNoticesProviders"
                      :key="index"
                      class="w-full flex gap-3"
                    >
                      <component
                        :is="provider.icon"
                        class="rounded-full w-5 h-5"
                      />
                      <span
                        class="text-[13px] line-clamp-1 font-medium text-slate-700"
                      >
                        {{ provider.name }}
                      </span>
                    </li>
                  </ul>
                </button>
              </div>
            </div>
          </template>
          <template #content="{ hide }">
            <FsnSearch @hide="hide" />
          </template>
        </Modal>
      </div>
      <ReviewItemTypeCard
        v-if="sourceType === 'Standards'"
        :sources="standardsSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
      <ReviewItemTypeCard
        v-if="sourceType === 'Guidance'"
        :sources="guidanceSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
    </template>
  </ExpansionPanel>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ReviewItemTypeCard from '../AddImportSourceToPlan/ReviewItemTypeCard.vue'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { isUrlValid } from '@app/utils/urlValidation'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import {
  AustraliaFlag,
  ChinaFlag,
  USAFlag,
  EUFlag,
  NetherlandsFlag,
  BrazilFlag,
  IndiaFlag,
  SouthKoreaFlag,
  CubaFlag,
  GermanyFlag,
  IranFlag,
  JapanFlag,
  SouthAfricaFlag,
  SriLankaFlag,
  ThailandFlag,
  PeruFlag,
  LebanonFlag,
  UKFlag,
  CanadaFlag,
} from '@app/components/Icons/flags/index'
import Modal from '@app/components/Global/Modal/Modal.vue'
import FsnSearch from './FsnSearch/FsnSearch.vue'
import useExpansionPanel from '@app/composables/use-expansion-panel'
import ChevronDownBoldIcon from '@app/components/Icons/ChevronDownBoldIcon.vue'
import ChevronRightBoldIcon from '@app/components/Icons/ChevronRightBoldIcon.vue'
import { fsnProviders } from '@app/utils/fsnProviders'

const expansionPanel = useExpansionPanel()

defineProps<{
  sourceType: string
}>()

interface DataProvider {
  name: string
  icon: any
}

const clinicalTrialDataProviders: DataProvider[] = [
  {
    name: 'ANZCTR',
    icon: AustraliaFlag,
  },
  { name: 'ChiCTR', icon: ChinaFlag },
  { name: 'ClinicalTrials.gov', icon: USAFlag },
  { name: 'CTIS', icon: EUFlag },
  { name: 'EU-CTR', icon: EUFlag },
  { name: 'ISRCTN', icon: UKFlag },
  { name: 'OMON', icon: NetherlandsFlag },
  { name: 'ReBec', icon: BrazilFlag },
  { name: 'CTRI', icon: IndiaFlag },
  {
    name: 'CRiS',
    icon: SouthKoreaFlag,
  },
  { name: 'RPCEC', icon: CubaFlag },
  { name: 'DRKS', icon: GermanyFlag },
  { name: 'IRCT', icon: IranFlag },
  { name: 'jRCT', icon: JapanFlag },
  { name: 'PACTR', icon: SouthAfricaFlag },
  { name: 'SLCTR', icon: SriLankaFlag },
  { name: 'TCTR', icon: ThailandFlag },
  { name: 'REPEC', icon: PeruFlag },
  { name: 'LBCTR', icon: LebanonFlag },
  {
    name: 'ITMCTR',
    icon: ChinaFlag,
  },
]

const fieldSafetyNoticesProviders = fsnProviders.flatMap((country) =>
  country.sources.map((source) => ({
    name: source.name,
    icon: country.countryFlag,
  })),
)

const builtInImportSources = useBuiltInImportSources()
const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()

const isPlanEditable = computed(() => !review.isPlanReadonly.value)

const scholarlyArticlesAggregatedSources = computed(() => {
  const articleSources = [
    createSource({
      id: BuiltInImportSourceId.PUBMED,
      iconUrl: '/sources-icons/pubmed.png',
    }),
    createSource({
      id: BuiltInImportSourceId.GOOGLE_SCHOLAR,
      iconUrl: '/sources-icons/google-scholar.png',
    }),
    createSource({
      id: BuiltInImportSourceId.OPEN_ALEX,
      iconUrl: '/sources-icons/openalex.png',
    }),
    createSource({
      id: BuiltInImportSourceId.DIMENSIONS_AI,
      iconUrl: '/sources-icons/dimensions-ai.png',
    }),
    createSource({
      id: BuiltInImportSourceId.PMC,
      iconUrl: '/sources-icons/pmc.png',
    }),
    createSource({
      id: BuiltInImportSourceId.EUROPE_PMC,
      iconUrl: '/sources-icons/europe-pmc.png',
    }),
    createSource({
      id: BuiltInImportSourceId.LIVIVO,
      iconUrl: '/sources-icons/livivo.png',
    }),
    createSource({
      id: BuiltInImportSourceId.BASE,
      iconUrl: '/sources-icons/base.png',
    }),
    createSource({
      id: BuiltInImportSourceId.EMBASE,
      iconUrl: '/sources-icons/science-direct.png',
    }),
  ]
  return articleSources
})

const scholarlyArticlesPublisherSpecificSources = computed(() => {
  const articleSources = [
    createSource({
      id: BuiltInImportSourceId.WILEY_ONLINE_LIBRARY,
      iconUrl: '/sources-icons/wiley-online-library.png',
    }),
    createSource({
      id: BuiltInImportSourceId.MAG_ONLINE,
      iconUrl: '/sources-icons/mag-online.png',
    }),
    createSource({
      id: BuiltInImportSourceId.SCIENCE_DIRECT,
      iconUrl: '/sources-icons/science-direct.png',
    }),
  ]
  return articleSources
})

const scholarlyArticlesSystematicReviewSources = computed(() => {
  const sources = [
    createSource({
      id: BuiltInImportSourceId.COCHRANE,
      iconUrl: '/sources-icons/cochrane.png',
    }),
    createSource({
      id: BuiltInImportSourceId.PROSPERO,
      iconUrl: '/sources-icons/prospero.png',
    }),
  ]
  return sources
})

const clinicalTrialsSources = computed(() => {
  const sources = [
    createSource({
      id: BuiltInImportSourceId.ICTRP,
      iconUrl: '/sources-icons/ictrp.svg',
    }),
  ]
  return sources
})

const incidentSources = computed(() => {
  const sources = [
    createSource({
      id: BuiltInImportSourceId.MAUDE,
      iconUrl: '',
      icon: USAFlag,
    }),
    createSource({
      id: BuiltInImportSourceId.DAEN,
      iconUrl: '',
      icon: AustraliaFlag,
      isComingSoon: true,
    }),
    createSource({
      id: BuiltInImportSourceId.MDID,
      iconUrl: '',
      icon: CanadaFlag,
      isComingSoon: true,
    }),
  ]
  return sources
})

const standardsSources = computed(() => {
  const sources = [
    createSource({
      id: BuiltInImportSourceId.CENELEC,
      iconUrl: '/sources-icons/cenelec.png',
      icon: '',
      isComingSoon: true,
    }),
  ]
  return sources
})

const guidanceSources = computed(() => {
  const sources = [
    createSource({
      id: BuiltInImportSourceId.MDCG,
      iconUrl: '/sources-icons/mdcg.png',
    }),
  ]
  return sources
})

function createSource({
  id,
  iconUrl,
  icon,
  isComingSoon,
}: {
  id: BuiltInImportSourceId
  iconUrl: string
  icon?: any
  isComingSoon?: boolean
}) {
  const source = builtInImportSources.findById(id)!
  return {
    label: source.name,
    id: source.id,
    iconUrl,
    isEvidenceMetadataExtractionTechnology:
      source.isEvidenceMetadataExtractionTechnology,
    type: source.type,
    url: source.url,
    description: source.description,
    icon,
    isComingSoon,
  }
}

async function handleAddImportSource(data: {
  id: string
  name: string
  url?: string
  description?: string
  type: ImportSourceType
}) {
  if (
    data.type !== ImportSourceType.HAND_SEARCH &&
    data.type !== ImportSourceType.CITATION_SEARCH &&
    (!data.url || !isUrlValid(data.url))
  ) {
    throw new Error('Invalid URL')
  }
  loading.start()
  try {
    if (!isSourceUsedInReview(data.id)) {
      await review.addCustomImportSourceToPlan({
        id: data.id,
        name: data.name,
        url: data.url ?? '',
        description: data.description,
        type: data.type,
      })
      snackbar.show(
        SnackbarState.SUCCESS,
        'Import source successfully added to plan',
      )
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
    document.getElementById(data.id)?.scrollIntoView({ behavior: 'smooth' })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

function isSourceUsedInReview(sourceId: string) {
  return review.entity.value.plan?.importPlan?.importSources?.some(
    (s) => s.id === sourceId,
  )
}

const buttonClass = computed(() => ({
  'hover:bg-blue-50': isPlanEditable.value,
  'cursor-not-allowed': !isPlanEditable.value,
}))

function togglePanel() {
  expansionPanel.toggle()
}
</script>

<style scoped>
.tree {
  position: relative;
  padding-left: 20px;
}

.item {
  position: relative;
}

.sub-item {
  position: relative;
}

.sub-item::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #cbd5e1;
  height: 150%;
}

.sub-item span::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 50%;
  width: 8px;
  height: 1px;
  background-color: #cbd5e1;
}

.sub-item:last-child::before {
  height: 50%;
}
</style>
