<template>
  <div class="container p-4 space-y-4">
    <Combobox
      v-if="projects.length"
      :items="projects"
      :model-value="selectedProject"
      @update:model-value="(v) => (projectId = v.id)"
      :display-value="(p) => p?.name"
      placeholder="Select a project"
      :filter="(p, q) => p?.name.toLowerCase().includes(q.toLowerCase())"
    />
    <Combobox
      v-if="selectedProject?.reviews?.length"
      :items="selectedProject?.reviews ?? []"
      :model-value="selectedReview"
      @update:model-value="(v) => (reviewId = v.id)"
      :display-value="(r) => r?.name"
      :filter="(r, q) => r.name.toLowerCase().includes(q.toLowerCase())"
      placeholder="Select a review"
    />
    <div class="space-y-2" v-if="reviewId">
      <div class="text-sm text-primary">Questions</div>
      <div class="flex gap-2 flex-wrap">
        <button
          class="rounded-lg px-2 py-1"
          v-for="attribute in selectedReview?.plan?.synthesisPlan
            .attributesStructure"
          @click="
            questions[reviewId][attribute.id] =
              !questions[reviewId][attribute.id]
          "
          :class="
            questions[reviewId][attribute.id]
              ? 'bg-primary text-white'
              : 'bg-slate-200 text-slate-800'
          "
          v-tooltip="attribute.question"
        >
          {{ attribute.label }}
        </button>
      </div>
    </div>
    <PrototypeDataExtraction
      :review="selectedReview"
      v-if="selectedReview"
      :questions="questions[reviewId]"
    />
    <PrototypeSynthesis
      v-if="selectedReview"
      :review="selectedReview"
      :questions="questions[reviewId]"
    />
  </div>
</template>

<script setup lang="ts">
import Combobox from '@app/components/Global/Combobox/Combobox.vue'
import PrototypeDataExtraction from './PrototypeDataExtraction.vue'
import { useStorage } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { Review } from '@core/domain/models/review.model'
import { injectStrict } from '@app/utils/injectStrict'
import { EvidenceServiceApiKey } from '@app/injectionKeys'
import PrototypeSynthesis from './PrototypeSynthesis.vue'

const projectId = useStorage<number>('prototype-project', 0)
const reviewId = useStorage<number>('prototype-review', 0)

const evidenceServiceApi = injectStrict(EvidenceServiceApiKey)
const questions = useStorage<{
  [reviewId: string]: { [questionId: string]: boolean }
}>('prototype-questions', {})

const { data } = await evidenceServiceApi.api.projectsControllerFind()
const projects = ref<any[]>(data)

const selectedReview = ref<Review>()

watch(projectId, () => {
  reviewId.value = null
})

const selectedProject = computed(() =>
  projects.value.find((p) => p.id === projectId.value),
)

watch(
  reviewId,
  async (v) => {
    if (!v) return

    const { data } = await evidenceServiceApi.api.reviewsControllerFindById(v, {
      shouldIncludeSearches: true,
    })
    selectedReview.value = new Review(data as Partial<Review>)
    if (!questions.value[v]) {
      questions.value[v] = {}
    }
  },
  {
    immediate: true,
  },
)
</script>
